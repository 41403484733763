@charset "UTF-8";
/*  ==========================================================================
    Includes
    ==========================================================================

    This is the main includes file. Here we include each of the sections.
    This includes file only concerns it's self with the cascade order of
    the different sections. Each section than controls it's own internal cascade.

    Cascade Index
    -------------
    1.  Vars
        The 'Vars' section holds global variables that all partials should be able to access if needs be. Note partials may have variables within them that are deemed only relevant for that partial. If you add a variable that you want to use across various partials you might want to consider adding it here.

    2.  Functions
        Has useful functions.

    3.  Breakpoints
        The 'Breakpoints' section contains a list of global variables that represent different breakpoint sizes. They are separated from the 'Vars' section because they will make use of some functions, particularly em() and bp-size().

    4.  Mixins
        Has useful mixins.

    5.  Base
        These are styles which effect base elements (p, a, table ect). The 'Base' section includes a reset and parts of normalize.css(https://github.com/necolas/normalize.css) are sprinkled throughout.

    6.  vendor
        If you want to include 3rd party css. They are included here so that the 'Objects' and 'Ui' sections van override styles.

    7.  Objects
        Reusable patterns. May contain generic patterns like the 'Media' object but you may also fill this folder with patterns that are a little more specific to your project.

    8.  Ui
        The main styles for your project.

    9.  Helpers
        Style trumps. Helper classes take precedence over everything else. I recommend only using helper classes if the style the helper class enables is relevant across all viewports otherwise you end up trying to fight the helper classes in the cascade.
*/
/*  ==========================================================================
    Vars
    ========================================================================== */
/*
    colors
    ------
*/
/*
    Fonts
    -----
*/
@font-face {
  font-family: "BrownStd_Bold";
  src: url("../fonts/BrownStd-Bold.otf") format("opentype");
}
@font-face {
  font-family: "BrownStd_Regular";
  src: url("../fonts/BrownStd-Regular.otf") format("opentype");
}
@font-face {
  font-family: "Butcombe_Bold";
  src: url("../fonts/Butcombe-Bold.ttf") format("truetype");
}
/*
    Z-indexes
    ---------
    Keep track of z-index by assigning your elements z-index to
    one of these defined levels.
*/
/*
    Widths
    ------
*/
/*
    Spacing units
    -------------
*/
/*
    Hamburgers
    -------------
*/
/* 1 */
/*  ==========================================================================
    Functions:Units
    ========================================================================== */
/*
    PX to EM
    --------

    Convert px to em

    $base-font-size is used as a default so when you use the em() function you don't have to provide
    a second parameter if your working with a element with the default font-size
*/
/*
    EM to PX
    --------

    Convert em to px
*/
/*  ==========================================================================
    Functions:Breakpoints
    ========================================================================== */
/* 2 */
/*  ==========================================================================
    Breakpoints
    ========================================================================== */
/* 3 */
/*  ==========================================================================
    Mixins:Respond
    ==========================================================================

    Generate media queries.

    $respond-no-media-queries
    -------------------------
    set this global var in your stylesheet aimed at browsers which do
    not support media queries (ie8) to give them desktop styles only.

    This way they get all the styles but the cascade makes sure they display
    the desktop styles (if your using a mobile first approach to layering the media queries)
*/
/*  ==========================================================================
    Mixins:Rem
    ==========================================================================

    A small mixin for easy use of rem with px as fallback
    usage: @include x-rem(font-size, 14px)
    usage: @include x-rem(marign, 0 12px 2 1.2)
    usage: @include x-rem(padding, 1.5 24px)

    thanks to Eric Meyer for https://github.com/ericam/susy
    and Hans Christian Reinl for http://drublic.de/blog/rem-fallback-sass-less/

    set $base-font-size outside of the mixin in your variables file
*/
/*  ==========================================================================
    Mixins:Visually Hidden
    ========================================================================== */
/* 4 */
/*  ==========================================================================
    Base:Reset
    ========================================================================== */
/*
    1. Use border-box globally
*/
*,
*:after,
*:before {
  box-sizing: border-box;
  /* 1 */
}

/*
    1. reset some stuff
*/
p, blockquote, pre,
dl, dd, form, fieldset, legend,
table, th, td, caption,
hr {
  margin: 0;
  /* 1 */
  padding: 0;
  /* 1 */
}

/*
    1. Correct `block` display not defined for any HTML5 element in IE 8/9. - Normalize:v3.0.1
    1. Correct `block` display not defined for `details` or `summary` in IE 10/11 and Firefox. - Normalize:v3.0.1
    1. Correct `block` display not defined for `main` in IE 11. - Normalize:v3.0.1
*/
article,
aside,
details,
figcaption,
figure,
footer,
header,
hgroup,
main,
nav,
section,
summary {
  display: block;
  /* 1 */
}

/*
    1. Correct `inline-block` display not defined in IE 8/9. - Normalize:v3.0.1
    2. Normalize vertical alignment of `progress` in Chrome, Firefox, and Opera. - Normalize:v3.0.1
*/
audio,
canvas,
progress,
video {
  display: inline-block;
  /* 1 */
  vertical-align: baseline;
  /* 2 */
}

/*
    1. Prevent modern browsers from displaying `audio` without controls. - Normalize:v3.0.1
    2. Remove excess height in iOS 5 devices. - Normalize:v3.0.1
*/
audio:not([controls]) {
  display: none;
  /* 1 */
  height: 0;
  /* 2 */
}

/*
    1. Address `[hidden]` styling not present in IE 8/9/10. - Normalize:v3.0.1
    2. Hide the `template` element in IE 8/9/11, Safari, and Firefox < 22. - Normalize:v3.0.1
*/
[hidden],
template {
  /* 2 */
  display: none;
}

/*  ==========================================================================
    Base:Main
    ========================================================================== */
html {
  -webkit-text-size-adjust: 100%;
  -ms-text-size-adjust: 100%;
  font-size: 1.125em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  margin: 0;
  background-color: #FFF7EB;
  overflow-x: hidden;
}
body.menu-active {
  overflow: hidden;
}

.map-page-text {
  font-size: 16px;
  line-height: 26px;
  max-width: 720px;
  width: 100%;
  margin: 0 auto 15px;
  text-align: center;
  font-family: "caslons-egyptian", sans-serif;
  overflow: hidden;
}

#onetrust-close-btn-container,
#onetrust-reject-all-handler,
#onetrust-pc-sdk .ot-pc-refuse-all-handler {
  display: none !important;
}

#onetrust-banner-sdk #onetrust-button-group {
  width: 50% !important;
}

#onetrust-pc-sdk .save-preference-btn-handler {
  max-width: 40% !important;
}

/*  ==========================================================================
    Base:Lists
    ========================================================================== */
/*
    1. Remove vertical spacing from nested lists.
*/
li > ul,
li > ol {
  margin-bottom: 0;
  /* 1 */
}

/*
    1. Have a numbered `ul` without the semantics implied by using an `ol`.
*/
/*ul*/
.numbered-list {
  list-style-type: decimal;
  /* 1 */
}

/*  ==========================================================================
    Base:Images
    ========================================================================== */
/*
    1. So that `alt` text is visually offset if images don’t load.
    2. Fluid images.
    3. Remove border when inside `a` element in IE 8/9. - Normalize:v3.0.1
*/
img {
  font-style: italic;
  /* 1 */
  max-width: 100%;
  /* 2 */
  border: 0;
  /* 3 */
}

/*
    1. Images in `figure` elements.
*/
figure > img {
  display: block;
  /* 1 */
}

/*  ==========================================================================
    Base:Type
    ========================================================================== */
/*
    1. Remove underlines from potentially troublesome elements.
*/
u,
ins {
  text-decoration: none;
  /* 1 */
}

/*
    1. Apply faux underline via `border-bottom`.
*/
ins {
  border-bottom: 1px solid;
  /* 1 */
}

/*
    1. Give a help cursor to elements that give extra info on `:hover`.
*/
abbr[title],
dfn[title] {
  cursor: help;
  /* 1 */
}

/*
    1. Address styling not present in IE 8/9, Safari 5, and Chrome. - Normalize:v3.0.1
*/
abbr[title] {
  border-bottom: 1px dotted;
  /* 1 */
}

/*
    1. Address style set to `bolder` in Firefox 4+, Safari 5, and Chrome. - Normalize:v3.0.1
*/
b,
strong {
  font-weight: bold;
  /* 1 */
}

/*
    1. Address styling not present in Safari 5 and Chrome. - Normalize:v3.0.1
*/
dfn {
  font-style: italic;
  /* 1 */
}

/*
    Address styling not present in IE 8/9. - Normalize:v3.0.1
*/
mark {
  background: #c3a76d;
  /* 1 */
  color: #000;
  /* 1 */
}

/*
    1. Set consistent quote types. - Normalize:v2.1.0
*/
q {
  quotes: "“" "”" "‘" "’";
  /* 1 */
}

/*
    1. Address inconsistent and variable font size in all browsers. - Normalize:v3.0.1
*/
small {
  font-size: 0.5555555556em;
  /* 1 */
}

/*
    1:sub-sup. Prevent `sub` and `sup` affecting `line-height` in all browsers. - Normalize:v3.0.1
*/
sub,
sup {
  font-size: 75%;
  /* 1:sub-sup */
  line-height: 0;
  /* 1:sub-sup */
  position: relative;
  /* 1:sub-sup */
  vertical-align: baseline;
  /* 1:sub-sup */
}

sup {
  top: -0.5em;
  /* 1:sub-sup */
}

sub {
  bottom: -0.25em;
  /* 1:sub-sup */
}

/*  ==========================================================================
    Base:Forms
    ========================================================================== */
/*
    1. Give form elements some cursor interactions...
*/
label,
input,
textarea,
button,
select,
option {
  cursor: pointer;
  /* 1 */
}

.text-input:active,
.text-input:focus,
textarea:active,
textarea:focus {
  cursor: text;
  /* 1 */
}

/*
    Known limitation: by default, Chrome and Safari on OS X allow very limited
    styling of `select`, unless a `border` property is set.

    1. Correct color not being inherited.
       Known issue: affects color of disabled elements. - Normalize:v3.0.1
    2. Correct font properties not being inherited. - Normalize:v3.0.1
    3. Address margins set differently in Firefox 4+, Safari, and Chrome. - Normalize:v3.0.1
*/
button,
input,
optgroup,
select,
textarea {
  color: inherit;
  /* 1 */
  font: inherit;
  /* 2 */
  margin: 0;
  /* 3 */
}

/*
    1. Address `overflow` set to `hidden` in IE 8/9/10/11. - Normalize:v3.0.1
*/
button {
  overflow: visible;
  /* 1 */
}

/*
    1. Address inconsistent `text-transform` inheritance for `button` and `select`.
       All other form control elements do not inherit `text-transform` values.
       Correct `button` style inheritance in Firefox, IE 8/9/10/11, and Opera.
       Correct `select` style inheritance in Firefox. - Normalize:v3.0.1
*/
button,
select {
  text-transform: none;
  /* 1 */
}

/*
    1. Avoid the WebKit bug in Android 4.0.* where (2) destroys native `audio`
       and `video` controls. - Normalize:v3.0.1
    2. Correct inability to style clickable `input` types in iOS. - Normalize:v3.0.1
    3. Improve usability and consistency of cursor style between image-type
       `input` and others. - Normalize:v3.0.1
*/
button,
html input[type=button],
input[type=reset],
input[type=submit] {
  -webkit-appearance: button;
  /* 2 */
  cursor: pointer;
  /* 3 */
}

/*
    1. Re-set default cursor for disabled elements. - Normalize:v3.0.1
*/
button[disabled],
html input[disabled] {
  cursor: default;
}

/*
    1. Remove inner padding and border in Firefox 4+. - Normalize:v3.0.1
*/
button::-moz-focus-inner,
input::-moz-focus-inner {
  border: 0;
  /* 1 */
  padding: 0;
  /* 1 */
}

/*
    1. Address Firefox 4+ setting `line-height` on `input` using `!important` in
       the UA stylesheet. - Normalize:v3.0.1
*/
input {
  line-height: normal;
  /* 1 */
}

/*
    It's recommended that you don't attempt to style these elements.
    Firefox's implementation doesn't respect box-sizing, padding, or width.

    1. Address box sizing set to `content-box` in IE 8/9/10. - Normalize:v3.0.1
    2. Remove excess padding in IE 8/9/10. - Normalize:v3.0.1
*/
input[type=checkbox],
input[type=radio] {
  box-sizing: border-box;
  /* 1 */
  padding: 0;
  /* 2 */
}

/*
    1. Fix the cursor style for Chrome's increment/decrement buttons. For certain
       `font-size` values of the `input`, it causes the cursor style of the
       decrement button to change from `default` to `text`. - Normalize:v3.0.1
*/
input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  height: auto;
  /* 1 */
}

/*
    1. Address `appearance` set to `searchfield` in Safari and Chrome.
*/
input[type=search] {
  -webkit-appearance: textfield;
  /* 1 */
}

/*
    1. Remove inner padding and search cancel button in Safari and Chrome on OS X.
       Safari (but not Chrome) clips the cancel button when the search input has
       padding (and `textfield` appearance). - Normalize:v3.0.1
*/
input[type=search]::-webkit-search-cancel-button,
input[type=search]::-webkit-search-decoration {
  -webkit-appearance: none;
  /* 1 */
}

/*
    1. Correct `color` not being inherited in IE 8/9/10/11. - Normalize:v3.0.1
    2. Remove padding so people aren't caught out if they zero out fieldsets. - Normalize:v3.0.1
*/
legend {
  border: 0;
  /* 1 */
  padding: 0;
  /* 2 */
}

/*
    1. Remove default vertical scrollbar in IE 8/9/10/11. - Normalize:v3.0.1
*/
textarea {
  overflow: auto;
  /* 1 */
}

/*
    1. Don't inherit the `font-weight` (applied by a rule above).
       NOTE: the default cannot safely be changed in Chrome and Safari on OS X. - Normalize:v3.0.1
*/
optgroup {
  font-weight: bold;
  /* 1 */
}

.form-errors li {
  color: #b94a48;
}

/*  ==========================================================================
    Base:Grouping
    ========================================================================== */
/*
    1. Address margin not present in IE 8/9 and Safari 5. - Normalize:v3.0.1
*/
figure {
  margin: 1em 40px;
  /* 1 */
}

/*
    1. Address differences between Firefox and other browsers. - Normalize:v3.0.1
*/
hr {
  -moz-box-sizing: content-box;
  /* 1 */
  box-sizing: content-box;
  /* 1 */
  height: 0;
  /* 1 */
}

/*
    1. Contain overflow in all browsers. - Normalize:v3.0.1
*/
pre {
  overflow: auto;
  /* 1 */
}

/*
    1. Address odd `em`-unit font size rendering in all browsers. - Normalize:v2.1.0
*/
code,
kbd,
pre,
samp {
  font-family: monospace, monospace;
  /* 1 */
  font-size: 1em;
  /* 1 */
}

/*  ==========================================================================
    Base:Tables
    ========================================================================== */
/*
    1. Remove most spacing between table cells. - Normalize:v3.0.1
*/
table {
  border-collapse: collapse;
  /* 1 */
  border-spacing: 0;
  /* 1 */
}

/*  ==========================================================================
    Base:SVG
    ========================================================================== */
/*
    1. Correct overflow not hidden in IE 9/10/11. - Normalize:v3.0.1
*/
svg:not(:root) {
  overflow: hidden;
  /* 1 */
}

/*  ==========================================================================
    Base:Media
    ========================================================================== */
/*
    1. http://timkadlec.com/2012/10/ie10-snap-mode-and-responsive-design/
*/
@-ms-viewport {
  width: device-width;
  /* 1 */
}
.ui-helper-hidden {
  display: none;
}

.ui-helper-hidden-accessible {
  border: 0;
  clip: rect(0 0 0 0);
  height: 1px;
  margin: -1px;
  overflow: hidden;
  padding: 0;
  position: absolute;
  width: 1px;
}

.ui-helper-reset {
  margin: 0;
  padding: 0;
  border: 0;
  outline: 0;
  line-height: 1.3;
  text-decoration: none;
  font-size: 100%;
  list-style: none;
}

.ui-helper-clearfix:before, .ui-helper-clearfix:after {
  content: "";
  display: table;
  border-collapse: collapse;
}

.ui-helper-clearfix:after {
  clear: both;
}

.ui-helper-zfix {
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  position: absolute;
  opacity: 0;
  -ms-filter: "alpha(opacity=0)";
}

.ui-front {
  z-index: 100;
}

.ui-state-disabled {
  cursor: default !important;
  pointer-events: none;
}

.ui-icon {
  display: inline-block;
  vertical-align: middle;
  margin-top: -0.25em;
  position: relative;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
}

.ui-widget-icon-block {
  left: 50%;
  margin-left: -8px;
  display: block;
}

.ui-widget-overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ui-datepicker {
  width: 17em;
  padding: 0.2em 0.2em 0;
  display: none;
}

.ui-datepicker .ui-datepicker-header {
  position: relative;
  padding: 0.2em 0;
}

.ui-datepicker .ui-datepicker-prev, .ui-datepicker .ui-datepicker-next {
  position: absolute;
  top: 2px;
  width: 1.8em;
  height: 1.8em;
}

.ui-datepicker .ui-datepicker-prev-hover, .ui-datepicker .ui-datepicker-next-hover {
  top: 1px;
}

.ui-datepicker .ui-datepicker-prev {
  left: 2px;
}

.ui-datepicker .ui-datepicker-next {
  right: 2px;
}

.ui-datepicker .ui-datepicker-prev-hover {
  left: 1px;
}

.ui-datepicker .ui-datepicker-next-hover {
  right: 1px;
}

.ui-datepicker .ui-datepicker-prev span, .ui-datepicker .ui-datepicker-next span {
  display: block;
  position: absolute;
  left: 50%;
  margin-left: -8px;
  top: 50%;
  margin-top: -8px;
}

.ui-datepicker .ui-datepicker-title {
  margin: 0 2.3em;
  line-height: 1.8em;
  text-align: center;
}

.ui-datepicker .ui-datepicker-title select {
  font-size: 1em;
  margin: 1px 0;
}

.ui-datepicker select.ui-datepicker-month, .ui-datepicker select.ui-datepicker-year {
  width: 45%;
}

.ui-datepicker table {
  width: 100%;
  font-size: 0.9em;
  border-collapse: collapse;
  margin: 0 0 0.4em;
}

.ui-datepicker th {
  padding: 0.7em 0.3em;
  text-align: center;
  font-weight: bold;
  border: 0;
}

.ui-datepicker td {
  border: 0;
  padding: 1px;
}

.ui-datepicker td span, .ui-datepicker td a {
  display: block;
  padding: 0.2em;
  text-align: right;
  text-decoration: none;
}

.ui-datepicker .ui-datepicker-buttonpane {
  background-image: none;
  margin: 0.7em 0 0 0;
  padding: 0 0.2em;
  border-left: 0;
  border-right: 0;
  border-bottom: 0;
}

.ui-datepicker .ui-datepicker-buttonpane button {
  float: right;
  margin: 0.5em 0.2em 0.4em;
  cursor: pointer;
  padding: 0.2em 0.6em 0.3em 0.6em;
  width: auto;
  overflow: visible;
}

.ui-datepicker .ui-datepicker-buttonpane button.ui-datepicker-current {
  float: left;
}

.ui-datepicker.ui-datepicker-multi {
  width: auto;
}

.ui-datepicker-multi .ui-datepicker-group {
  float: left;
}

.ui-datepicker-multi .ui-datepicker-group table {
  width: 95%;
  margin: 0 auto 0.4em;
}

.ui-datepicker-multi-2 .ui-datepicker-group {
  width: 50%;
}

.ui-datepicker-multi-3 .ui-datepicker-group {
  width: 33.3%;
}

.ui-datepicker-multi-4 .ui-datepicker-group {
  width: 25%;
}

.ui-datepicker-multi .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-multi .ui-datepicker-group-middle .ui-datepicker-header {
  border-left-width: 0;
}

.ui-datepicker-multi .ui-datepicker-buttonpane {
  clear: left;
}

.ui-datepicker-row-break {
  clear: both;
  width: 100%;
  font-size: 0;
}

.ui-datepicker-rtl {
  direction: rtl;
}

.ui-datepicker-rtl .ui-datepicker-prev {
  right: 2px;
  left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next {
  left: 2px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-prev:hover {
  right: 1px;
  left: auto;
}

.ui-datepicker-rtl .ui-datepicker-next:hover {
  left: 1px;
  right: auto;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane {
  clear: right;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button {
  float: left;
}

.ui-datepicker-rtl .ui-datepicker-buttonpane button.ui-datepicker-current, .ui-datepicker-rtl .ui-datepicker-group {
  float: right;
}

.ui-datepicker-rtl .ui-datepicker-group-last .ui-datepicker-header, .ui-datepicker-rtl .ui-datepicker-group-middle .ui-datepicker-header {
  border-right-width: 0;
  border-left-width: 1px;
}

.ui-datepicker .ui-icon {
  display: block;
  text-indent: -99999px;
  overflow: hidden;
  background-repeat: no-repeat;
  left: 0.5em;
  top: 0.3em;
}

.ui-widget {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
}

.ui-widget .ui-widget {
  font-size: 1em;
}

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button {
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1em;
}

.ui-widget.ui-widget-content {
  border: 1px solid #c5c5c5;
}

.ui-widget-content {
  border: 1px solid #ddd;
  background: #fff;
  color: #333;
}

.ui-widget-content a {
  color: #333;
}

.ui-widget-header {
  border: 1px solid #ddd;
  background: #e9e9e9;
  color: #333;
  font-weight: bold;
}

.ui-widget-header a {
  color: #333;
}

.ui-state-default, .ui-widget-content .ui-state-default, .ui-widget-header .ui-state-default, .ui-button, html .ui-button.ui-state-disabled:hover, html .ui-button.ui-state-disabled:active {
  border: 1px solid #c5c5c5;
  background: #f6f6f6;
  font-weight: normal;
  color: #454545;
}

.ui-state-default a, .ui-state-default a:link, .ui-state-default a:visited, a.ui-button, a:link.ui-button, a:visited.ui-button, .ui-button {
  color: #454545;
  text-decoration: none;
}

.ui-state-hover, .ui-widget-content .ui-state-hover, .ui-widget-header .ui-state-hover, .ui-state-focus, .ui-widget-content .ui-state-focus, .ui-widget-header .ui-state-focus, .ui-button:hover, .ui-button:focus {
  border: 1px solid #ccc;
  background: #ededed;
  font-weight: normal;
  color: #2b2b2b;
}

.ui-state-hover a, .ui-state-hover a:hover, .ui-state-hover a:link, .ui-state-hover a:visited, .ui-state-focus a, .ui-state-focus a:hover, .ui-state-focus a:link, .ui-state-focus a:visited, a.ui-button:hover, a.ui-button:focus {
  color: #2b2b2b;
  text-decoration: none;
}

.ui-visual-focus {
  box-shadow: 0 0 3px 1px #5e9ed6;
}

.ui-state-active, .ui-widget-content .ui-state-active, .ui-widget-header .ui-state-active, a.ui-button:active, .ui-button:active, .ui-button.ui-state-active:hover {
  border: 1px solid #003eff;
  background: #007fff;
  font-weight: normal;
  color: #fff;
}

.ui-icon-background, .ui-state-active .ui-icon-background {
  border: #003eff;
  background-color: #fff;
}

.ui-state-active a, .ui-state-active a:link, .ui-state-active a:visited {
  color: #fff;
  text-decoration: none;
}

.ui-state-highlight, .ui-widget-content .ui-state-highlight, .ui-widget-header .ui-state-highlight {
  border: 1px solid #dad55e;
  background: #fffa90;
  color: #777620;
}

.ui-state-checked {
  border: 1px solid #dad55e;
  background: #fffa90;
}

.ui-state-highlight a, .ui-widget-content .ui-state-highlight a, .ui-widget-header .ui-state-highlight a {
  color: #777620;
}

.ui-state-error, .ui-widget-content .ui-state-error, .ui-widget-header .ui-state-error {
  border: 1px solid #f1a899;
  background: #fddfdf;
  color: #5f3f3f;
}

.ui-state-error a, .ui-widget-content .ui-state-error a, .ui-widget-header .ui-state-error a {
  color: #5f3f3f;
}

.ui-state-error-text, .ui-widget-content .ui-state-error-text, .ui-widget-header .ui-state-error-text {
  color: #5f3f3f;
}

.ui-priority-primary, .ui-widget-content .ui-priority-primary, .ui-widget-header .ui-priority-primary {
  font-weight: bold;
}

.ui-priority-secondary, .ui-widget-content .ui-priority-secondary, .ui-widget-header .ui-priority-secondary {
  opacity: 0.7;
  -ms-filter: "alpha(opacity=70)";
  font-weight: normal;
}

.ui-state-disabled, .ui-widget-content .ui-state-disabled, .ui-widget-header .ui-state-disabled {
  opacity: 0.35;
  -ms-filter: "alpha(opacity=35)";
  background-image: none;
}

.ui-state-disabled .ui-icon {
  -ms-filter: "alpha(opacity=35)";
}

.ui-icon {
  width: 16px;
  height: 16px;
}

.ui-icon, .ui-widget-content .ui-icon {
  background-image: url("images/ui-icons_444444_256x240.png");
}

.ui-widget-header .ui-icon {
  background-image: url("images/ui-icons_444444_256x240.png");
}

.ui-state-hover .ui-icon, .ui-state-focus .ui-icon, .ui-button:hover .ui-icon, .ui-button:focus .ui-icon {
  background-image: url("images/ui-icons_555555_256x240.png");
}

.ui-state-active .ui-icon, .ui-button:active .ui-icon {
  background-image: url("images/ui-icons_ffffff_256x240.png");
}

.ui-state-highlight .ui-icon, .ui-button .ui-state-highlight.ui-icon {
  background-image: url("images/ui-icons_777620_256x240.png");
}

.ui-state-error .ui-icon, .ui-state-error-text .ui-icon {
  background-image: url("images/ui-icons_cc0000_256x240.png");
}

.ui-button .ui-icon {
  background-image: url("images/ui-icons_777777_256x240.png");
}

.ui-icon-blank.ui-icon-blank.ui-icon-blank {
  background-image: none;
}

.ui-icon-caret-1-n {
  background-position: 0 0;
}

.ui-icon-caret-1-ne {
  background-position: -16px 0;
}

.ui-icon-caret-1-e {
  background-position: -32px 0;
}

.ui-icon-caret-1-se {
  background-position: -48px 0;
}

.ui-icon-caret-1-s {
  background-position: -65px 0;
}

.ui-icon-caret-1-sw {
  background-position: -80px 0;
}

.ui-icon-caret-1-w {
  background-position: -96px 0;
}

.ui-icon-caret-1-nw {
  background-position: -112px 0;
}

.ui-icon-caret-2-n-s {
  background-position: -128px 0;
}

.ui-icon-caret-2-e-w {
  background-position: -144px 0;
}

.ui-icon-triangle-1-n {
  background-position: 0 -16px;
}

.ui-icon-triangle-1-ne {
  background-position: -16px -16px;
}

.ui-icon-triangle-1-e {
  background-position: -32px -16px;
}

.ui-icon-triangle-1-se {
  background-position: -48px -16px;
}

.ui-icon-triangle-1-s {
  background-position: -65px -16px;
}

.ui-icon-triangle-1-sw {
  background-position: -80px -16px;
}

.ui-icon-triangle-1-w {
  background-position: -96px -16px;
}

.ui-icon-triangle-1-nw {
  background-position: -112px -16px;
}

.ui-icon-triangle-2-n-s {
  background-position: -128px -16px;
}

.ui-icon-triangle-2-e-w {
  background-position: -144px -16px;
}

.ui-icon-arrow-1-n {
  background-position: 0 -32px;
}

.ui-icon-arrow-1-ne {
  background-position: -16px -32px;
}

.ui-icon-arrow-1-e {
  background-position: -32px -32px;
}

.ui-icon-arrow-1-se {
  background-position: -48px -32px;
}

.ui-icon-arrow-1-s {
  background-position: -65px -32px;
}

.ui-icon-arrow-1-sw {
  background-position: -80px -32px;
}

.ui-icon-arrow-1-w {
  background-position: -96px -32px;
}

.ui-icon-arrow-1-nw {
  background-position: -112px -32px;
}

.ui-icon-arrow-2-n-s {
  background-position: -128px -32px;
}

.ui-icon-arrow-2-ne-sw {
  background-position: -144px -32px;
}

.ui-icon-arrow-2-e-w {
  background-position: -160px -32px;
}

.ui-icon-arrow-2-se-nw {
  background-position: -176px -32px;
}

.ui-icon-arrowstop-1-n {
  background-position: -192px -32px;
}

.ui-icon-arrowstop-1-e {
  background-position: -208px -32px;
}

.ui-icon-arrowstop-1-s {
  background-position: -224px -32px;
}

.ui-icon-arrowstop-1-w {
  background-position: -240px -32px;
}

.ui-icon-arrowthick-1-n {
  background-position: 1px -48px;
}

.ui-icon-arrowthick-1-ne {
  background-position: -16px -48px;
}

.ui-icon-arrowthick-1-e {
  background-position: -32px -48px;
}

.ui-icon-arrowthick-1-se {
  background-position: -48px -48px;
}

.ui-icon-arrowthick-1-s {
  background-position: -64px -48px;
}

.ui-icon-arrowthick-1-sw {
  background-position: -80px -48px;
}

.ui-icon-arrowthick-1-w {
  background-position: -96px -48px;
}

.ui-icon-arrowthick-1-nw {
  background-position: -112px -48px;
}

.ui-icon-arrowthick-2-n-s {
  background-position: -128px -48px;
}

.ui-icon-arrowthick-2-ne-sw {
  background-position: -144px -48px;
}

.ui-icon-arrowthick-2-e-w {
  background-position: -160px -48px;
}

.ui-icon-arrowthick-2-se-nw {
  background-position: -176px -48px;
}

.ui-icon-arrowthickstop-1-n {
  background-position: -192px -48px;
}

.ui-icon-arrowthickstop-1-e {
  background-position: -208px -48px;
}

.ui-icon-arrowthickstop-1-s {
  background-position: -224px -48px;
}

.ui-icon-arrowthickstop-1-w {
  background-position: -240px -48px;
}

.ui-icon-arrowreturnthick-1-w {
  background-position: 0 -64px;
}

.ui-icon-arrowreturnthick-1-n {
  background-position: -16px -64px;
}

.ui-icon-arrowreturnthick-1-e {
  background-position: -32px -64px;
}

.ui-icon-arrowreturnthick-1-s {
  background-position: -48px -64px;
}

.ui-icon-arrowreturn-1-w {
  background-position: -64px -64px;
}

.ui-icon-arrowreturn-1-n {
  background-position: -80px -64px;
}

.ui-icon-arrowreturn-1-e {
  background-position: -96px -64px;
}

.ui-icon-arrowreturn-1-s {
  background-position: -112px -64px;
}

.ui-icon-arrowrefresh-1-w {
  background-position: -128px -64px;
}

.ui-icon-arrowrefresh-1-n {
  background-position: -144px -64px;
}

.ui-icon-arrowrefresh-1-e {
  background-position: -160px -64px;
}

.ui-icon-arrowrefresh-1-s {
  background-position: -176px -64px;
}

.ui-icon-arrow-4 {
  background-position: 0 -80px;
}

.ui-icon-arrow-4-diag {
  background-position: -16px -80px;
}

.ui-icon-extlink {
  background-position: -32px -80px;
}

.ui-icon-newwin {
  background-position: -48px -80px;
}

.ui-icon-refresh {
  background-position: -64px -80px;
}

.ui-icon-shuffle {
  background-position: -80px -80px;
}

.ui-icon-transfer-e-w {
  background-position: -96px -80px;
}

.ui-icon-transferthick-e-w {
  background-position: -112px -80px;
}

.ui-icon-folder-collapsed {
  background-position: 0 -96px;
}

.ui-icon-folder-open {
  background-position: -16px -96px;
}

.ui-icon-document {
  background-position: -32px -96px;
}

.ui-icon-document-b {
  background-position: -48px -96px;
}

.ui-icon-note {
  background-position: -64px -96px;
}

.ui-icon-mail-closed {
  background-position: -80px -96px;
}

.ui-icon-mail-open {
  background-position: -96px -96px;
}

.ui-icon-suitcase {
  background-position: -112px -96px;
}

.ui-icon-comment {
  background-position: -128px -96px;
}

.ui-icon-person {
  background-position: -144px -96px;
}

.ui-icon-print {
  background-position: -160px -96px;
}

.ui-icon-trash {
  background-position: -176px -96px;
}

.ui-icon-locked {
  background-position: -192px -96px;
}

.ui-icon-unlocked {
  background-position: -208px -96px;
}

.ui-icon-bookmark {
  background-position: -224px -96px;
}

.ui-icon-tag {
  background-position: -240px -96px;
}

.ui-icon-home {
  background-position: 0 -112px;
}

.ui-icon-flag {
  background-position: -16px -112px;
}

.ui-icon-calendar {
  background-position: -32px -112px;
}

.ui-icon-cart {
  background-position: -48px -112px;
}

.ui-icon-pencil {
  background-position: -64px -112px;
}

.ui-icon-clock {
  background-position: -80px -112px;
}

.ui-icon-disk {
  background-position: -96px -112px;
}

.ui-icon-calculator {
  background-position: -112px -112px;
}

.ui-icon-zoomin {
  background-position: -128px -112px;
}

.ui-icon-zoomout {
  background-position: -144px -112px;
}

.ui-icon-search {
  background-position: -160px -112px;
}

.ui-icon-wrench {
  background-position: -176px -112px;
}

.ui-icon-gear {
  background-position: -192px -112px;
}

.ui-icon-heart {
  background-position: -208px -112px;
}

.ui-icon-star {
  background-position: -224px -112px;
}

.ui-icon-link {
  background-position: -240px -112px;
}

.ui-icon-cancel {
  background-position: 0 -128px;
}

.ui-icon-plus {
  background-position: -16px -128px;
}

.ui-icon-plusthick {
  background-position: -32px -128px;
}

.ui-icon-minus {
  background-position: -48px -128px;
}

.ui-icon-minusthick {
  background-position: -64px -128px;
}

.ui-icon-close {
  background-position: -80px -128px;
}

.ui-icon-closethick {
  background-position: -96px -128px;
}

.ui-icon-key {
  background-position: -112px -128px;
}

.ui-icon-lightbulb {
  background-position: -128px -128px;
}

.ui-icon-scissors {
  background-position: -144px -128px;
}

.ui-icon-clipboard {
  background-position: -160px -128px;
}

.ui-icon-copy {
  background-position: -176px -128px;
}

.ui-icon-contact {
  background-position: -192px -128px;
}

.ui-icon-image {
  background-position: -208px -128px;
}

.ui-icon-video {
  background-position: -224px -128px;
}

.ui-icon-script {
  background-position: -240px -128px;
}

.ui-icon-alert {
  background-position: 0 -144px;
}

.ui-icon-info {
  background-position: -16px -144px;
}

.ui-icon-notice {
  background-position: -32px -144px;
}

.ui-icon-help {
  background-position: -48px -144px;
}

.ui-icon-check {
  background-position: -64px -144px;
}

.ui-icon-bullet {
  background-position: -80px -144px;
}

.ui-icon-radio-on {
  background-position: -96px -144px;
}

.ui-icon-radio-off {
  background-position: -112px -144px;
}

.ui-icon-pin-w {
  background-position: -128px -144px;
}

.ui-icon-pin-s {
  background-position: -144px -144px;
}

.ui-icon-play {
  background-position: 0 -160px;
}

.ui-icon-pause {
  background-position: -16px -160px;
}

.ui-icon-seek-next {
  background-position: -32px -160px;
}

.ui-icon-seek-prev {
  background-position: -48px -160px;
}

.ui-icon-seek-end {
  background-position: -64px -160px;
}

.ui-icon-seek-start {
  background-position: -80px -160px;
}

.ui-icon-seek-first {
  background-position: -80px -160px;
}

.ui-icon-stop {
  background-position: -96px -160px;
}

.ui-icon-eject {
  background-position: -112px -160px;
}

.ui-icon-volume-off {
  background-position: -128px -160px;
}

.ui-icon-volume-on {
  background-position: -144px -160px;
}

.ui-icon-power {
  background-position: 0 -176px;
}

.ui-icon-signal-diag {
  background-position: -16px -176px;
}

.ui-icon-signal {
  background-position: -32px -176px;
}

.ui-icon-battery-0 {
  background-position: -48px -176px;
}

.ui-icon-battery-1 {
  background-position: -64px -176px;
}

.ui-icon-battery-2 {
  background-position: -80px -176px;
}

.ui-icon-battery-3 {
  background-position: -96px -176px;
}

.ui-icon-circle-plus {
  background-position: 0 -192px;
}

.ui-icon-circle-minus {
  background-position: -16px -192px;
}

.ui-icon-circle-close {
  background-position: -32px -192px;
}

.ui-icon-circle-triangle-e {
  background-position: -48px -192px;
}

.ui-icon-circle-triangle-s {
  background-position: -64px -192px;
}

.ui-icon-circle-triangle-w {
  background-position: -80px -192px;
}

.ui-icon-circle-triangle-n {
  background-position: -96px -192px;
}

.ui-icon-circle-arrow-e {
  background-position: -112px -192px;
}

.ui-icon-circle-arrow-s {
  background-position: -128px -192px;
}

.ui-icon-circle-arrow-w {
  background-position: -144px -192px;
}

.ui-icon-circle-arrow-n {
  background-position: -160px -192px;
}

.ui-icon-circle-zoomin {
  background-position: -176px -192px;
}

.ui-icon-circle-zoomout {
  background-position: -192px -192px;
}

.ui-icon-circle-check {
  background-position: -208px -192px;
}

.ui-icon-circlesmall-plus {
  background-position: 0 -208px;
}

.ui-icon-circlesmall-minus {
  background-position: -16px -208px;
}

.ui-icon-circlesmall-close {
  background-position: -32px -208px;
}

.ui-icon-squaresmall-plus {
  background-position: -48px -208px;
}

.ui-icon-squaresmall-minus {
  background-position: -64px -208px;
}

.ui-icon-squaresmall-close {
  background-position: -80px -208px;
}

.ui-icon-grip-dotted-vertical {
  background-position: 0 -224px;
}

.ui-icon-grip-dotted-horizontal {
  background-position: -16px -224px;
}

.ui-icon-grip-solid-vertical {
  background-position: -32px -224px;
}

.ui-icon-grip-solid-horizontal {
  background-position: -48px -224px;
}

.ui-icon-gripsmall-diagonal-se {
  background-position: -64px -224px;
}

.ui-icon-grip-diagonal-se {
  background-position: -80px -224px;
}

.ui-corner-all, .ui-corner-top, .ui-corner-left, .ui-corner-tl {
  border-top-left-radius: 3px;
}

.ui-corner-all, .ui-corner-top, .ui-corner-right, .ui-corner-tr {
  border-top-right-radius: 3px;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-left, .ui-corner-bl {
  border-bottom-left-radius: 3px;
}

.ui-corner-all, .ui-corner-bottom, .ui-corner-right, .ui-corner-br {
  border-bottom-right-radius: 3px;
}

.ui-widget-overlay {
  background: #aaa;
  opacity: 0.3;
  -ms-filter: Alpha(Opacity=30);
}

.ui-widget-shadow {
  -webkit-box-shadow: 0 0 5px #666;
  box-shadow: 0 0 5px #666;
}

/* 5 */
/* Slider */
.slick-slider {
  position: relative;
  display: block;
  box-sizing: border-box;
  -moz-box-sizing: border-box;
  -webkit-touch-callout: none;
  -webkit-user-select: none;
  -khtml-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  -ms-touch-action: pan-y;
  touch-action: pan-y;
  -webkit-tap-highlight-color: transparent;
}

.slick-list {
  position: relative;
  overflow: hidden;
  display: block;
  margin: 0;
  padding: 0;
}
.slick-list:focus {
  outline: none;
}
.slick-list.dragging {
  cursor: pointer;
  cursor: hand;
}

.slick-slider .slick-track,
.slick-slider .slick-list {
  -webkit-transform: translate3d(0, 0, 0);
  -moz-transform: translate3d(0, 0, 0);
  -ms-transform: translate3d(0, 0, 0);
  -o-transform: translate3d(0, 0, 0);
  transform: translate3d(0, 0, 0);
}

.slick-track {
  position: relative;
  left: 0;
  top: 0;
  display: block;
}
.slick-track:before, .slick-track:after {
  content: "";
  display: table;
}
.slick-track:after {
  clear: both;
}
.slick-loading .slick-track {
  visibility: hidden;
}

.slick-slide {
  float: left;
  height: 100%;
  min-height: 1px;
  display: none;
}
[dir=rtl] .slick-slide {
  float: right;
}
.slick-slide img {
  display: block;
}
.slick-slide.slick-loading img {
  display: none;
}
.slick-slide.dragging img {
  pointer-events: none;
}
.slick-initialized .slick-slide {
  display: block;
}
.slick-loading .slick-slide {
  visibility: hidden;
}
.slick-vertical .slick-slide {
  display: block;
  height: auto;
  border: 1px solid transparent;
}

/* 6 */
.alert {
  padding: 8px 14px;
  margin: 0 0 1em;
  text-shadow: 0 1px 0 rgba(255, 255, 255, 0.5);
  border-radius: 4px;
  clear: both;
}

.alert--success {
  background-color: #dff0d8;
  border-color: #d6e9c6;
  color: #468847;
}

.alert--error {
  background-color: #f2dede;
  border-color: #eed3d7;
  color: #b94a48;
}

/*  ==========================================================================
    Objects:Button
    ==========================================================================

    The button classes are best applied to links, buttons, and submit inputs.
    These components can be used in forms, as calls to action, or as part of the
    general UI of the site/app.

    Recommended use:

    <a class="Button [Button--modifier]" role="button" href="[url]">Button text</a>
    <button class="Button [Button--modifier]" type="submit">Button text</button>
    <input class="Button [Button--modifier]" type="submit" value="Button text">

    Button template

    Build on this component using rulesets in your application-level CSS.

    1.  Corrects inability to style clickable `input` types in iOS
    2.  Normalize `box-sizing` across all elements that this component could be
        applied to.
    3.  Allow easier styling.
    4.  Normalize `line-height`. For `input`, it can't be changed from `normal` in Firefox 4+.
    5.  Normalise box model styles.
    6.  Prevent button text from being selectable.
    7.  Stop buttons wrapping and looking broken.
*/
.btn {
  -webkit-appearance: none;
  /* 1 */
  box-sizing: border-box;
  /* 2 */
  cursor: pointer;
  display: inline-block;
  /* 3 */
  line-height: normal;
  /* 4 */
  margin: 0;
  border: 0;
  /* 5 */
  padding-top: 0;
  /* 5 */
  padding-bottom: 0;
  /* 5 */
  -moz-user-select: none;
  -ms-user-select: none;
  -webkit-user-select: none;
  user-select: none;
  /* 6 */
  white-space: nowrap;
  /* 7 */
  position: relative;
  text-decoration: none;
  /*
      Remove excess padding and border in Firefox 4+
  */
}
.btn, .btn:hover {
  text-decoration: none;
}
.btn:disabled, .btn.is-disabled {
  cursor: default;
}
.btn::-moz-focus-inner {
  border: 0;
  padding: 0;
}

/*
    Variations
    ----------

    e.g
    .btn--primary{}
    .btn--secondary{}
    .btn--tertiary{}
*/
#my-account-menu-btn {
  font-family: "objektiv-mk1", sans-serif;
  display: none;
}
@media (min-width: 46.875em) {
  #my-account-menu-btn {
    display: none;
  }
}

/*  ==========================================================================
    Objects:Grids
    ==========================================================================

    These grids are mostly from css wizardry grids:- https://github.com/csswizardry/csswizardry-grids
    but there is also some stuff from an older version of SUIT grid component:- https://github.com/suitcss/components-grid

    Fluid and nestable grid system, e.g.:

    <div class="grid">

        <div class="grid__cell one-third">
            <p>One third grid</p>
        </div><!--

     --><div class="grid__cell two-thirds">
            <p>Two thirds grid</p>
        </div><!--

     --><div class="grid__cell one-half">
            <p>One half grid</p>
        </div><!--

     --><div class="grid__cell one-quarter">
            <p>One quarter grid</p>
        </div><!--

     --><div class="grid__cell one-quarter">
            <p>One quarter grid</p>
        </div>

    </div>

*/
/*
    All content must be contained within child Grid-cell elements.

    1.  Account for browser defaults of elements that might be the root node of
        the component.
*/
.grid {
  display: block;
  /* 1 */
  padding: 0;
  /* 1 */
  margin: 0;
  /* 1 */
  margin-left: -1.6666666667em;
}

/*
    Very infrequently occurring grid wrappers as children of grid wrappers.
*/
.grid > .grid {
  margin-left: 0;
}

/*
    No explicit width by default. Rely on combining `Grid-cell` with a dimension
    utility or a component class that extends 'grid'.

    1. Fundamentals of the non-float grid layout.
    2. Controls vertical positioning of units.
    3. Make cells full-width by default.
*/
.grid__cell {
  display: inline-block;
  /* 1 */
  vertical-align: top;
  /* 2 */
  width: 100%;
  /* 3 */
  padding-left: 1.6666666667em;
}

/*
    Gutterless grids have all the properties of regular grids, minus any spacing.
*/
.grid--full {
  margin-left: 0;
}
.grid--full > .grid__cell {
  padding-left: 0;
}

/*
    Reversed grids allow you to structure your source in the opposite order to
    how your rendered layout will appear. Extends `.grid`.
*/
.grid--rev {
  direction: rtl;
  text-align: left;
}
.grid--rev > .grid__cell {
  direction: ltr;
  text-align: left;
}

/*  ==========================================================================
    Objects:Media
    ==========================================================================

    Image and text blocks, as devised by @stubbornella
    stubbornella.org/content/2010/06/25/the-media-object-saves-hundreds-of-lines-of-code

    This object is useful to document the usage of the overflow to create a new block formatting context pattern. I don't find myself using this actual object much as in a responsive context i often want a component to transform into a media object, so if i use the this object i end up fighting the styles.

    Dependencies
    ------------
    * group (clearfix) - must be applied to the main element.

    HTML
    ----
    <div class=media group>
        <img src=http://placehold.it/150x150 alt="" class=media__img>
        <p class=media__body>Lorem ipsum dolor sit amet, consectetur adipisicing elit,
        sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
    </div>

*/
.media {
  display: block;
}

.media__img {
  float: left;
  margin-right: 1.6666666667em;
}

/*
    Reversed image location (right instead of left).
*/
.media__img--rev {
  float: right;
  margin-left: 1.6666666667em;
}

.media__img img,
.media__img--rev img {
  display: block;
}

/*
    1. Create a new block formatting context. See - http://www.stubbornella.org/content/2009/07/23/overflow-a-secret-benefit/
*/
.media__body {
  overflow: hidden;
  /* 1 */
}

.media__body,
.media__body > :last-child {
  margin-bottom: 0;
}

/*  ==========================================================================
    Objects:Nav
    ==========================================================================

    By @csswizardry and taken from a version of inuit.css https://github.com/inuitcss

    When used on an `ol` or `ul`, this class throws the list into horizontal mode
    e.g.:

    Dependencies
    ------------
    * group (clearfix) - must be applied to the main element.

    HTML
    ----
    <ul class=nav group>
        <li><a href=#>Home</a></li>
        <li><a href=#>About</a></li>
        <li><a href=#>Portfolio</a></li>
        <li><a href=#>Contact</a></li>
    </ul>
*/
/*
    1:  Set line-height so positing does not depend on the global line-height set on the html element.
    Overwrite this value on a particular nav if you need to.
    This way if the global line-height changes it does not effect the navigations position.
*/
.nav {
  list-style: none;
  margin-left: 0;
  line-height: 1em;
  /* 1 */
}
.nav > li,
.nav > li > a {
  display: inline-block;
}

/*
    extends .nav and throws the list into vertical mode.
*/
.nav--stacked > li {
  display: list-item;
}
.nav--stacked > li > a {
  display: block;
}

/*
    Force a nav to occupy 100% of the available width of its parent. Extends
    .nav, original idea @pimpl
*/
.nav--fit {
  display: table;
  width: 100%;
}
.nav--fit > li {
  display: table-cell;
}
.nav--fit > li > a {
  display: block;
}

.flipping_book_wrapper {
  width: 100vw;
  max-width: 100%;
  margin: auto;
}
@media (min-width: 46.875em) {
  .flipping_book_wrapper {
    width: 78.51vw;
  }
}
@media (min-width: 96.875em) {
  .flipping_book_wrapper {
    width: 72vw;
  }
}
.flipping_book_wrapper .fp-iframe {
  height: 570px;
}
@media (min-width: 46.875em) {
  .flipping_book_wrapper .fp-iframe {
    height: 900px;
  }
}
.flipping_book_wrapper > * {
  width: 66.56vw;
}
@media (min-width: 46.875em) {
  .flipping_book_wrapper > * {
    width: 78.51vw;
  }
}
@media (min-width: 96.875em) {
  .flipping_book_wrapper > * {
    width: 72vw;
  }
}

._df_book {
  background-color: transparent !important;
}

.pubs_filter_wrapper {
  font-family: "objektiv-mk1", sans-serif;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 1rem;
  padding-right: 1rem;
}
.pubs_filter_wrapper #filters {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 30px auto;
  padding: 1.5rem 2rem 2rem 2rem;
  background-color: white;
  max-width: 1088px;
  border-radius: 10px;
}
@media (min-width: 46.875em) {
  .pubs_filter_wrapper #filters {
    flex-direction: row;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.pubs_filter_wrapper #filters .filter_wrapper {
  gap: 1rem;
  display: flex;
  align-items: center;
  flex-direction: column;
  width: 100%;
}
@media (min-width: 46.875em) {
  .pubs_filter_wrapper #filters .filter_wrapper {
    flex-direction: row;
    width: calc(33.33% - 1rem);
  }
}
.pubs_filter_wrapper #filters .filter_wrapper label {
  text-align: center;
  font-size: 19px;
  font-weight: 400;
}
.pubs_filter_wrapper #filters .filter_wrapper select {
  height: 36px;
  width: 100%;
  display: inline-block !important;
  white-space: nowrap;
  text-decoration: none !important;
  font-size: 13px !important;
  cursor: pointer;
  letter-spacing: 0;
  text-align: left;
  background: #FFF;
  border-radius: 8px;
  color: #231f20 !important;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  border: 2px solid #B9975B;
  outline: 0;
  padding: 0 10px !important;
}
.pubs_filter_wrapper #filters .filter_wrapper input#accommodation {
  position: relative;
  top: 2px;
}
.pubs_filter_wrapper .buttons_section {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  padding-left: 0;
  background-color: #2D2926;
  padding: 40px 0;
  margin: 0;
}
.pubs_filter_wrapper .buttons_section .button_filter {
  border: 2px solid #B9975B;
  border-radius: 8px;
  display: inline-block;
  padding: 15px 20px;
  margin: 5px;
  text-align: center;
  text-transform: uppercase;
  font-family: "objektiv-mk1", sans-serif;
  cursor: pointer;
  width: 180px;
  letter-spacing: 1.5px;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  color: #FFF;
  text-decoration: none;
}
.pubs_filter_wrapper .title {
  display: block;
  text-align: center;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 24px;
  color: #B9975B;
  line-height: 50px;
  letter-spacing: normal;
  padding-top: 40px;
  margin-bottom: 0;
}
.pubs_filter_wrapper .divider {
  background-color: #2D2926;
  padding: 0;
  margin: 0;
  width: 100%;
  min-width: 100%;
}
.pubs_filter_wrapper .subtitle, .pubs_filter_wrapper .pubs_cards_title {
  display: block !important;
  text-align: center;
  font-family: "objektiv-mk1", sans-serif !important;
  font-weight: 700 !important;
  font-size: 17px;
  color: #B9975B !important;
  line-height: 27px;
  letter-spacing: normal;
  margin: 40px auto !important;
  max-width: 1088px;
}
.pubs_filter_wrapper .pubs_cards_title {
  padding: 40px 0;
  font-size: 25px;
}
.pubs_filter_wrapper .cards_wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}
@media (min-width: 34.375em) {
  .pubs_filter_wrapper .cards_wrapper {
    flex-direction: row;
  }
}
.pubs_filter_wrapper .cards_wrapper #cardTemplate {
  display: none;
}
.pubs_filter_wrapper .cards_wrapper .card {
  width: 100%;
  text-decoration: none;
  color: black;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-start;
  padding: 0 10px;
  margin-bottom: 40px;
}
@media (min-width: 34.375em) {
  .pubs_filter_wrapper .cards_wrapper .card {
    width: 50%;
  }
}
@media (min-width: 56.25em) {
  .pubs_filter_wrapper .cards_wrapper .card {
    width: 33%;
  }
}
@media (min-width: 68.75em) {
  .pubs_filter_wrapper .cards_wrapper .card {
    width: 25%;
  }
}
.pubs_filter_wrapper .cards_wrapper .card .card_image {
  max-height: 200px;
  width: 100%;
  object-fit: cover;
}
.pubs_filter_wrapper .cards_wrapper .card .card_title,
.pubs_filter_wrapper .cards_wrapper .card .card_address,
.pubs_filter_wrapper .cards_wrapper .card .card_city {
  display: block;
  text-align: left;
}
.pubs_filter_wrapper .cards_wrapper .card .card_title {
  font-size: 25px;
  padding: 10px 0;
  font-family: "objektiv-mk1", sans-serif;
  text-transform: uppercase;
}
/* 7 */
.wm-links a {
  background: none;
  display: inline-block;
  color: #FFF7EB;
  border: 1px solid #FFF7EB;
  margin: 16px;
  font-size: 16px;
  text-align: left;
  white-space: normal;
  vertical-align: top;
  position: relative;
  padding: 10px 24px 10px 14px;
}
.wm-links a:after {
  content: ">";
  display: block;
  position: absolute;
  bottom: 10px;
  right: 5px;
}
.wm-links a:hover {
  transition: all 0.2s ease-in;
  background: #FFF7EB;
  color: #d3d3d3;
}

.wm-buttons a {
  background: none;
  display: inline-block;
  color: #FFF7EB;
  border: 1px solid #FFF7EB;
  margin: 16px;
  font-size: 16px;
  text-align: left;
  white-space: normal;
  vertical-align: top;
  position: relative;
  padding: 10px 24px 10px 14px;
}
.wm-buttons a:after {
  content: ">";
  display: block;
  position: absolute;
  bottom: 10px;
  right: 5px;
}
.wm-buttons a:hover {
  transition: all 0.2s ease-in;
  background: #FFF7EB;
  color: #d3d3d3;
}

@-webkit-keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
@-webkit-keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
@keyframes fadeOut {
  from {
    opacity: 1;
  }
  to {
    opacity: 0;
  }
}
.inner-wrap {
  overflow-x: hidden;
  max-width: 1920px;
  width: 100%;
  margin: 0 auto;
  position: relative;
}
.inner-wrap .pub-gallery .slick-track {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
}
.inner-wrap .pub-gallery .slick-track .slick-slide {
  height: auto;
  line-height: normal;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.inner-wrap .pub-gallery.pub-gallery-slider button {
  border: 2px solid #000 !important;
  width: 50px !important;
  height: 50px !important;
  margin: 0 auto;
  background-position: center;
  background-size: 32px;
}
.inner-wrap .pub-gallery.pub-gallery-slider button.slick-prev {
  background-image: url("../images/chevron-left-60.png") !important;
}
.inner-wrap .pub-gallery.pub-gallery-slider button.slick-next {
  background-image: url("../images/chevron-right-60.png") !important;
}

.divider {
  max-width: 146px;
  width: 100%;
  height: 11px;
  margin: 40px auto;
  background: url("../images/divider.png") no-repeat center center;
}
.divider.left-aligned {
  margin: 20px 0;
}

.block.off-white-bg {
  background-color: #f9f5eb;
}
.block.white-bg {
  background-color: #FFF;
}
.block.cream-bg {
  background-color: #f4ead7;
}
.block.gold-bg {
  background-color: #c3a76d;
}
.block.off-black-bg {
  background-color: #231f20;
}
.block.padded {
  padding: 50px;
}
@media (min-width: 46.875em) {
  .block.padded {
    padding: 90px;
  }
}
.block.padded.first-block-padding {
  padding: 90px 50px 70px;
}
@media (min-width: 46.875em) {
  .block.padded.first-block-padding {
    padding: 90px;
  }
}
.block.list ul {
  padding: 0;
  margin: 0;
}
.block.list ul li {
  list-style-type: none;
}
.block.list.cards ul {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.block.list.cards ul li {
  max-width: 100%;
  width: 100%;
  margin-bottom: 15px;
  border-radius: 0 0 5px 5px;
  background-color: #FFF;
}
@media (min-width: 46.875em) {
  .block.list.cards ul li {
    max-width: 48%;
  }
  .block.list.cards ul li:nth-child(odd):last-of-type {
    max-width: 100%;
  }
}
@media (min-width: 56.25em) {
  .block.list.cards ul li {
    max-width: 32%;
  }
  .block.list.cards ul li:nth-child(odd):last-of-type {
    max-width: 32%;
  }
}
.block.list.cards ul li a {
  text-decoration: none;
  color: #231f20;
}
.block.list.cards ul li img {
  width: 100%;
  border-radius: 5px 5px 0 0;
  display: block;
}
.block.list.cards ul li .title-block {
  text-align: center;
  padding: 15px 30px;
}
.block.list.cards ul li .title-block h4 {
  font-size: 16px;
  line-height: 20px;
}
.block.list.bg-ctas ul {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.block.list.bg-ctas ul.wrap {
  flex-wrap: wrap;
}
.block.list.bg-ctas ul.wrap li:last-of-type:nth-child(odd) {
  max-width: 100%;
  height: 380px;
}
.block.list.bg-ctas ul.no-wrap {
  flex-wrap: nowrap;
}
.block.list.bg-ctas ul li {
  max-width: 100%;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  height: 290px;
}
@media (min-width: 56.25em) {
  .block.list.bg-ctas ul li {
    max-width: 50%;
    height: 380px;
  }
  .block.list.bg-ctas ul li:last-of-type:nth-child(odd) {
    max-width: 100%;
  }
}
.block.list.bg-ctas ul li a {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: RGBA(38, 36, 32, 0.35);
  text-align: center;
  color: #FFF;
  font-size: 30px;
  text-decoration: none;
  padding: 0 25px;
}
.block.list.bg-ctas ul li a:hover {
  text-decoration: underline;
}
.block.list.bg-buttons ul {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}
.block.list.bg-buttons ul.wrap {
  flex-wrap: wrap;
}
.block.list.bg-buttons ul.wrap li:last-of-type:nth-child(odd) {
  max-width: 100%;
  height: 380px;
}
.block.list.bg-buttons ul.no-wrap {
  flex-wrap: nowrap;
}
.block.list.bg-buttons ul li {
  max-width: 100%;
  width: 100%;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 30px;
  overflow: hidden;
  margin-bottom: 30px;
  min-height: 144px;
}
@media (min-width: 56.25em) {
  .block.list.bg-buttons ul li:not(:first-child) {
    margin-left: 30px;
  }
}
@media (min-width: 56.25em) {
  .block.list.bg-buttons ul li {
    max-width: 25%;
  }
}
.block.list.bg-buttons ul li a {
  width: 100%;
  height: 100%;
  min-height: 144px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: RGBA(38, 36, 32, 0.35);
  text-align: center;
  color: #FFF;
  font-size: 20px;
  text-decoration: none;
  padding: 30px 45px;
}
.block.list.bg-buttons ul li a:hover {
  text-decoration: underline;
}
.block.offers-cards {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
@media (min-width: 56.25em) {
  .block.offers-cards {
    flex-direction: row;
  }
}
.block.offers-cards a {
  text-decoration: none;
}
.block.offers-cards .single-card {
  display: block;
  max-width: 100%;
  width: 100%;
  border-radius: 5px;
  overflow: hidden;
  text-decoration: none;
  position: relative;
  min-height: 200px;
  margin-bottom: 15px;
}
@media (min-width: 56.25em) {
  .block.offers-cards .single-card {
    max-width: 32%;
    margin-bottom: 0;
    min-height: 400px;
  }
}
.block.offers-cards .single-card img {
  width: 100%;
  border-radius: 5px;
  position: absolute;
  top: 0;
  left: 0;
  height: 500px;
  object-fit: cover;
}
.block.offers-cards .single-card .content-block {
  background-color: rgba(0, 0, 0, 0.5);
  position: absolute;
  top: 0;
  left: 0;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 15px 30px;
  width: 100%;
  height: 100%;
}
.block.offers-cards .single-card .content-block .title {
  font-size: 20px;
  line-height: 20px;
  color: #f9f5eb;
}
.block.offers-cards .single-card .content-block p {
  display: none;
  font-size: 16px;
  line-height: 20px;
  color: #f9f5eb;
  font-family: "objektiv-mk1", sans-serif;
}
@media (min-width: 56.25em) {
  .block.offers-cards .single-card .content-block:hover .title {
    display: none;
  }
  .block.offers-cards .single-card .content-block:hover p {
    display: block;
  }
}
.block.news ul {
  padding: 0;
  margin: 0 auto;
  max-width: 100%;
  display: flex;
  flex-wrap: wrap;
}
.block.news ul li {
  list-style-type: none;
  text-align: left;
  max-width: 100%;
  margin-bottom: 25px;
}
@media (min-width: 46.875em) {
  .block.news ul li {
    max-width: 32%;
    margin-right: 1%;
  }
}
.block.news ul li .thumb-wrap {
  width: 100%;
  height: 290px;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  border-radius: 5px 5px 0 0;
}
.block.news ul li .news-content {
  background-color: #FFF;
  padding: 25px;
  border-radius: 0 0 5px 5px;
}
.block.news ul li p {
  margin-top: 15px;
  text-align: left;
}
.block.news ul li p a {
  background-color: #FFF;
  color: #231f20;
  border: 1px solid #231f20;
  text-decoration: none;
  max-width: 140px;
  width: 100%;
  height: 35px;
  text-align: center;
  display: block !important;
  text-transform: uppercase;
  line-height: 1 !important;
  text-decoration: none !important;
  font-size: 14px !important;
  padding-top: 7px !important;
  font-family: "objektiv-mk1", sans-serif;
  margin-top: 10px;
}
.block.news .tool-pagination ul {
  padding: 0;
  margin: 0;
  justify-content: center;
}
.block.news .tool-pagination ul li {
  color: #c3a76d;
  margin: 0 15px 0 0;
  font-family: "objektiv-mk1", sans-serif;
}
.block.news .tool-pagination ul li a {
  color: #c3a76d;
  font-family: "objektiv-mk1", sans-serif;
}
.block.image-block {
  padding: 60px 0;
}
.block.image-block img {
  height: auto;
}
.block.standard-text-block {
  text-align: center;
  color: #231f20;
  padding: 30px;
}
@media (min-width: 46.875em) {
  .block.standard-text-block {
    padding: 60px 90px;
  }
}
.block.standard-text-block .main_title {
  font-size: 33px;
  line-height: 36px;
  font-family: "BrownStd_Bold", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0 0 20px;
  letter-spacing: 2px;
}
.block.standard-text-block.no-top-padding {
  padding-top: 0px;
}
.block.standard-text-block a.button {
  height: auto;
  padding: 10px 0;
  padding-top: 12px;
}
.block.standard-text-block.col-block {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.block.standard-text-block.col-block h3 {
  max-width: 100%;
  width: 100%;
}
.block.standard-text-block.col-block.txt-left p {
  text-align: left !important;
}
.block.standard-text-block.col-block.txt-left .left {
  order: 1;
}
.block.standard-text-block.col-block.txt-left .right {
  order: 2;
}
.block.standard-text-block.col-block.txt-right p {
  text-align: left !important;
}
.block.standard-text-block.col-block.txt-right .left {
  order: 1;
}
.block.standard-text-block.col-block.txt-right .right {
  order: 2;
}
@media (min-width: 56.25em) {
  .block.standard-text-block.col-block.txt-right .left {
    order: 2;
  }
  .block.standard-text-block.col-block.txt-right .right {
    order: 1;
  }
}
.block.standard-text-block.col-block .left {
  max-width: 100%;
  width: 100%;
}
@media (min-width: 56.25em) {
  .block.standard-text-block.col-block .left {
    max-width: 48%;
  }
  .block.standard-text-block.col-block .left.wide {
    max-width: 100%;
  }
  .block.standard-text-block.col-block .left h3 {
    text-align: left;
  }
}
.block.standard-text-block.col-block .right {
  max-width: 100%;
  width: 100%;
}
@media (min-width: 56.25em) {
  .block.standard-text-block.col-block .right {
    max-width: 48%;
  }
}
.block.standard-text-block.col-block picture {
  display: block;
}
.block.standard-text-block.col-block picture img {
  object-fit: cover;
  max-height: 300px;
  height: 100%;
  width: 100%;
}
.block.standard-text-block.col-block .column-wrapper {
  width: 100%;
  max-width: 1366px;
  margin: 0 auto;
  display: flex;
  gap: 2rem;
}
@media (min-width: 56.25em) {
  .block.standard-text-block.col-block .column-wrapper {
    gap: 3rem;
  }
}
.block.standard-text-block.col-block .column-wrapper .left,
.block.standard-text-block.col-block .column-wrapper .right {
  order: unset;
}
.block.standard-text-block.col-block .column-wrapper .gform_confirmation_message {
  background: black !important;
  border: 1.5px solid #2D2926 !important;
  border-radius: 5px !important;
  padding: 10px !important;
  outline: none !important;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 27px !important;
  color: #2D2926 !important;
}
.block.standard-text-block.col-block .column-wrapper .gform_wrapper {
  width: 100% !important;
  padding: 0px 20px 0px 20px !important;
}
@media (min-width: 56.25em) {
  .block.standard-text-block.col-block .column-wrapper .gform_wrapper {
    padding: 0px 40px 0px 40px !important;
  }
}
.block.standard-text-block.col-block .column-wrapper .gform_wrapper .gform_heading .gform_title {
  display: none !important;
  text-transform: uppercase !important;
  letter-spacing: normal !important;
  font-family: "objektiv-mk1", sans-serif !important;
  font-weight: 800 !important;
  font-size: 24px !important;
  color: #B9975B !important;
  line-height: 50px !important;
  margin-bottom: 17px !important;
}
.block.standard-text-block.col-block .column-wrapper .gform_wrapper .gform_validation_errors {
  background: #2D2926;
  border: 1.5px solid #B9975B;
  padding: 5px;
  outline: none;
}
.block.standard-text-block.col-block .column-wrapper .gform_wrapper .gform_validation_errors * {
  font-family: "objektiv-mk1", sans-serif;
  font-size: 13px;
  color: #B9975B;
}
.block.standard-text-block.col-block .column-wrapper .gform_wrapper .gfield_validation_message {
  background: #2D2926;
  border: 1.5px solid #B9975B;
  border-radius: 5px;
  padding: 5px;
  outline: none;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 13px;
  color: #B9975B;
}
.block.standard-text-block.col-block .column-wrapper .gform_wrapper .gform_required_legend {
  display: none;
}
.block.standard-text-block.col-block .column-wrapper .gform_wrapper .gfield--type-html {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  color: #2D2926;
}
.block.standard-text-block.col-block .column-wrapper .gform_wrapper label {
  color: #2D2926 !important;
  font-family: "objektiv-mk1", sans-serif !important;
  font-size: 18px !important;
}
.block.standard-text-block.col-block .column-wrapper .gform_wrapper input,
.block.standard-text-block.col-block .column-wrapper .gform_wrapper textarea {
  font-family: "objektiv-mk1", sans-serif !important;
  font-size: 14px !important;
  color: #2D2926 !important;
  background: transparent !important;
  border-radius: 8px !important;
  border: 2px solid #B9975B !important;
  outline: none !important;
  cursor: text !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  resize: none !important;
  overflow: hidden !important;
}
.block.standard-text-block.col-block .column-wrapper .gform_wrapper input::placeholder,
.block.standard-text-block.col-block .column-wrapper .gform_wrapper textarea::placeholder {
  color: #2D2926 !important;
}
.block.standard-text-block.col-block .column-wrapper .gform_wrapper input::-ms-input-placeholder,
.block.standard-text-block.col-block .column-wrapper .gform_wrapper textarea::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #2D2926 !important;
}
.block.standard-text-block.col-block .column-wrapper .gform_wrapper textarea {
  overflow-y: auto !important;
  resize: vertical !important;
}
.block.standard-text-block.col-block .column-wrapper .gform_wrapper .gchoice {
  display: flex !important;
  align-items: flex-start !important;
}
.block.standard-text-block.col-block .column-wrapper .gform_wrapper .gchoice input {
  margin-top: 5px !important;
  margin-right: 10px !important;
  border: 2px solid #B9975B !important;
  outline: none !important;
}
.block.standard-text-block.col-block .column-wrapper .gform_wrapper .gchoice label {
  font-family: "objektiv-mk1", sans-serif !important;
  font-weight: 300 !important;
  font-size: 15px !important;
  line-height: 24px !important;
  color: #2D2926 !important;
}
.block.standard-text-block.col-block .column-wrapper .gform_wrapper .gform_button {
  background: #B9975B !important;
  height: 36px !important;
  width: 100% !important;
  max-width: 165px !important;
  border: 0px !important;
  border-radius: 30px !important;
  cursor: pointer !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  text-align: left !important;
  color: #FFF !important;
  font-family: "objektiv-mk1", sans-serif !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  letter-spacing: 1.5px !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  background-image: url("../images/butcombe-btn-arrow.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 20px 12px !important;
  background-position: calc(100% - 20px) 50% !important;
  padding: 0px 29px 0px 20px !important;
}
.block.standard-text-block.col-block .column-wrapper .gform_wrapper .gform_button:hover {
  background: #2D2926 !important;
  background-image: url("../images/butcombe-btn-arrow.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 20px 12px !important;
  background-position: calc(100% - 20px) 50% !important;
  padding: 0px 29px 0px 20px !important;
}
.block.standard-text-block.col-block .column-wrapper.txt-left {
  flex-direction: column;
}
@media (min-width: 56.25em) {
  .block.standard-text-block.col-block .column-wrapper.txt-left {
    flex-direction: row;
  }
}
.block.standard-text-block.col-block .column-wrapper.txt-right {
  flex-direction: column;
}
@media (min-width: 56.25em) {
  .block.standard-text-block.col-block .column-wrapper.txt-right {
    flex-direction: row-reverse;
  }
}
.block.contact .christmas-wired-section {
  margin-bottom: 20px;
}
@media (min-width: 18.75em) {
  .block.contact .christmas-wired-section {
    display: block;
    margin-bottom: 40px;
  }
}
@media (min-width: 56.25em) {
  .block.contact .christmas-wired-section {
    margin-bottom: 20px;
  }
}
.block.contact a.button-wired {
  border-radius: 5px;
  background-color: #c3a76d !important;
  color: #FFF !important;
  font-family: "objektiv-mk1", sans-serif;
  text-decoration: none !important;
  padding: 5px 20px 8px 20px;
  max-width: 350px;
  font-size: 14px;
  display: inline-block;
}
@media (min-width: 18.75em) {
  .block.contact a.button-wired {
    display: block;
    margin-bottom: 20px;
  }
}
@media (min-width: 28.125em) {
  .block.contact a.button-wired {
    display: inline-block;
  }
}
.block.contact .contact-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.block.contact .contact-wrap .center-text-wired {
  text-align: center !important;
}
.block.contact .contact-wrap .clear-wired {
  clear: both;
}
.block.contact .contact-wrap .left {
  max-width: 100%;
  width: 100%;
  padding: 25px 0;
  text-align: center;
  margin: 0 auto;
}
@media (min-width: 56.25em) {
  .block.contact .contact-wrap .left {
    max-width: 48%;
  }
}
.block.contact .contact-wrap .left p {
  text-align: left !important;
}
.block.contact .contact-wrap .left p.center-text {
  text-align: center !important;
}
.block.contact .contact-wrap .left .widget_area iframe {
  display: block;
  margin: 0 auto;
}
.block.contact .contact-wrap .left .widget_area button {
  max-width: 350px;
  height: 40px;
}
.block.contact .contact-wrap .left .widget_area p {
  text-align: center !important;
}
.block.contact .contact-wrap .left .widget_area .center-text {
  text-align: center !important;
}
.block.contact .contact-wrap .left form .form-row {
  margin-bottom: 10px;
}
.block.contact .contact-wrap .left form .form-row.checkbox {
  display: flex;
  align-items: center;
}
.block.contact .contact-wrap .left form .form-row.checkbox .control-group {
  max-width: 11px;
  width: 100%;
  margin-right: 10px;
  padding-top: 5px;
}
.block.contact .contact-wrap .left form .form-row.checkbox p {
  margin-bottom: 0px;
}
.block.contact .contact-wrap .left form .form-row.checkbox input {
  max-width: 11px !important;
}
.block.contact .contact-wrap .left form label {
  display: none;
}
.block.contact .contact-wrap .left form input {
  padding: 10px 10px 10px 0;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  border-bottom: 2px solid #231f20;
  font-size: 14px;
  font-family: "objektiv-mk1", sans-serif;
  width: 100%;
  margin-bottom: 10px;
  color: #231f20;
}
.block.contact .contact-wrap .left form input::placeholder {
  color: #231f20;
}
.block.contact .contact-wrap .left form textarea {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid #231f20;
  font-family: "objektiv-mk1", sans-serif;
  background-color: transparent;
  font-size: 14px;
  height: 90px;
  width: 100%;
}
.block.contact .contact-wrap .left form textarea::placeholder {
  color: #231f20;
}
.block.contact .contact-wrap .left form select {
  min-width: 0 !important;
  width: 100%;
  margin: 0px !important;
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
  padding: 5px 5px 5px 0px;
  font-size: 14px;
  border-radius: 0px;
  border-top: 0;
  border-right: 0;
  margin-bottom: 10px;
  border-left: 0;
  border-bottom: 2px solid #231f20;
  background-color: transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("../images/down-pointer.png") no-repeat 95% 50%;
  background-size: 12px;
}
.block.contact .contact-wrap .left form button {
  border-radius: 5px;
  background-color: #c3a76d;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  width: 140px;
  height: 35px;
  font-size: 14px;
  line-height: 1;
}
.block.contact .contact-wrap .right {
  max-width: 100%;
  text-align: left;
  width: 100%;
  padding: 25px 0;
}
.block.contact .contact-wrap .right.christmas_area_right {
  display: none;
}
@media (min-width: 56.25em) {
  .block.contact .contact-wrap .right {
    max-width: 48%;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
  }
}
.block.contact .contact-wrap .right .christmas_area button {
  max-width: 350px;
  height: 40px;
}
.block.contact .contact-wrap .right .christmas_area p {
  text-align: center !important;
}
.block.contact .contact-wrap .right .christmas_area .center-text {
  text-align: center !important;
}
.block.contact .contact-wrap .right a {
  color: #B9975B;
  text-decoration: none;
}
.block.contact .contact-wrap .right a:hover {
  text-decoration: underline;
}
.block.contact .contact-wrap .right p {
  text-align: left;
}
.block.contact .contact-wrap .right p.center-text {
  text-align: center !important;
}
.block.contact .contact-wrap .right form {
  width: 100%;
  text-align: center !important;
}
.block.contact .contact-wrap .right form .form-row {
  margin-bottom: 30px;
}
.block.contact .contact-wrap .right form label {
  display: none;
}
.block.contact .contact-wrap .right form input {
  padding: 10px 10px 10px 0;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  border-bottom: 2px solid #231f20;
  font-size: 14px;
  max-width: 350px !important;
  font-family: "objektiv-mk1", sans-serif;
  width: 100%;
  color: #231f20;
}
.block.contact .contact-wrap .right form input::placeholder {
  color: #231f20;
}
.block.contact .contact-wrap .right form textarea {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid #231f20;
  font-family: "objektiv-mk1", sans-serif;
  background-color: transparent;
  font-size: 14px;
  height: 90px;
  max-width: 350px !important;
  width: 100%;
}
.block.contact .contact-wrap .right form textarea::placeholder {
  color: #231f20;
}
.block.contact .contact-wrap .right form select {
  max-width: 350px !important;
  min-width: 0 !important;
  width: 100%;
  margin: 0px !important;
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
  padding: 5px 5px 5px 0px;
  font-size: 14px;
  border-radius: 0px;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-bottom: 2px solid #231f20;
  background-color: transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("../images/down-pointer.png") no-repeat 95% 50%;
  background-size: 12px;
}
.block.contact .contact-wrap .right form button {
  border-radius: 5px;
  background-color: #c3a76d;
  color: #FFF;
  font-family: "jaf-domus-titling-web", sans-serif;
  width: 100%;
  height: 40px;
  max-width: 350px;
  font-size: 14px;
  line-height: 1;
}
.block.contact .contact-wrap .map {
  max-width: 100%;
  width: 100%;
}
.block.properties-main-wrap {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0px 20px 50px 20px;
  max-width: 1088px;
  margin: 0 auto;
  background-color: transparent;
}
@media (min-width: 46.875em) {
  .block.properties-main-wrap {
    padding: 0;
  }
}
.block.properties-main-wrap .filter-sidebar {
  max-width: 100%;
  width: 100%;
  margin-bottom: 25px;
}
@media (min-width: 46.875em) {
  .block.properties-main-wrap .filter-sidebar {
    max-width: 48%;
    margin-bottom: 0px;
  }
}
@media (min-width: 56.25em) {
  .block.properties-main-wrap .filter-sidebar {
    max-width: 23%;
  }
}
.block.properties-main-wrap .filter-sidebar .inner {
  background-color: #FFF;
  padding: 15px 25px;
}
@media (min-width: 46.875em) {
  .block.properties-main-wrap .filter-sidebar .inner {
    padding: 25px;
  }
}
.block.properties-main-wrap .filter-sidebar .inner h3 {
  background-image: url("../images/menu-down-point.png");
  background-repeat: no-repeat;
  background-position: right center;
  width: 100%;
}
@media (min-width: 46.875em) {
  .block.properties-main-wrap .filter-sidebar .inner h3 {
    background-image: none;
  }
}
.block.properties-main-wrap .filter-sidebar .inner h3.active {
  background-image: url("../images/menu-up-point.png");
}
@media (min-width: 46.875em) {
  .block.properties-main-wrap .filter-sidebar .inner h3.active {
    background-image: none;
  }
}
.block.properties-main-wrap .filter-sidebar .inner .search-bar {
  margin-top: 25px;
  display: none;
}
.block.properties-main-wrap .filter-sidebar .inner .search-bar.active {
  display: block;
}
@media (min-width: 46.875em) {
  .block.properties-main-wrap .filter-sidebar .inner .search-bar {
    display: block;
  }
}
.block.properties-main-wrap .filter-sidebar .inner .search-bar input {
  font-family: "objektiv-mk1", sans-serif;
  max-width: 100%;
  width: 100%;
  padding: 10px;
}
.block.properties-main-wrap .filter-sidebar .inner .search-bar .hidden {
  display: none;
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters {
  padding: 0;
  margin: 25px 0 0 0;
  display: none;
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters.active {
  display: block;
}
@media (min-width: 46.875em) {
  .block.properties-main-wrap .filter-sidebar .inner .category-filters {
    display: block;
  }
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li {
  list-style-type: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 15px;
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li:last-of-type {
  margin-bottom: 0px;
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li .cat-box,
.block.properties-main-wrap .filter-sidebar .inner .category-filters li .cat-box-offers {
  appearance: none;
  -moz-appearance: none;
  -webkit-appearance: none;
  border: 1px solid #231f20;
  border-radius: 0px;
  width: 15px;
  height: 15px;
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li .cat-box:checked,
.block.properties-main-wrap .filter-sidebar .inner .category-filters li .cat-box-offers:checked {
  background: url("../images/cat-box-check.png") no-repeat center center;
  border: 0;
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.label {
  display: block;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 16px;
  color: #231f20;
  padding-left: 15px;
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon {
  display: block;
  background-position: center center;
  background-repeat: no-repeat;
  max-width: 20px;
  height: 20px;
  width: 100%;
  margin-left: 15px;
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.accommodation {
  background-image: url("../images/Pubs_Icons-Accommodation.svg");
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.bar-food {
  background-image: url("../images/Pubs_Icons-Bar_Food.svg");
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.dog-friendly {
  background-image: url("../images/Pubs_Icons-Dog_Friendly.svg");
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.family-friendly {
  background-image: url("../images/Pubs_Icons-Family.svg");
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.kids-play-area {
  background-image: url("../images/Pubs_Icons-Play_Area.svg");
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.live-music {
  background-image: url("../images/Pubs_Icons-Music.svg");
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.live-sport {
  background-image: url("../images/Pubs_Icons-Sport.svg");
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.loyalty {
  background-image: url("../images/Pubs_Icons-_Loyalty.svg");
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.managed {
  background-image: url("../images/Pubs_Icons-Managed.svg");
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.near-me {
  background-image: url("../images/Pubs_Icons-Near_me.svg");
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.outside-seating {
  background-image: url("../images/Pubs_Icons-Outside_Seating.svg");
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.parking {
  background-image: url("../images/Pubs_Icons-Parking.svg");
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.restaurant {
  background-image: url("../images/Pubs_Icons-Restaurant.svg");
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.tenanted {
  background-image: url("../images/Pubs_Icons-Tenanted.svg");
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.wifi {
  background-image: url("../images/Pubs_Icons-Wifi.svg");
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.offers {
  background-image: url("../images/offer-icon-offer.svg");
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.events {
  background-image: url("../images/offer-icon-event.svg");
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.private-hire {
  background-image: url("../images/Private_Hire_2_Retina.png");
  background-size: 20px 20px;
}
.block.properties-main-wrap .filter-sidebar .inner .category-filters li span.icon.wedding-venue {
  background-image: url("../images/Wedding_1_Retina.png");
  background-size: 20px 20px;
}
.block.properties-main-wrap .properties-list,
.block.properties-main-wrap .offers-list {
  max-width: 100%;
  width: 100%;
  color: transparent;
  font-size: 0px;
}
@media (min-width: 46.875em) {
  .block.properties-main-wrap .properties-list,
.block.properties-main-wrap .offers-list {
    max-width: 48%;
  }
}
@media (min-width: 56.25em) {
  .block.properties-main-wrap .properties-list,
.block.properties-main-wrap .offers-list {
    max-width: 75%;
  }
}
.block.properties-main-wrap .properties-list.landing,
.block.properties-main-wrap .offers-list.landing {
  display: block;
}
.block.properties-main-wrap .properties-list.landing.inactive,
.block.properties-main-wrap .offers-list.landing.inactive {
  display: none;
}
.block.properties-main-wrap .properties-list.search-results,
.block.properties-main-wrap .offers-list.search-results {
  display: none;
}
.block.properties-main-wrap .properties-list.search-results.active,
.block.properties-main-wrap .offers-list.search-results.active {
  display: block;
}
.block.properties-main-wrap .properties-list.no-sidebar,
.block.properties-main-wrap .offers-list.no-sidebar {
  max-width: 100%;
  width: 100%;
}
@media (min-width: 46.875em) {
  .block.properties-main-wrap .properties-list.no-sidebar,
.block.properties-main-wrap .offers-list.no-sidebar {
    max-width: 100%;
  }
}
@media (min-width: 56.25em) {
  .block.properties-main-wrap .properties-list.no-sidebar,
.block.properties-main-wrap .offers-list.no-sidebar {
    max-width: 100%;
  }
}
.block.properties-main-wrap .properties-list.no-sidebar ul,
.block.properties-main-wrap .offers-list.no-sidebar ul {
  justify-content: space-between;
}
@media (min-width: 46.875em) {
  .block.properties-main-wrap .properties-list.no-sidebar ul,
.block.properties-main-wrap .offers-list.no-sidebar ul {
    justify-content: space-between;
  }
}
@media (min-width: 56.25em) {
  .block.properties-main-wrap .properties-list.no-sidebar ul,
.block.properties-main-wrap .offers-list.no-sidebar ul {
    justify-content: flex-start;
    gap: 0.75rem;
  }
}
.block.properties-main-wrap .properties-list.no-sidebar ul li,
.block.properties-main-wrap .offers-list.no-sidebar ul li {
  max-width: 100%;
  width: 100%;
  border-radius: 8px;
  overflow: hidden;
}
@media (min-width: 46.875em) {
  .block.properties-main-wrap .properties-list.no-sidebar ul li,
.block.properties-main-wrap .offers-list.no-sidebar ul li {
    max-width: 49%;
  }
}
@media (min-width: 64.125em) {
  .block.properties-main-wrap .properties-list.no-sidebar ul li,
.block.properties-main-wrap .offers-list.no-sidebar ul li {
    width: 352px;
    max-width: calc(33.33% - 0.5rem);
  }
}
.block.properties-main-wrap .properties-list.no-sidebar ul li .thumb-wrap,
.block.properties-main-wrap .offers-list.no-sidebar ul li .thumb-wrap {
  max-height: 230px;
}
.block.properties-main-wrap .properties-list.no-sidebar ul li .thumb-wrap .image-wrapper,
.block.properties-main-wrap .offers-list.no-sidebar ul li .thumb-wrap .image-wrapper {
  position: relative;
  overflow: hidden;
}
.block.properties-main-wrap .properties-list.no-sidebar ul li .thumb-wrap .image-wrapper .overlay,
.block.properties-main-wrap .offers-list.no-sidebar ul li .thumb-wrap .image-wrapper .overlay {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 400;
  line-height: 20px;
  text-align: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  max-height: 230px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(31, 33, 27, 0.5);
  font-size: 20px;
  letter-spacing: 2px;
  text-transform: uppercase;
  color: white;
  border-radius: 4px;
  opacity: 0;
  transition: opacity 1s ease;
}
.block.properties-main-wrap .properties-list.no-sidebar ul li .thumb-wrap .image-wrapper:hover .overlay,
.block.properties-main-wrap .offers-list.no-sidebar ul li .thumb-wrap .image-wrapper:hover .overlay {
  opacity: 1;
}
.block.properties-main-wrap .properties-list.no-sidebar ul li .thumb-wrap .image-wrapper .boutique-overlay,
.block.properties-main-wrap .offers-list.no-sidebar ul li .thumb-wrap .image-wrapper .boutique-overlay {
  display: none;
}
.block.properties-main-wrap .properties-list.no-sidebar ul li .thumb-wrap .image-wrapper.is-boutique .boutique-overlay,
.block.properties-main-wrap .offers-list.no-sidebar ul li .thumb-wrap .image-wrapper.is-boutique .boutique-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 35px;
  width: 157px;
  max-width: 100%;
  top: 0;
  left: 0;
  background: #B9975B;
  text-transform: uppercase;
  color: #FFF7EB;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 12px;
  letter-spacing: 1.5px;
  border-bottom-right-radius: 8px;
}
.block.properties-main-wrap .properties-list h3,
.block.properties-main-wrap .offers-list h3 {
  color: #231f20;
  font-size: 24px;
  text-align: center;
}
.block.properties-main-wrap .properties-list h3 span,
.block.properties-main-wrap .offers-list h3 span {
  font-size: 14px;
  font-weight: 400;
}
.block.properties-main-wrap .properties-list ul,
.block.properties-main-wrap .offers-list ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.block.properties-main-wrap .properties-list ul li,
.block.properties-main-wrap .offers-list ul li {
  list-style-type: none;
  max-width: 100%;
  width: 100%;
  background-color: #FFF;
  margin-bottom: 25px;
}
@media (min-width: 46.875em) {
  .block.properties-main-wrap .properties-list ul li,
.block.properties-main-wrap .offers-list ul li {
    max-width: 100%;
  }
}
@media (min-width: 56.25em) {
  .block.properties-main-wrap .properties-list ul li,
.block.properties-main-wrap .offers-list ul li {
    max-width: 48%;
  }
}
.block.properties-main-wrap .properties-list ul li .thumb-wrap,
.block.properties-main-wrap .offers-list ul li .thumb-wrap {
  width: 100%;
  max-height: 285px;
  overflow: hidden;
}
.block.properties-main-wrap .properties-list ul li .thumb-wrap img,
.block.properties-main-wrap .offers-list ul li .thumb-wrap img {
  width: 100%;
  display: block;
  border-radius: 5px 5px 0 0;
  min-height: 300px;
  object-fit: cover;
}
.block.properties-main-wrap .properties-list ul li .property-details,
.block.properties-main-wrap .properties-list ul li .offer-details,
.block.properties-main-wrap .offers-list ul li .property-details,
.block.properties-main-wrap .offers-list ul li .offer-details {
  padding: 1.5rem;
  border-radius: 0 0 5px 5px;
}
.block.properties-main-wrap .properties-list ul li .property-details h3,
.block.properties-main-wrap .properties-list ul li .offer-details h3,
.block.properties-main-wrap .offers-list ul li .property-details h3,
.block.properties-main-wrap .offers-list ul li .offer-details h3 {
  color: #231f20;
  letter-spacing: normal;
  text-align: left;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 18px;
  line-height: 28px;
  text-transform: uppercase;
}
.block.properties-main-wrap .properties-list ul li .property-details p,
.block.properties-main-wrap .properties-list ul li .offer-details p,
.block.properties-main-wrap .offers-list ul li .property-details p,
.block.properties-main-wrap .offers-list ul li .offer-details p {
  color: #231f20;
  margin-bottom: 15px;
  min-height: 40px;
  height: auto;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  margin-top: 0.5rem;
}
@media (min-width: 64.125em) {
  .block.properties-main-wrap .properties-list ul li .property-details p,
.block.properties-main-wrap .properties-list ul li .offer-details p,
.block.properties-main-wrap .offers-list ul li .property-details p,
.block.properties-main-wrap .offers-list ul li .offer-details p {
    min-height: unset;
  }
}
@media (min-width: 81.5em) {
  .block.properties-main-wrap .properties-list ul li .property-details p,
.block.properties-main-wrap .properties-list ul li .offer-details p,
.block.properties-main-wrap .offers-list ul li .property-details p,
.block.properties-main-wrap .offers-list ul li .offer-details p {
    min-height: 40px;
  }
}
.block.properties-main-wrap .properties-list ul li .property-details .cta-button,
.block.properties-main-wrap .properties-list ul li .offer-details .cta-button,
.block.properties-main-wrap .offers-list ul li .property-details .cta-button,
.block.properties-main-wrap .offers-list ul li .offer-details .cta-button {
  margin-top: 1rem;
  background: transparent;
  width: 100%;
  display: block;
  color: #B9975B;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.block.properties-main-wrap .properties-list ul li .property-details a.button.white,
.block.properties-main-wrap .properties-list ul li .offer-details a.button.white,
.block.properties-main-wrap .offers-list ul li .property-details a.button.white,
.block.properties-main-wrap .offers-list ul li .offer-details a.button.white {
  margin-right: 10px;
}
.block.properties-main-wrap .properties-list ul li .property-details .buttons-wrapper,
.block.properties-main-wrap .properties-list ul li .offer-details .buttons-wrapper,
.block.properties-main-wrap .offers-list ul li .property-details .buttons-wrapper,
.block.properties-main-wrap .offers-list ul li .offer-details .buttons-wrapper {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
  align-items: stretch;
  justify-content: space-between;
  margin-top: 15px;
}
.block.properties-main-wrap .properties-list ul li .property-details .buttons-wrapper a.button_1,
.block.properties-main-wrap .properties-list ul li .offer-details .buttons-wrapper a.button_1,
.block.properties-main-wrap .offers-list ul li .property-details .buttons-wrapper a.button_1,
.block.properties-main-wrap .offers-list ul li .offer-details .buttons-wrapper a.button_1 {
  line-height: normal;
  display: flex !important;
  float: none;
  min-width: 48%;
  background: #B9975B;
  height: 36px;
  width: 100%;
  max-width: 187px;
  border-radius: 30px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  -webkit-box-pack: start;
  -ms-flex-pack: start;
  justify-content: flex-start;
  color: #fff;
  font-family: objektiv-mk1, sans-serif;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url("/content/themes/standard/images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 20px) 50%;
  padding: 0 20px;
  margin-top: 0.5rem;
}
.block.properties-main-wrap .properties-list ul li .property-details .buttons-wrapper a.button_1:hover,
.block.properties-main-wrap .properties-list ul li .offer-details .buttons-wrapper a.button_1:hover,
.block.properties-main-wrap .offers-list ul li .property-details .buttons-wrapper a.button_1:hover,
.block.properties-main-wrap .offers-list ul li .offer-details .buttons-wrapper a.button_1:hover {
  color: #FFF !important;
  background-color: #231f20 !important;
}
.block.properties-main-wrap .properties-list ul li .property-details .buttons-wrapper a.button_1.wide,
.block.properties-main-wrap .properties-list ul li .offer-details .buttons-wrapper a.button_1.wide,
.block.properties-main-wrap .offers-list ul li .property-details .buttons-wrapper a.button_1.wide,
.block.properties-main-wrap .offers-list ul li .offer-details .buttons-wrapper a.button_1.wide {
  min-width: 100%;
}
.block.properties-main-wrap .properties-list ul li .property-details .buttons-wrapper a.button_1.button-direction,
.block.properties-main-wrap .properties-list ul li .offer-details .buttons-wrapper a.button_1.button-direction,
.block.properties-main-wrap .offers-list ul li .property-details .buttons-wrapper a.button_1.button-direction,
.block.properties-main-wrap .offers-list ul li .offer-details .buttons-wrapper a.button_1.button-direction {
  min-width: 100%;
  color: #FFF !important;
  background-color: #231f20 !important;
}
.block.properties-main-wrap .properties-list ul li .property-details .buttons-wrapper a.button_1.button-direction:hover,
.block.properties-main-wrap .properties-list ul li .offer-details .buttons-wrapper a.button_1.button-direction:hover,
.block.properties-main-wrap .offers-list ul li .property-details .buttons-wrapper a.button_1.button-direction:hover,
.block.properties-main-wrap .offers-list ul li .offer-details .buttons-wrapper a.button_1.button-direction:hover {
  color: #231f20 !important;
  background-color: #f8f5ec !important;
  border-color: #f8f5ec !important;
}
.block.properties-main-wrap .properties-list ul li .property-details .buttons-wrapper a.button_1:nth-child(2),
.block.properties-main-wrap .properties-list ul li .offer-details .buttons-wrapper a.button_1:nth-child(2),
.block.properties-main-wrap .offers-list ul li .property-details .buttons-wrapper a.button_1:nth-child(2),
.block.properties-main-wrap .offers-list ul li .offer-details .buttons-wrapper a.button_1:nth-child(2) {
  background-color: #231f20 !important;
}
.block.properties-main-wrap .properties-list ul li .property-details .buttons-wrapper a.button_1:nth-child(2):hover,
.block.properties-main-wrap .properties-list ul li .offer-details .buttons-wrapper a.button_1:nth-child(2):hover,
.block.properties-main-wrap .offers-list ul li .property-details .buttons-wrapper a.button_1:nth-child(2):hover,
.block.properties-main-wrap .offers-list ul li .offer-details .buttons-wrapper a.button_1:nth-child(2):hover {
  background-color: #B9975B !important;
}
.block.properties-main-wrap .properties-list ul li .property-details .buttons-wrapper a.button.white,
.block.properties-main-wrap .properties-list ul li .offer-details .buttons-wrapper a.button.white,
.block.properties-main-wrap .offers-list ul li .property-details .buttons-wrapper a.button.white,
.block.properties-main-wrap .offers-list ul li .offer-details .buttons-wrapper a.button.white {
  margin-top: 5px;
  margin-right: 0;
}
.block.properties-main-wrap .properties-list ul li .property-details ul,
.block.properties-main-wrap .properties-list ul li .offer-details ul,
.block.properties-main-wrap .offers-list ul li .property-details ul,
.block.properties-main-wrap .offers-list ul li .offer-details ul {
  display: none;
  flex-wrap: wrap;
  justify-content: flex-start;
  margin-bottom: 15px;
}
@media (min-width: 79em) {
  .block.properties-main-wrap .properties-list ul li .property-details ul,
.block.properties-main-wrap .properties-list ul li .offer-details ul,
.block.properties-main-wrap .offers-list ul li .property-details ul,
.block.properties-main-wrap .offers-list ul li .offer-details ul {
    min-height: unset;
  }
}
.block.properties-main-wrap .properties-list ul li .property-details ul li,
.block.properties-main-wrap .properties-list ul li .offer-details ul li,
.block.properties-main-wrap .offers-list ul li .property-details ul li,
.block.properties-main-wrap .offers-list ul li .offer-details ul li {
  font-size: 0px;
  max-width: 20px;
  height: 20px;
  background-position: center center;
  background-repeat: no-repeat;
  margin-right: 10px;
  margin-bottom: 5px;
}
@media (min-width: 46.875em) {
  .block.properties-main-wrap .properties-list ul li .property-details ul li,
.block.properties-main-wrap .properties-list ul li .offer-details ul li,
.block.properties-main-wrap .offers-list ul li .property-details ul li,
.block.properties-main-wrap .offers-list ul li .offer-details ul li {
    margin-right: 15px;
  }
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.accommodation,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.accommodation,
.block.properties-main-wrap .offers-list ul li .property-details ul li.accommodation,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.accommodation {
  background-image: url("../images/Pubs_Icons-Accommodation.svg");
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.bar-food,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.bar-food,
.block.properties-main-wrap .offers-list ul li .property-details ul li.bar-food,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.bar-food {
  background-image: url("../images/Pubs_Icons-Bar_Food.svg");
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.dog-friendly,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.dog-friendly,
.block.properties-main-wrap .offers-list ul li .property-details ul li.dog-friendly,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.dog-friendly {
  background-image: url("../images/Pubs_Icons-Dog_Friendly.svg");
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.family-friendly,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.family-friendly,
.block.properties-main-wrap .offers-list ul li .property-details ul li.family-friendly,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.family-friendly {
  background-image: url("../images/Pubs_Icons-Family.svg");
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.kids-play-area,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.kids-play-area,
.block.properties-main-wrap .offers-list ul li .property-details ul li.kids-play-area,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.kids-play-area {
  background-image: url("../images/Pubs_Icons-Play_Area.svg");
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.live-music,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.live-music,
.block.properties-main-wrap .offers-list ul li .property-details ul li.live-music,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.live-music {
  background-image: url("../images/Pubs_Icons-Music.svg");
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.live-sport,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.live-sport,
.block.properties-main-wrap .offers-list ul li .property-details ul li.live-sport,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.live-sport {
  background-image: url("../images/Pubs_Icons-Sport.svg");
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.loyalty,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.loyalty,
.block.properties-main-wrap .offers-list ul li .property-details ul li.loyalty,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.loyalty {
  background-image: url("../images/Pubs_Icons-_Loyalty.svg");
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.managed,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.managed,
.block.properties-main-wrap .offers-list ul li .property-details ul li.managed,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.managed {
  background-image: url("../images/Pubs_Icons-Managed.svg");
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.near-me,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.near-me,
.block.properties-main-wrap .offers-list ul li .property-details ul li.near-me,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.near-me {
  background-image: url("../images/Pubs_Icons-Near_me.svg");
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.outside-seating,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.outside-seating,
.block.properties-main-wrap .offers-list ul li .property-details ul li.outside-seating,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.outside-seating {
  background-image: url("../images/Pubs_Icons-Outside_Seating.svg");
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.parking,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.parking,
.block.properties-main-wrap .offers-list ul li .property-details ul li.parking,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.parking {
  background-image: url("../images/Pubs_Icons-Parking.svg");
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.restaurant,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.restaurant,
.block.properties-main-wrap .offers-list ul li .property-details ul li.restaurant,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.restaurant {
  background-image: url("../images/Pubs_Icons-Restaurant.svg");
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.tenanted,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.tenanted,
.block.properties-main-wrap .offers-list ul li .property-details ul li.tenanted,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.tenanted {
  background-image: url("../images/Pubs_Icons-Tenanted.svg");
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.wifi,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.wifi,
.block.properties-main-wrap .offers-list ul li .property-details ul li.wifi,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.wifi {
  background-image: url("../images/Pubs_Icons-Wifi.svg");
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.offers,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.offers,
.block.properties-main-wrap .offers-list ul li .property-details ul li.offers,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.offers {
  background-image: url("../images/offer-icon-offer.svg");
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.events,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.events,
.block.properties-main-wrap .offers-list ul li .property-details ul li.events,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.events {
  background-image: url("../images/offer-icon-event.svg");
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.private-hire,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.private-hire,
.block.properties-main-wrap .offers-list ul li .property-details ul li.private-hire,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.private-hire {
  background-image: url("../images/Private_Hire_2_Retina.png");
  background-size: 20px 20px;
}
.block.properties-main-wrap .properties-list ul li .property-details ul li.wedding-venue,
.block.properties-main-wrap .properties-list ul li .offer-details ul li.wedding-venue,
.block.properties-main-wrap .offers-list ul li .property-details ul li.wedding-venue,
.block.properties-main-wrap .offers-list ul li .offer-details ul li.wedding-venue {
  background-image: url("../images/Wedding_1_Retina.png");
  background-size: 20px 20px;
}
.block.single-pub.top-bar {
  width: 100%;
  padding: 50px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media (min-width: 56.25em) {
  .block.single-pub.top-bar {
    padding: 45px 90px;
  }
}
.block.single-pub.top-bar .address {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 25px;
}
@media (min-width: 46.875em) {
  .block.single-pub.top-bar .address {
    max-width: 50%;
  }
}
.block.single-pub.top-bar .address .address-details {
  max-width: 100%;
  width: 100%;
}
.block.single-pub.top-bar .address .address-details p {
  color: #231f20;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  margin-bottom: 0px;
  letter-spacing: 2px;
}
.block.single-pub.top-bar .operational-times {
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (min-width: 46.875em) {
  .block.single-pub.top-bar .operational-times {
    padding-left: 25px;
  }
}
.block.single-pub.top-bar .operational-times ul {
  margin: 0;
  padding: 0;
  max-width: 100%;
  width: 100%;
  margin-bottom: 15px;
}
@media (min-width: 46.875em) {
  .block.single-pub.top-bar .operational-times ul {
    max-width: 50%;
    margin-bottom: 0px;
    flex-wrap: wrap !important;
  }
}
.block.single-pub.top-bar .operational-times ul li {
  list-style-type: none;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 12px;
  margin-bottom: 5px;
  width: 100%;
}
.block.single-pub.top-bar .operational-times ul li:first-of-type {
  font-family: "objektiv-mk1", sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  color: #231f20;
  margin-bottom: 10px;
  font-size: 16px;
}
.block.single-pub.top-bar .contact-details {
  max-width: 100%;
  width: 100%;
}
@media (min-width: 46.875em) {
  .block.single-pub.top-bar .contact-details {
    max-width: 50%;
    margin-bottom: 0px;
  }
}
.block.single-pub.top-bar .contact-list {
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
  margin: 0;
  padding: 0;
}
@media (min-width: 56.25em) {
  .block.single-pub.top-bar .contact-list {
    flex-wrap: nowrap;
    max-width: 50%;
  }
}
.block.single-pub.top-bar .contact-list li {
  list-style-type: none;
  margin-bottom: 15px;
  width: 100%;
}
@media (min-width: 56.25em) {
  .block.single-pub.top-bar .contact-list li {
    margin-right: 25px;
    margin-bottom: 0px;
    width: auto;
  }
}
.block.single-pub.top-bar .contact-list li a {
  color: #231f20;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media (min-width: 46.875em) {
  .block.single-pub.top-bar .contact-list li a {
    font-size: 14px;
  }
}
.block.single-pub.top-bar .contact-list li a.phone span {
  max-width: 20px;
}
@media (min-width: 46.875em) {
  .block.single-pub.top-bar .contact-list li a.phone span {
    max-width: 10px;
  }
}
.block.single-pub.top-bar .contact-list li a.email span {
  max-width: 20px;
}
@media (min-width: 46.875em) {
  .block.single-pub.top-bar .contact-list li a.email span {
    max-width: 13px;
  }
}
.block.single-pub.top-bar .contact-list li a.directions span {
  max-width: 15px;
}
@media (min-width: 46.875em) {
  .block.single-pub.top-bar .contact-list li a.directions span {
    max-width: 7px;
  }
}
.block.single-pub.top-bar .contact-list li a span {
  max-width: 15px;
  display: block;
  margin-right: 10px;
}
.block.single-pub.top-bar .contact-list li a span img {
  width: 100%;
}
.block.single-pub.top-bar .contact-list li a:hover {
  text-decoration: underline;
}
.block.single-pub.top-bar .contact-list li:last-of-type {
  margin-right: 0px;
}
.block.single-pub.top-bar .booking-btns {
  margin: 0;
  padding: 0;
  max-width: 100%;
  width: 100%;
  display: flex;
  justify-content: flex-start;
  align-items: center;
}
@media (min-width: 46.875em) {
  .block.single-pub.top-bar .booking-btns {
    max-width: 50%;
    padding: 0 0 0 25px;
  }
}
.block.single-pub.top-bar .booking-btns li {
  list-style-type: none;
  width: 100%;
  margin-right: 15px;
  max-width: 175px;
}
.block.single-pub.top-bar .booking-btns li a {
  max-width: 175px;
  width: 100%;
}
.block.single-pub.gallery-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  flex-wrap: wrap;
}
.block.single-pub.gallery-content .gallery {
  max-width: 100%;
  width: 100%;
  order: 2;
}
@media (min-width: 56.25em) {
  .block.single-pub.gallery-content .gallery {
    max-width: 50%;
    margin-bottom: 0px;
    order: 1;
  }
}
.block.single-pub.gallery-content .gallery .feat-img {
  width: 100%;
  display: block;
}
.block.single-pub.gallery-content .gallery ul {
  padding: 0;
  margin: 0;
}
.block.single-pub.gallery-content .gallery ul.pub-gallery {
  margin-bottom: 5px;
}
.block.single-pub.gallery-content .gallery ul button {
  position: absolute;
  top: 50%;
  width: 15px;
  height: 20px;
  font-size: 0px;
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
}
.block.single-pub.gallery-content .gallery ul button.slick-prev {
  background-image: url("../images/prev-arrow.png");
  left: 20px;
}
.block.single-pub.gallery-content .gallery ul button.slick-next {
  background-image: url("../images/next-arrow.png");
  right: 20px;
}
.block.single-pub.gallery-content .standard-content {
  max-width: 100%;
  width: 100%;
  padding: 50px;
  order: 1;
}
@media (min-width: 56.25em) {
  .block.single-pub.gallery-content .standard-content {
    max-width: 50%;
    margin-bottom: 0px;
    order: 2;
    padding: 0 90px;
  }
}
.block.single-pub.gallery-content .standard-content p {
  color: #231f20;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 16px;
  line-height: 26px;
  margin-bottom: 10px;
}
.block.single-pub.gallery-content .standard-content p a {
  color: #B9975B;
}
.block.single-pub.gallery-content .standard-content p:last-of-type {
  margin-bottom: none;
}
.block.single-pub.gallery-content.standard-gallery {
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
  padding-bottom: 50px;
}
.block.single-pub.gallery-content.standard-gallery .gallery {
  max-width: 100%;
  width: 100%;
  order: 1;
}
@media (min-width: 56.25em) {
  .block.single-pub.gallery-content.standard-gallery .gallery {
    max-width: 100%;
    margin-bottom: 0px;
  }
}
.block.single-pub.category-list {
  padding: 50px 15px;
  text-align: center;
}
@media (min-width: 56.25em) {
  .block.single-pub.category-list {
    padding: 90px;
  }
}
.block.single-pub.category-list .menus {
  max-width: 100%;
  width: 100%;
  margin-top: 25px;
}
.block.single-pub.category-list .menus h3 {
  color: #231f20;
  font-size: 18px;
  margin-bottom: 20px;
  text-align: center;
}
.block.single-pub.category-list .menus ul {
  display: flex;
  justify-content: center;
  padding: 0;
  margin: 0;
  flex-wrap: wrap;
}
@media (min-width: 56.25em) {
  .block.single-pub.category-list .menus ul {
    flex-wrap: nowrap;
  }
}
.block.single-pub.category-list .menus ul li {
  list-style-type: none;
  max-width: 100%;
  width: auto;
  margin: 0 15px;
}
@media (min-width: 46.875em) {
  .block.single-pub.category-list .menus ul li {
    max-width: 25%;
    width: 100%;
  }
}
@media (min-width: 56.25em) {
  .block.single-pub.category-list .menus ul li {
    max-width: 25%;
    width: auto;
  }
}
.block.single-pub.category-list .menus ul li .button {
  border-radius: 5px;
  padding: 3px 20px 0;
  margin: 0 auto 15px;
  max-width: none;
}
@media (min-width: 46.875em) {
  .block.single-pub.category-list .menus ul li .button {
    max-width: 200px;
  }
}
@media (min-width: 56.25em) {
  .block.single-pub.category-list .menus ul li .button {
    margin: 0 0 15px 0;
  }
}
.block.single-pub.category-list h3 {
  color: #231f20;
  font-size: 24px;
  margin-bottom: 20px;
}
.block.single-pub.category-list ul {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media (min-width: 56.25em) {
  .block.single-pub.category-list ul {
    flex-wrap: nowrap;
  }
}
.block.single-pub.category-list ul li {
  list-style-type: none;
  min-width: 110px;
  margin-bottom: 25px;
}
@media (min-width: 56.25em) {
  .block.single-pub.category-list ul li {
    margin-bottom: 0px;
  }
}
.block.single-pub.category-list ul li .category {
  font-family: "objektiv-mk1", sans-serif;
  font-size: 14px;
  color: #231f20;
  text-align: center;
  width: 100%;
  margin-bottom: 15px;
}
.block.single-pub.category-list ul li .icon {
  text-align: center;
  height: 30px;
  width: 100%;
  background-repeat: no-repeat;
  background-position: center center;
}
@media (min-width: 46.875em) {
  .block.single-pub.category-list ul li .icon {
    border-right: 2px solid #f9f5eb;
  }
}
.block.single-pub.category-list ul li .icon.accommodation {
  background-image: url("../images/Pubs_Icons-Accommodation.svg");
}
.block.single-pub.category-list ul li .icon.bar-food {
  background-image: url("../images/Pubs_Icons-Bar_Food.svg");
}
.block.single-pub.category-list ul li .icon.dog-friendly {
  background-image: url("../images/Pubs_Icons-Dog_Friendly.svg");
}
.block.single-pub.category-list ul li .icon.family-friendly {
  background-image: url("../images/Pubs_Icons-Family.svg");
}
.block.single-pub.category-list ul li .icon.kids-play-area {
  background-image: url("../images/Pubs_Icons-Play_Area.svg");
}
.block.single-pub.category-list ul li .icon.live-music {
  background-image: url("../images/Pubs_Icons-Music.svg");
}
.block.single-pub.category-list ul li .icon.live-sport {
  background-image: url("../images/Pubs_Icons-Sport.svg");
}
.block.single-pub.category-list ul li .icon.loyalty {
  background-image: url("../images/Pubs_Icons-_Loyalty.svg");
}
.block.single-pub.category-list ul li .icon.managed {
  background-image: url("../images/Pubs_Icons-Managed.svg");
}
.block.single-pub.category-list ul li .icon.near-me {
  background-image: url("../images/Pubs_Icons-Near_me.svg");
}
.block.single-pub.category-list ul li .icon.outside-seating {
  background-image: url("../images/Pubs_Icons-Outside_Seating.svg");
}
.block.single-pub.category-list ul li .icon.parking {
  background-image: url("../images/Pubs_Icons-Parking.svg");
}
.block.single-pub.category-list ul li .icon.restaurant {
  background-image: url("../images/Pubs_Icons-Restaurant.svg");
}
.block.single-pub.category-list ul li .icon.tenanted {
  background-image: url("../images/Pubs_Icons-Tenanted.svg");
}
.block.single-pub.category-list ul li .icon.wifi {
  background-image: url("../images/Pubs_Icons-Wifi.svg");
}
.block.single-pub.category-list ul li:last-of-type .icon {
  border-right: none;
}
.block.single-pub.details {
  padding: 50px;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (min-width: 56.25em) {
  .block.single-pub.details {
    padding: 90px;
  }
  .block.single-pub.details.inline {
    align-items: center;
  }
}
.block.single-pub.details .map {
  width: 100%;
  margin-top: 25px;
}
.block.single-pub.details .map.inline {
  max-width: 50%;
  margin-top: 0px;
}
.block.shop-front-categories {
  padding: 0 50px 50px 50px;
}
@media (min-width: 46.875em) {
  .block.shop-front-categories {
    padding: 0 90px 50px 90px;
  }
}
.block.shop-front-categories ul {
  padding: 0;
  margin: 0;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.block.shop-front-categories ul li {
  max-width: 100%;
  width: 100%;
  list-style-type: none;
  text-align: center;
  padding: 25px;
  margin-bottom: 25px;
}
@media (min-width: 46.875em) {
  .block.shop-front-categories ul li {
    max-width: 48%;
  }
}
.block.shop-front-categories ul li.bg-en {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: right center;
}
.block.shop-front-categories ul li a {
  text-decoration: none;
  color: #231f20;
}
.block.shop-front-categories ul li a h3 {
  font-size: 26px;
  margin-bottom: 15px;
}
.block.shop-front-categories ul li a p {
  font-family: "objektiv-mk1", sans-serif;
  margin-bottom: 15px;
  min-height: 65px;
}
.block.shop-front-categories ul li a button {
  background-color: #B9975B;
  color: #FFF;
  text-transform: uppercase;
  border: 0;
  display: block;
  margin: 0 auto 25px;
  border-radius: 5px;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 14px;
  max-width: 140px;
  width: 100%;
  line-height: 2px;
  padding-bottom: 5px;
  height: 35px;
}
.block.shop-front-categories ul li .cat-thumb {
  max-height: 450px;
}
.block.shop-front-categories ul li:nth-child(odd):last-of-type {
  max-width: 100%;
  margin-bottom: 0px;
}
.block.shop-front-categories ul li:nth-child(odd):last-of-type a p {
  min-height: auto;
}
.block.woocommerce-wrap {
  padding: 15px;
}
@media (min-width: 64.125em) {
  .block.woocommerce-wrap {
    padding: 60px 90px;
  }
}
.single-product .block.woocommerce-wrap {
  padding: 20px;
}
.block.woocommerce-wrap .woocommerce-breadcrumb {
  color: #231f20;
  font-family: "objektiv-mk1", sans-serif;
}
.block.woocommerce-wrap .woocommerce-breadcrumb a {
  color: #231f20;
  font-family: "objektiv-mk1", sans-serif;
}
.block.woocommerce-wrap .woocommerce-products-header {
  margin-bottom: 25px;
}
.block.woocommerce-wrap .woocommerce-products-header h1 {
  text-align: center;
  color: #231f20;
}
.block.woocommerce-wrap .woocommerce-products-header .term-description {
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
  text-align: center;
  font-size: 16px;
  padding: 0 25px;
}
.block.woocommerce-wrap .woocommerce-products-header .term-description p {
  line-height: 26px;
}
.block.woocommerce-wrap .woocommerce-message,
.block.woocommerce-wrap .woocommerce-info,
.block.woocommerce-wrap .woocommerce-error {
  border-top: 3px solid #B9975B;
  background-color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
  font-size: 20px;
  padding: 15px 15px 15px 60px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  text-align: center;
}
@media (min-width: 46.875em) {
  .block.woocommerce-wrap .woocommerce-message,
.block.woocommerce-wrap .woocommerce-info,
.block.woocommerce-wrap .woocommerce-error {
    display: block;
    padding: 1em 2em 1em 3.5em;
  }
}
.block.woocommerce-wrap .woocommerce-message a,
.block.woocommerce-wrap .woocommerce-info a,
.block.woocommerce-wrap .woocommerce-error a {
  background-color: #B9975B !important;
  margin-bottom: 15px;
}
@media (min-width: 46.875em) {
  .block.woocommerce-wrap .woocommerce-message a,
.block.woocommerce-wrap .woocommerce-info a,
.block.woocommerce-wrap .woocommerce-error a {
    margin-bottom: 0px;
  }
}
.block.woocommerce-wrap .woocommerce-message a.restore-item, .block.woocommerce-wrap .woocommerce-message a.showcoupon,
.block.woocommerce-wrap .woocommerce-info a.restore-item,
.block.woocommerce-wrap .woocommerce-info a.showcoupon,
.block.woocommerce-wrap .woocommerce-error a.restore-item,
.block.woocommerce-wrap .woocommerce-error a.showcoupon {
  background-color: transparent !important;
  color: #B9975B !important;
}
.block.woocommerce-wrap .woocommerce-message::before,
.block.woocommerce-wrap .woocommerce-info::before,
.block.woocommerce-wrap .woocommerce-error::before {
  color: #B9975B;
  font-size: 24px;
  top: 14px;
  left: 15px;
}
@media (min-width: 46.875em) {
  .block.woocommerce-wrap .woocommerce-message::before,
.block.woocommerce-wrap .woocommerce-info::before,
.block.woocommerce-wrap .woocommerce-error::before {
    top: 18px;
    left: 1.5em;
  }
}
.block.woocommerce-wrap p.return-to-shop a {
  background-color: #B9975B !important;
  max-width: 200px !important;
  width: 100% !important;
}
.block.woocommerce-wrap .woocommerce-ordering {
  display: none;
}
.block.woocommerce-wrap .related.products {
  width: 100%;
}
.block.woocommerce-wrap .related.products h2, .block.woocommerce-wrap .related.products h3 {
  margin-bottom: 20px;
  text-align: center;
}
.block.woocommerce-wrap .related.products h3 {
  font-size: 28px;
}
.block.woocommerce-wrap ul.products {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
.block.woocommerce-wrap ul.products li {
  float: none !important;
  margin: 0 0 25px 0 !important;
  width: 100% !important;
  max-width: 100% !important;
  text-align: center;
  position: relative;
}
@media (min-width: 46.875em) {
  .block.woocommerce-wrap ul.products li {
    max-width: 50% !important;
  }
}
@media (min-width: 56.25em) {
  .block.woocommerce-wrap ul.products li {
    max-width: 25% !important;
  }
}
.block.woocommerce-wrap ul.products li a {
  width: 100%;
}
.block.woocommerce-wrap ul.products li a img {
  display: inline !important;
}
.block.woocommerce-wrap ul.products li .loopproduct-image-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.block.woocommerce-wrap ul.products li .awards-list {
  margin: 0;
  padding: 0;
  position: absolute;
  right: 13px;
  top: 10px;
  max-width: 50px;
  width: 100%;
}
.block.woocommerce-wrap ul.products li .awards-list.awards-list-top {
  top: 80px;
}
.block.woocommerce-wrap ul.products li .awards-list li {
  margin: 0 !important;
  max-width: 50px !important;
}
.block.woocommerce-wrap ul.products li .awards-list li img {
  max-width: 100% !important;
  margin: 0;
}
.block.woocommerce-wrap ul.products li .onsale {
  left: auto !important;
  right: 12px !important;
  background-color: transparent !important;
  background-image: url("../images/sale-banner.png");
  background-repeat: no-repeat;
  font-size: 0;
  width: 50px;
  height: 70px;
  border-radius: 0px !important;
}
.block.woocommerce-wrap ul.products li .new_product {
  left: auto !important;
  right: 13px !important;
  background-color: transparent !important;
  background-image: url("../images/Butcombe_New_Icon_Retina.png");
  background-repeat: no-repeat;
  background-size: 50px;
  font-size: 0;
  width: 50px;
  height: 70px;
  border-radius: 0px !important;
  margin: -0.5em -0.5em 0 0;
  top: 0;
  min-height: 3.236em;
  min-width: 3.236em;
  padding: 0.202em;
  font-weight: 700;
  position: absolute;
  text-align: center;
  line-height: 3.236;
  z-index: 9;
}
.block.woocommerce-wrap ul.products li form.wired_add_to_cart {
  display: flex;
  flex-flow: row wrap;
  align-items: center;
  margin: 0 auto;
}
.block.woocommerce-wrap ul.products li form input.wired_wo_input {
  width: 50px;
  margin: 0 0 0 5px;
  height: 36px;
  border: 1px solid #B9975B !important;
  border-radius: 5px;
  padding: 0 0 4px 12px;
  text-align: center;
  font-family: "objektiv-mk1", sans-serif;
}
.block.woocommerce-wrap ul.products li form button.wired_wo_button {
  background-color: #B9975B !important;
  width: 140px;
  height: 35px;
  margin: 0 auto;
  text-align: center;
  display: block !important;
  color: #FFF !important;
  border: 1px solid #B9975B !important;
  font-family: "objektiv-mk1", sans-serif;
  text-transform: uppercase;
  line-height: 1 !important;
  text-decoration: none !important;
  font-size: 14px !important;
  padding-top: 7px !important;
}
.block.woocommerce-wrap ul.products li form button.wired_wo_button:focus {
  border: 1px solid #B9975B !important;
}
.block.woocommerce-wrap ul.products li form button.wired_wo_button:active {
  border: 1px solid #B9975B !important;
}
.block.woocommerce-wrap ul.products li a h2, .block.woocommerce-wrap ul.products li a h3 {
  color: #231f20;
}
.block.woocommerce-wrap ul.products li a p.wired_bottle_unit {
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
  font-size: 14px;
  margin-bottom: 10px;
  font-weight: normal;
}
.block.woocommerce-wrap ul.products li a .price {
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
}
.block.woocommerce-wrap ul.products li a.button {
  background-color: #B9975B !important;
  margin: 0 auto;
  height: auto;
}
.block.woocommerce-wrap .product {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.block.woocommerce-wrap .product .woocommerce-product-gallery {
  max-width: 100%;
  margin-top: 25px;
}
@media (min-width: 56.25em) {
  .block.woocommerce-wrap .product .woocommerce-product-gallery {
    max-width: 48%;
    text-align: center;
  }
}
.block.woocommerce-wrap .product .woocommerce-product-gallery .woocommerce-product-gallery__wrapper {
  text-align: center;
}
.block.woocommerce-wrap .product .woocommerce-product-gallery .woocommerce-product-gallery__wrapper img.wp-post-image {
  max-width: 50%;
  display: inline !important;
}
.block.woocommerce-wrap .product .woocommerce-product-gallery .awards-list {
  margin: 0;
  padding: 0;
  position: absolute;
  left: 0px;
  top: 60px;
  max-width: 70px;
  width: 100%;
  list-style: none;
}
.block.woocommerce-wrap .product .woocommerce-product-gallery .awards-list li {
  max-width: 100% !important;
  margin-bottom: 5px !important;
}
@media (min-width: 56.25em) {
  .block.woocommerce-wrap .product .woocommerce-product-gallery .awards-list li {
    display: block;
  }
}
.block.woocommerce-wrap .product .woocommerce-product-gallery .awards-list li img {
  max-width: 100% !important;
}
.block.woocommerce-wrap .product .onsale {
  left: 0px !important;
  right: auto !important;
  background-color: transparent !important;
  background-image: url("../images/sale-banner.png");
  background-repeat: no-repeat;
  font-size: 0;
  width: 50px;
  height: 70px;
  border-radius: 0px !important;
}
.block.woocommerce-wrap .product .summary {
  max-width: 100%;
  margin-bottom: 0;
}
@media (min-width: 56.25em) {
  .block.woocommerce-wrap .product .summary {
    max-width: 48%;
  }
}
.block.woocommerce-wrap .product .summary h1 {
  color: #231f20;
}
.block.woocommerce-wrap .product .summary h2, .block.woocommerce-wrap .product .summary h3 {
  color: #231f20;
}
.block.woocommerce-wrap .product .summary .abv-list li {
  margin-bottom: 5px;
  color: #B9975B;
}
.block.woocommerce-wrap .product .summary .abv-list li span {
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
  font-size: 16px;
}
.block.woocommerce-wrap .product .summary .abv-list li:last-of-type {
  margin-bottom: 0px;
}
.block.woocommerce-wrap .product .summary .woocommerce-product-details__short-description {
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
  font-size: 16px;
  line-height: 22px;
  margin-bottom: 20px;
}
.block.woocommerce-wrap .product .summary .food-pairing {
  margin-bottom: 20px;
}
.block.woocommerce-wrap .product .summary .food-pairing h3 {
  color: #231f20;
  margin-bottom: 10px;
}
.block.woocommerce-wrap .product .summary .food-pairing p {
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
  font-size: 16px;
  line-height: 22px;
}
.block.woocommerce-wrap .product .summary .out-of-stock {
  font-family: "objektiv-mk1", sans-serif;
}
.block.woocommerce-wrap .product .summary form {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
}
.block.woocommerce-wrap .product .summary form label {
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
  font-weight: normal !important;
  width: 90px;
  margin-bottom: 10px;
  margin-right: 30px;
  display: inline-block;
}
@media (min-width: 46.875em) {
  .block.woocommerce-wrap .product .summary form label {
    margin-bottom: 0px;
  }
}
.block.woocommerce-wrap .product .summary form .qty {
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
  margin-right: 15px;
}
.block.woocommerce-wrap .product .summary form span.price,
.block.woocommerce-wrap .product .summary form p.price {
  color: #231f20;
  font-family: "objektiv-mk1", sans-serif;
  width: 100%;
}
.block.woocommerce-wrap .product .summary form button {
  background-color: #B9975B;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  text-transform: uppercase;
  border-radius: 5px;
  margin-top: 15px;
  padding-top: 5px;
}
.block.woocommerce-wrap .product .summary form button:hover {
  background-color: #B9975B;
  color: #FFF;
}
.block.woocommerce-wrap .product .related-products {
  max-width: 100%;
}
.block.woocommerce-wrap .woocommerce-cart-form table {
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
  border: none;
}
.block.woocommerce-wrap .woocommerce-cart-form table thead tr th {
  font-weight: normal;
}
.block.woocommerce-wrap .woocommerce-cart-form table tbody tr.cart_item td a {
  color: #B9975B;
  text-decoration: none;
}
.block.woocommerce-wrap .woocommerce-cart-form table tbody tr.cart_item td a:hover {
  text-decoration: underline;
}
.block.woocommerce-wrap .woocommerce-cart-form table tbody tr td.actions {
  padding: 15px;
}
@media (min-width: 56.25em) {
  .block.woocommerce-wrap .woocommerce-cart-form table tbody tr td.actions {
    padding: 20px 0;
  }
}
.block.woocommerce-wrap .woocommerce-cart-form table tbody tr td.actions .coupon input {
  width: 100%;
  margin-bottom: 10px;
  border-bottom: 2px solid #231f20;
  border-top: 0;
  border-left: 0;
  border-right: 0;
  background-color: transparent;
  color: #231f20;
}
.block.woocommerce-wrap .woocommerce-cart-form table tbody tr td.actions .coupon input::placeholder {
  color: #231f20;
  opacity: 1;
}
@media (min-width: 46.875em) {
  .block.woocommerce-wrap .woocommerce-cart-form table tbody tr td.actions .coupon input {
    max-width: 200px;
    margin-right: 15px;
  }
}
.block.woocommerce-wrap .woocommerce-cart-form table tbody tr td.actions .coupon button {
  background-color: #B9975B;
  color: #FFF;
  font-weight: normal;
  border-radius: 5px;
  height: 35px;
  padding-top: 9px;
  text-transform: uppercase;
  font-size: 14px;
  width: 100%;
}
.block.woocommerce-wrap .woocommerce-cart-form table tbody tr td.actions button {
  background-color: #B9975B;
  color: #FFF;
  font-weight: normal;
  border-radius: 5px;
  height: 35px;
  padding-top: 9px;
  text-transform: uppercase;
  font-size: 14px;
}
.block.woocommerce-wrap .cart-collaterals .cart_totals h2 {
  color: #231f20;
  margin-bottom: 25px;
}
.block.woocommerce-wrap .cart-collaterals .cart_totals .woocommerce-shipping-destination {
  display: none !important;
}
.block.woocommerce-wrap .cart-collaterals .cart_totals table {
  border: none;
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
}
.block.woocommerce-wrap .cart-collaterals .cart_totals table tbody tr th {
  padding: 10px 0;
  font-weight: normal;
}
.block.woocommerce-wrap .cart-collaterals .cart_totals table tbody tr td .shipping-calculator-button {
  color: #B9975B;
}
.block.woocommerce-wrap .cart-collaterals .cart_totals table tbody tr td .shipping-calculator-button::after {
  color: #B9975B;
}
.block.woocommerce-wrap .cart-collaterals .cart_totals .wc-proceed-to-checkout a {
  background-color: #B9975B !important;
  max-width: 100% !important;
  font-size: 18px !important;
  padding: 10px !important;
  width: 100%;
  height: auto;
}
@media (min-width: 46.875em) {
  .block.woocommerce-wrap .cart-collaterals .cart_totals .wc-proceed-to-checkout a {
    max-width: 300px !important;
  }
}
.block.woocommerce-wrap .woocommerce-checkout .col2-set {
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  margin-bottom: 15px;
}
.block.woocommerce-wrap .woocommerce-checkout .col2-set .col-1,
.block.woocommerce-wrap .woocommerce-checkout .col2-set .col-2 {
  max-width: 100%;
}
@media (min-width: 56.25em) {
  .block.woocommerce-wrap .woocommerce-checkout .col2-set .col-1,
.block.woocommerce-wrap .woocommerce-checkout .col2-set .col-2 {
    max-width: 48%;
    padding: 0 25px;
  }
}
.block.woocommerce-wrap .woocommerce-checkout .col2-set .col-1 h3,
.block.woocommerce-wrap .woocommerce-checkout .col2-set .col-2 h3 {
  margin-bottom: 25px;
}
.block.woocommerce-wrap .woocommerce-checkout .col2-set .col-1 p,
.block.woocommerce-wrap .woocommerce-checkout .col2-set .col-2 p {
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
}
.block.woocommerce-wrap .woocommerce-checkout .col2-set .col-1 p label,
.block.woocommerce-wrap .woocommerce-checkout .col2-set .col-2 p label {
  display: none;
}
.block.woocommerce-wrap .woocommerce-checkout .col2-set .col-1 p input,
.block.woocommerce-wrap .woocommerce-checkout .col2-set .col-2 p input {
  padding: 10px 10px 10px 0;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  border-bottom: 2px solid #231f20;
  font-size: 14px;
}
.block.woocommerce-wrap .woocommerce-checkout .col2-set .col-1 p textarea,
.block.woocommerce-wrap .woocommerce-checkout .col2-set .col-2 p textarea {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid #231f20;
  background-color: transparent;
  font-size: 14px;
  height: 90px;
}
.block.woocommerce-wrap .woocommerce-checkout .col2-set .col-1 p#billing_country_field label, .block.woocommerce-wrap .woocommerce-checkout .col2-set .col-1 p#shipping_country_field label, .block.woocommerce-wrap .woocommerce-checkout .col2-set .col-1 p#kl_newsletter_checkbox_field label,
.block.woocommerce-wrap .woocommerce-checkout .col2-set .col-2 p#billing_country_field label,
.block.woocommerce-wrap .woocommerce-checkout .col2-set .col-2 p#shipping_country_field label,
.block.woocommerce-wrap .woocommerce-checkout .col2-set .col-2 p#kl_newsletter_checkbox_field label {
  display: block;
  font-size: 14px;
}
.block.woocommerce-wrap .woocommerce-checkout #order_review_heading {
  margin-bottom: 25px;
}
@media (min-width: 56.25em) {
  .block.woocommerce-wrap .woocommerce-checkout #order_review_heading {
    padding: 0 25px;
  }
}
@media (min-width: 56.25em) {
  .block.woocommerce-wrap .woocommerce-checkout .woocommerce-checkout-review-order {
    padding: 0 25px;
  }
}
.block.woocommerce-wrap .woocommerce-checkout .woocommerce-checkout-review-order table {
  border: 0;
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
}
.block.woocommerce-wrap .woocommerce-checkout .woocommerce-checkout-review-order table thead,
.block.woocommerce-wrap .woocommerce-checkout .woocommerce-checkout-review-order table th {
  font-family: "objektiv-mk1", sans-serif;
}
.block.woocommerce-wrap .woocommerce-checkout .woocommerce-checkout-review-order table .woocommerce-shipping-methods label {
  font-family: "objektiv-mk1", sans-serif;
}
.block.woocommerce-wrap .woocommerce-checkout .woocommerce-checkout-review-order table .woocommerce-shipping-methods small {
  font-size: 14px;
  font-weight: 600;
  color: red;
}
.block.woocommerce-wrap .woocommerce-checkout .woocommerce-checkout-review-order table .woocommerce-shipping-methods .woocommerce-shipping-contents small {
  color: #231f20;
}
.block.woocommerce-wrap .woocommerce-checkout .woocommerce-checkout-review-order .woocommerce-checkout-payment {
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
  background-color: #f9f5eb !important;
}
.block.woocommerce-wrap .woocommerce-checkout .woocommerce-checkout-review-order .woocommerce-checkout-payment a {
  color: #B9975B;
}
.block.woocommerce-wrap .woocommerce-checkout .woocommerce-checkout-review-order .woocommerce-checkout-payment .optional {
  display: none;
}
.block.woocommerce-wrap .woocommerce-checkout .woocommerce-checkout-review-order .woocommerce-checkout-payment button {
  background-color: #B9975B !important;
  border-radius: 5px;
  font-size: 20px;
  font-weight: 700;
  text-transform: uppercase;
  font-family: "objektiv-mk1", sans-serif;
  padding-top: 5px;
  margin-top: 25px;
  max-width: 280px;
  width: 100%;
  height: 60px;
  float: left !important;
  margin-bottom: 20px;
}
.block.woocommerce-wrap .woocommerce-checkout .woocommerce-checkout-review-order .woocommerce-checkout-payment button.wired_checkout_button {
  float: none !important;
}
.block.woocommerce-wrap .woocommerce-checkout .woocommerce-checkout-review-order .woocommerce-checkout-payment .payment_method_stripe #stripe-payment-data .form-row .InputElement::placeholder {
  opacity: 1;
  color: #444;
}
.block.woocommerce-wrap .woocommerce-checkout .woocommerce-checkout-review-order .woocommerce-checkout-payment .payment_method_stripe #stripe-payment-data fieldset {
  border: 0;
}
.block.woocommerce-wrap .woocommerce-checkout .wc_payment_method label {
  cursor: default;
  font-size: 20px;
}
.block.woocommerce-wrap .woocommerce-order {
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
}
.block.woocommerce-wrap .woocommerce-order a {
  color: #B9975B;
  text-decoration: none;
}
.block.woocommerce-wrap .woocommerce-order a:hover {
  text-decoration: underline;
}
.block.woocommerce-wrap .woocommerce-order ul.order_details {
  margin: 25px 0;
  padding: 0;
}
.block.woocommerce-wrap .woocommerce-order table.order_details {
  border: 0;
  margin-top: 25px;
}
.block.woocommerce-wrap .woocommerce-order section.addresses h2 {
  margin-bottom: 25px;
}
.block.woocommerce-wrap .woocommerce-order section.addresses h2:last-of-type {
  margin-top: 15px;
}
.block.woocommerce-wrap .woocommerce-order section.addresses address {
  border: 0;
  padding: 0;
}
.block .images-row {
  display: flex;
  justify-content: center;
  gap: 30px;
  gap: 1.33%;
  padding: 0px 30px;
}
@media (min-width: 90em) {
  .block .images-row {
    padding: 0;
  }
}
.block .images-row img {
  max-width: 100%;
  object-fit: cover;
}
.block .images-row-slider {
  padding: 0px 30px;
}
@media (min-width: 90em) {
  .block .images-row-slider {
    padding: 0;
  }
}
.block .images-row-slider img {
  max-width: 100%;
  height: 255px;
  object-fit: cover;
}

.flexible-content-wrap .block {
  padding-bottom: 0px;
  padding-top: 0px;
  margin-bottom: 40px;
}
.flexible-content-wrap .block:last-of-type.bg-ctas {
  margin-bottom: 0px;
  padding-bottom: 0px;
}
.flexible-content-wrap .block.products-list {
  padding: 50px 90px;
}
.flexible-content-wrap .block.products-list h2 {
  text-align: center;
}
.flexible-content-wrap .block.products-list .feat-products-list {
  padding: 0;
  margin: 0;
  display: flex;
  justify-content: space-between;
}
.flexible-content-wrap .block.products-list .feat-products-list li {
  list-style-type: none;
  text-align: center;
}
.flexible-content-wrap .block.products-list .feat-products-list li img {
  max-height: 300px;
  display: inline;
  margin-bottom: 10px;
}
.flexible-content-wrap .block.products-list .feat-products-list li a {
  margin-top: 15px;
}
.flexible-content-wrap .block.products-list .feat-products-list button {
  position: absolute;
  top: 49%;
  width: 15px;
  height: 20px;
  font-size: 0px;
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
}
@media (min-width: 46.875em) {
  .flexible-content-wrap .block.products-list .feat-products-list button {
    display: block !important;
  }
}
.flexible-content-wrap .block.products-list .feat-products-list button.slick-prev {
  background-image: url("../images/prev-arrow-grey.png");
  left: 0px;
}
@media (min-width: 56.25em) {
  .flexible-content-wrap .block.products-list .feat-products-list button.slick-prev {
    left: 0px;
  }
}
.flexible-content-wrap .block.products-list .feat-products-list button.slick-next {
  background-image: url("../images/next-arrow-grey.png");
  right: 0px;
}
@media (min-width: 56.25em) {
  .flexible-content-wrap .block.products-list .feat-products-list button.slick-next {
    right: 0px;
  }
}
.flexible-content-wrap .block.full_width_image_block h3 {
  text-align: center;
  margin: 20px 0;
}
.flexible-content-wrap .block.full_width_image_block h3 a {
  color: black;
  text-decoration: none;
}
.flexible-content-wrap .block.full_width_image_block img {
  width: 100%;
  object-fit: contain;
}

.woocommerce span.onsale {
  background-color: red !important;
}

/* The Modal (background) */
.wired-modal {
  display: none;
  position: fixed;
  z-index: 1;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background-color: black;
  background-color: rgba(0, 0, 0, 0.4);
}
.wired-modal .wired-modal-content {
  background-color: #fefefe;
  margin: 15% auto;
  padding: 20px;
  border: 1px solid #888;
  width: 100%;
  max-width: 320px;
}
.wired-modal .wired-modal-content .wired-close {
  color: #aaa;
  float: right;
  font-size: 28px;
  font-weight: bold;
}
.wired-modal .wired-modal-content .wired-close:hover, .wired-modal .wired-modal-content .wired-close:focus {
  color: black;
  text-decoration: none;
  cursor: pointer;
}

.map {
  height: 500px;
}

.property-dropdown-wrap {
  width: 100%;
  max-width: 100%;
  margin-top: 50px;
  margin-bottom: 50px;
}
.property-dropdown-wrap h3 {
  text-align: center;
  margin-bottom: 20px;
}
.property-dropdown-wrap .inner {
  padding: 0px 90px 25px 90px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}
@media (min-width: 56.25em) {
  .property-dropdown-wrap .inner {
    flex-wrap: nowrap;
  }
}
.property-dropdown-wrap .inner select {
  font-family: "objektiv-mk1", sans-serif;
  padding: 0px 5px 5px 5px;
  border-radius: 5px;
  margin-bottom: 25px;
  font-size: 16px;
}
@media (min-width: 56.25em) {
  .property-dropdown-wrap .inner select {
    margin-right: 50px;
    margin-bottom: 0px;
  }
}
.property-dropdown-wrap .inner .dropdown-block {
  font-family: "objektiv-mk1", sans-serif;
  padding: 2px 5px 5px 8px;
  border-radius: 5px;
  margin: 0 0 25px;
  font-size: 16px;
  min-width: 175px;
  border: 1px solid #231f20;
  background-color: #FFF;
  background-image: url("../images/down-arrow.svg");
  background-repeat: no-repeat;
  background-position: 95% 13px;
  background-size: 10px;
  color: #231f20;
  text-transform: uppercase;
  text-align: left;
  position: relative;
  cursor: pointer;
}
@media (min-width: 56.25em) {
  .property-dropdown-wrap .inner .dropdown-block {
    margin: 0 20px;
  }
}
.property-dropdown-wrap .inner .dropdown-block .dropdown-title {
  width: 100%;
  padding-right: 20px;
}
.property-dropdown-wrap .inner .dropdown-block .dropdown-options {
  display: none;
}
.property-dropdown-wrap .inner .dropdown-block .dropdown-options.active {
  display: block;
  position: absolute;
  top: 28px;
  border-left: 1px solid #231f20;
  border-right: 1px solid #231f20;
  border-bottom: 1px solid #231f20;
  border-radius: 0 0 5px 5px;
  left: -1px;
  padding: 0;
  background-color: #FFF;
  box-sizing: content-box;
  width: 100%;
}
.property-dropdown-wrap .inner .dropdown-block .dropdown-options.active span {
  display: block;
  width: 100%;
  cursor: pointer;
  padding: 0 5px 5px 8px;
}
.property-dropdown-wrap .inner .dropdown-block .dropdown-options.active span a {
  color: #231f20;
  text-decoration: none;
  cursor: pointer;
}

.postid-2675 .qty {
  max-width: 150px;
  margin-right: 59px !important;
  display: inline-block;
}
.postid-2675 .quantity {
  float: none !important;
  display: inline-block;
}

.wcpa_form_outer label {
  display: none !important;
}
.wcpa_form_outer .form-control::placeholder {
  font-family: "objektiv-mk1", sans-serif;
}

.popfwc-currency-symbol {
  font-family: "objektiv-mk1", sans-serif;
}

.alg_open_price::placeholder {
  font-family: "objektiv-mk1", sans-serif;
}

.map-wrapper {
  width: 100%;
  height: 500px;
  max-width: 1260px;
  margin: 40px auto;
}
.map-wrapper #map {
  height: 500px;
}

.filter-buttons {
  width: 100%;
  padding: 25px;
  max-width: 1260px;
  margin: 0 auto 40px;
}
.filter-buttons .wpsl-provided-by {
  display: none !important;
}
.filter-buttons .wpsl-search {
  background-color: #FFF;
  padding: 20px !important;
}
.filter-buttons .wpsl-search #wpsl-search-wrap .wpsl-input {
  margin-bottom: 0px !important;
  max-width: 250px;
  width: 100%;
}
.filter-buttons .wpsl-search #wpsl-search-wrap label {
  display: none;
}
.filter-buttons .wpsl-search #wpsl-search-wrap #wpsl-search-input {
  font-family: "objektiv-mk1", sans-serif;
  border-bottom: 2px solid #231f20;
  border-top: 0;
  border-right: 0;
  border-left: 0;
  border-radius: 0px;
  padding-left: 0px;
  font-size: 16px;
  margin-bottom: 0px;
  width: 100%;
}
.filter-buttons .wpsl-search .wpsl-search-btn-wrap input {
  background-color: #B9975B !important;
  font-family: "objektiv-mk1", sans-serif !important;
  text-transform: uppercase !important;
  border-radius: 0px !important;
  border: 0 !important;
  color: #FFF !important;
  background-image: none !important;
  padding: 0px 10px 7px 10px !important;
  height: 35px !important;
  line-height: 5px !important;
}
.filter-buttons #wpsl-result-list ul li {
  border-bottom: 2px solid #231f20 !important;
  font-family: "objektiv-mk1", sans-serif !important;
}
.filter-buttons #wpsl-result-list ul li p {
  color: #231f20 !important;
}
.filter-buttons #wpsl-result-list ul li p strong {
  font-family: "objektiv-mk1", sans-serif !important;
  display: block;
  margin-bottom: 5px;
}
.filter-buttons #wpsl-result-list ul li a {
  color: #B9975B !important;
  text-decoration: none !important;
  text-transform: capitalize !important;
}

.page-template-template-second-level-property .block:first-of-type,
.page-template-template-top-level-property .block:first-of-type {
  padding-top: 30px;
  padding-bottom: 20px;
}
@media (min-width: 64.125em) {
  .page-template-template-second-level-property .block:first-of-type,
.page-template-template-top-level-property .block:first-of-type {
    padding-top: 200px;
  }
}

.page-template-template-second-level-property,
.page-template-template-top-level-property,
.page-template-template-pubs-rooms {
  /* Safari */
}
.page-template-template-second-level-property .properties-main-wrap,
.page-template-template-top-level-property .properties-main-wrap,
.page-template-template-pubs-rooms .properties-main-wrap {
  padding-bottom: 0px;
}
.page-template-template-second-level-property a.button.white.button-direction,
.page-template-template-top-level-property a.button.white.button-direction,
.page-template-template-pubs-rooms a.button.white.button-direction {
  display: inline-block !important;
}
.page-template-template-second-level-property a.button.white.button-direction:first-of-type,
.page-template-template-top-level-property a.button.white.button-direction:first-of-type,
.page-template-template-pubs-rooms a.button.white.button-direction:first-of-type {
  margin-right: 10px;
  margin-bottom: 10px;
}
@-webkit-keyframes "spin" {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@keyframes "spin" {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
.page-template-template-second-level-property input#postcode-search-input,
.page-template-template-top-level-property input#postcode-search-input,
.page-template-template-pubs-rooms input#postcode-search-input {
  width: 70%;
  padding: 10px;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 16px;
}
.page-template-template-second-level-property #location-search-wrap .button,
.page-template-template-top-level-property #location-search-wrap .button,
.page-template-template-pubs-rooms #location-search-wrap .button {
  background-color: #B9975B !important;
  padding: 11px !important;
  max-width: 100%;
  height: auto;
  line-height: normal !important;
}
.page-template-template-second-level-property #location-search-wrap td,
.page-template-template-top-level-property #location-search-wrap td,
.page-template-template-pubs-rooms #location-search-wrap td {
  display: block;
  margin: 10px;
  width: 100%;
  padding: 5px;
  border-radius: 5px;
}
@media (min-width: 64.125em) {
  .page-template-template-second-level-property #location-search-wrap td,
.page-template-template-top-level-property #location-search-wrap td,
.page-template-template-pubs-rooms #location-search-wrap td {
    display: table-cell;
    width: 33%;
  }
}
.page-template-template-second-level-property div#location-search-wrap,
.page-template-template-top-level-property div#location-search-wrap,
.page-template-template-pubs-rooms div#location-search-wrap {
  width: 100%;
  margin-bottom: 30px;
}
.page-template-template-second-level-property .pub-hotel-route-map,
.page-template-template-top-level-property .pub-hotel-route-map,
.page-template-template-pubs-rooms .pub-hotel-route-map {
  position: relative;
}
.page-template-template-second-level-property .pub-hotel-route-map #map-canvas,
.page-template-template-top-level-property .pub-hotel-route-map #map-canvas,
.page-template-template-pubs-rooms .pub-hotel-route-map #map-canvas {
  height: 50vh;
  margin-bottom: 30px;
}
.page-template-template-second-level-property .pub-hotel-route-map #directions-panel-right,
.page-template-template-top-level-property .pub-hotel-route-map #directions-panel-right,
.page-template-template-pubs-rooms .pub-hotel-route-map #directions-panel-right {
  width: 100%;
  height: 100%;
  max-width: 100%;
  display: none;
  overflow: auto;
  max-height: 50vh;
  position: inherit;
  margin-bottom: 30px;
  background: #FFF;
}
@media (min-width: 56.25em) {
  .page-template-template-second-level-property .pub-hotel-route-map #directions-panel-right,
.page-template-template-top-level-property .pub-hotel-route-map #directions-panel-right,
.page-template-template-pubs-rooms .pub-hotel-route-map #directions-panel-right {
    position: absolute;
    top: 0;
    right: 0;
    max-width: 450px;
  }
}
.page-template-template-second-level-property .pub-hotel-route-map #directions-panel-right span.closebtn,
.page-template-template-top-level-property .pub-hotel-route-map #directions-panel-right span.closebtn,
.page-template-template-pubs-rooms .pub-hotel-route-map #directions-panel-right span.closebtn {
  font-weight: bolder;
  float: right;
  color: #231f20 !important;
  font-size: 36px;
  cursor: pointer;
  transition: 0.3s;
}
.page-template-template-second-level-property .pub-hotel-route-map #directions-panel,
.page-template-template-top-level-property .pub-hotel-route-map #directions-panel,
.page-template-template-pubs-rooms .pub-hotel-route-map #directions-panel {
  width: 100%;
  height: 100%;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 16px;
  line-height: 30px;
  padding-left: 10px;
}
.page-template-template-second-level-property .pub-hotel-route-map #directions-panel #right-panel select,
.page-template-template-second-level-property .pub-hotel-route-map #directions-panel input,
.page-template-template-top-level-property .pub-hotel-route-map #directions-panel #right-panel select,
.page-template-template-top-level-property .pub-hotel-route-map #directions-panel input,
.page-template-template-pubs-rooms .pub-hotel-route-map #directions-panel #right-panel select,
.page-template-template-pubs-rooms .pub-hotel-route-map #directions-panel input {
  font-size: 15px;
}
.page-template-template-second-level-property .pub-hotel-route-map #directions-panel select,
.page-template-template-top-level-property .pub-hotel-route-map #directions-panel select,
.page-template-template-pubs-rooms .pub-hotel-route-map #directions-panel select {
  width: 100%;
}
.page-template-template-second-level-property .pub-hotel-route-map #directions-panel i,
.page-template-template-top-level-property .pub-hotel-route-map #directions-panel i,
.page-template-template-pubs-rooms .pub-hotel-route-map #directions-panel i {
  font-size: 12px;
}
.page-template-template-second-level-property .pub-hotel-route-map #directions-panel .adp-step,
.page-template-template-second-level-property .pub-hotel-route-map #directions-panel .adp-text,
.page-template-template-top-level-property .pub-hotel-route-map #directions-panel .adp-step,
.page-template-template-top-level-property .pub-hotel-route-map #directions-panel .adp-text,
.page-template-template-pubs-rooms .pub-hotel-route-map #directions-panel .adp-step,
.page-template-template-pubs-rooms .pub-hotel-route-map #directions-panel .adp-text {
  width: auto;
}
.page-template-template-second-level-property .pub-hotel-route-map #directions-panel table.adp-placemark,
.page-template-template-top-level-property .pub-hotel-route-map #directions-panel table.adp-placemark,
.page-template-template-pubs-rooms .pub-hotel-route-map #directions-panel table.adp-placemark {
  width: 100%;
}
.page-template-template-second-level-property .map-wired-tooltip,
.page-template-template-top-level-property .map-wired-tooltip,
.page-template-template-pubs-rooms .map-wired-tooltip {
  width: 100%;
  max-width: 320px;
  text-align: left;
}
.page-template-template-second-level-property .map-wired-tooltip p,
.page-template-template-top-level-property .map-wired-tooltip p,
.page-template-template-pubs-rooms .map-wired-tooltip p {
  font-family: "objektiv-mk1", sans-serif;
  font-size: 16px;
  color: #231f20 !important;
  margin-bottom: 10px;
  text-align: left;
}
.page-template-template-second-level-property .map-wired-tooltip h3,
.page-template-template-top-level-property .map-wired-tooltip h3,
.page-template-template-pubs-rooms .map-wired-tooltip h3 {
  color: #231f20 !important;
  font-size: 18px;
  line-height: 20px;
  letter-spacing: 2px;
  margin-top: 15px;
  margin-bottom: 15px;
}
.page-template-template-second-level-property .map-wired-tooltip .thumb-wrap,
.page-template-template-top-level-property .map-wired-tooltip .thumb-wrap,
.page-template-template-pubs-rooms .map-wired-tooltip .thumb-wrap {
  width: 100%;
  overflow: hidden;
  max-height: 150px;
}
.page-template-template-second-level-property .map-wired-tooltip .thumb-wrap img,
.page-template-template-top-level-property .map-wired-tooltip .thumb-wrap img,
.page-template-template-pubs-rooms .map-wired-tooltip .thumb-wrap img {
  width: 100%;
  display: block;
  border-radius: 5px 5px 0 0;
}
.page-template-template-second-level-property #location-search-wrap .pubhotel-alert,
.page-template-template-top-level-property #location-search-wrap .pubhotel-alert,
.page-template-template-pubs-rooms #location-search-wrap .pubhotel-alert {
  padding: 20px;
  background-color: #B9975B;
  color: white;
  margin: 20px 0;
  border-radius: 5px;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 16px;
  display: none;
}
.page-template-template-second-level-property #location-search-wrap .pubhotel-alert .closebtn,
.page-template-template-top-level-property #location-search-wrap .pubhotel-alert .closebtn,
.page-template-template-pubs-rooms #location-search-wrap .pubhotel-alert .closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}
.page-template-template-second-level-property #location-search-wrap .pubhotel-searching,
.page-template-template-top-level-property #location-search-wrap .pubhotel-searching,
.page-template-template-pubs-rooms #location-search-wrap .pubhotel-searching {
  width: 100%;
  display: none;
  margin: 20px 0;
}
.page-template-template-second-level-property #location-search-wrap .pubhotel-searching .loader,
.page-template-template-top-level-property #location-search-wrap .pubhotel-searching .loader,
.page-template-template-pubs-rooms #location-search-wrap .pubhotel-searching .loader {
  border: 5px solid #B9975B !important;
  border-radius: 50%;
  border-top: 5px solid #231f20 !important;
  width: 32px;
  height: 32px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  float: left;
}
.page-template-template-second-level-property #location-search-wrap .pubhotel-searching span,
.page-template-template-top-level-property #location-search-wrap .pubhotel-searching span,
.page-template-template-pubs-rooms #location-search-wrap .pubhotel-searching span {
  line-height: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 16px;
  color: #231f20;
}
.page-template-template-second-level-property #location-search-wrap .pubhotel-searching .pubhotel-text-center,
.page-template-template-top-level-property #location-search-wrap .pubhotel-searching .pubhotel-text-center,
.page-template-template-pubs-rooms #location-search-wrap .pubhotel-searching .pubhotel-text-center {
  text-align: center;
  max-width: 320px;
  margin: 0 auto;
}

.video_block {
  display: flex;
  flex-direction: row;
  margin-bottom: 50px;
}
.video_block.alignment_left {
  justify-content: flex-start;
}
.video_block.alignment_center {
  justify-content: center;
}
.video_block.alignment_right {
  justify-content: flex-end;
}

.ginput_container.ginput_container_repeater2-end {
  text-align: right;
  font-family: "objektiv-mk1", sans-serif !important;
}
.ginput_container.ginput_container_repeater2-end .gf_repeater2_add,
.ginput_container.ginput_container_repeater2-end .gf_repeater2_remove {
  padding: 5px 20px 10px 20px !important;
  font-size: 14px !important;
}
.ginput_container.ginput_container_repeater2-end .gf_repeater2_add {
  background-color: #B9975B;
}
.ginput_container.ginput_container_repeater2-end .gf_repeater2_remove {
  background-color: #231f20;
}

form.direct-enquiries-form .gfield label {
  display: block !important;
  font-size: 14px;
  font-family: "objektiv-mk1", sans-serif;
  text-align: left;
}
form.direct-enquiries-form .gfield input {
  padding: 10px 10px 10px 0;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  border-bottom: 2px solid #231f20;
  font-size: 14px;
  font-family: "objektiv-mk1", sans-serif;
  width: 100%;
  max-width: 100%;
  margin-bottom: 10px;
  max-width: 100% !important;
  color: #231f20;
}
form.direct-enquiries-form .gfield input::placeholder {
  color: #231f20;
}
form.direct-enquiries-form .gfield textarea {
  border-top: 0;
  border-left: 0;
  border-right: 0;
  border-bottom: 2px solid #231f20;
  font-family: "objektiv-mk1", sans-serif;
  background-color: transparent;
  font-size: 14px;
  min-height: 90px;
  width: 100%;
  max-width: 100% !important;
}
form.direct-enquiries-form .gfield textarea::placeholder {
  color: #231f20;
}
form.direct-enquiries-form .gfield select {
  min-width: 0 !important;
  width: 100%;
  margin: 0px !important;
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
  padding: 5px 5px 5px 0px;
  font-size: 14px;
  border-radius: 0px;
  border-top: 0;
  border-right: 0;
  margin-bottom: 10px;
  border-left: 0;
  border-bottom: 2px solid #231f20;
  background-color: transparent;
  -moz-appearance: none;
  -webkit-appearance: none;
  appearance: none;
  background: url("../images/down-pointer.png") no-repeat 95% 50%;
  background-size: 12px;
  max-width: 100% !important;
}
form.direct-enquiries-form .gform_footer .gform_button.button {
  border-radius: 5px !important;
  background-color: #c3a76d !important;
  color: #FFF !important;
  font-family: "objektiv-mk1", sans-serif !important;
  padding: 10px 20px !important;
  border: 0 !important;
  min-width: 140px;
  font-size: 14px !important;
  width: auto !important;
  max-width: 100%;
}

.klaviyo-form.klaviyo-form.klaviyo-form {
  font-family: "objektiv-mk1", sans-serif !important;
}

/* BB-244 crocodile catcher page images */
body.page-id-47422 .images-row img {
  max-height: 300px;
}

/* BB-247 video block rows*/
@font-face {
  font-family: "ThemeIcons";
  src: url("../fonts/ThemeIcons.eot") format("embedded-opentype"), url("../fonts/ThemeIcons.woff2") format("woff2"), url("../fonts/ThemeIcons.woff") format("woff"), url("../fonts/ThemeIcons.ttf") format("truetype"), url("../fonts/ThemeIcons.svg") format("svg");
}
#player_bkg {
  height: 105vh !important;
}

#player_wrapper {
  max-width: calc(100vw - 1rem) !important;
  left: 0 !important;
}
@media (min-width: 56.25em) {
  #player_wrapper {
    left: calc(-500px + 50vw) !important;
  }
}
#player_wrapper iframe {
  height: 300px !important;
  width: 100vw !important;
  max-width: 1000px !important;
}
@media (min-width: 46.875em) {
  #player_wrapper iframe {
    height: 530px !important;
  }
}

.video_block_row {
  display: flex;
  gap: 20px;
  flex-direction: column;
  align-items: center;
  margin-bottom: 50px;
  flex-wrap: wrap;
}
@media (min-width: 46.875em) {
  .video_block_row {
    flex-direction: row;
  }
}
.video_block_row.alignment_center {
  justify-content: center;
}
.video_block_row.alignment_left {
  justify-content: flex-start;
}
.video_block_row.alignment_right {
  justify-content: flex-end;
}
.video_block_row .video_block_row_box {
  width: 100%;
  background-color: #FFF;
}
.video_block_row .video_block_row_box:first-of-type {
  margin-left: 0;
}
.video_block_row .video_block_row_box:last-of-type {
  margin-right: 0;
}
@media (min-width: 46.875em) {
  .video_block_row .video_block_row_box {
    flex: 0 0 48%;
  }
}
@media (min-width: 56.25em) {
  .video_block_row .video_block_row_box {
    flex: 0 0 31%;
  }
}
.video_block_row .video_block_row_box .image_wrapper {
  width: 100%;
  height: 250px;
  overflow: hidden;
  position: relative;
}
.video_block_row .video_block_row_box .image_wrapper::before {
  content: "";
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background: transparent;
  border: 4px solid #FFFFFF;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  opacity: 1;
  transition: opacity 350ms ease-in;
  z-index: 1;
}
.video_block_row .video_block_row_box .image_wrapper::after {
  content: "\f109";
  line-height: 1;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: "ThemeIcons";
  display: block;
  position: absolute;
  font-size: 25px;
  left: 50%;
  top: 50%;
  transform: translate(-40%, -50%);
  color: #fff;
  z-index: 1;
  opacity: 1;
  transition: opacity 350ms ease-in;
}
.video_block_row .video_block_row_box .image_wrapper .video_box_image {
  width: 100%;
  height: 250px;
  object-fit: cover;
  transition: transform 0.2s;
  /* Animation */
}
.video_block_row .video_block_row_box .image_wrapper .video_box_image:hover {
  transform: scale(1.1);
  cursor: pointer;
}
.video_block_row .video_block_row_box .image_wrapper:hover {
  cursor: pointer;
}
.video_block_row .video_block_row_box .image_wrapper:hover::before {
  opacity: 0.75;
}
.video_block_row .video_block_row_box .image_wrapper:hover::after {
  opacity: 0.75;
}
.video_block_row .video_block_row_box .image_wrapper:hover .video_box_image {
  transform: scale(1.1);
}
.video_block_row .video_block_row_box .contents_wrapper {
  padding: 20px;
  min-height: 200px;
  position: relative;
  text-align: left;
}
.video_block_row .video_block_row_box .contents_wrapper .video_box_title {
  margin-bottom: 10px;
}
.video_block_row .video_block_row_box .contents_wrapper .video_box_description {
  text-align: left;
}
.video_block_row .video_block_row_box .contents_wrapper .video_box_play {
  position: absolute;
  bottom: 20px;
  text-decoration: underline;
  background-color: #FFF;
  color: #231f20;
  border: 1px solid #231f20;
  text-decoration: none;
  max-width: 140px;
  width: 100%;
  height: 35px;
  text-align: center;
  display: block !important;
  text-transform: uppercase;
  line-height: 1 !important;
  text-decoration: none !important;
  font-size: 14px !important;
  padding-top: 7px !important;
  font-family: "objektiv-mk1", sans-serif;
  margin-top: 10px;
}
.video_block_row .video_block_row_box .contents_wrapper .video_box_play:hover {
  cursor: pointer;
}

/* /goramipazero/ page */
body.page-id-67595 .standard-text-block p {
  max-width: 1200px;
}

.pre-foot-block {
  width: 100%;
  padding: 40px 35px;
  background-color: #231f20;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
@media (min-width: 46.875em) {
  .pre-foot-block {
    padding: 40px 90px;
  }
}
.pre-foot-block .news {
  max-width: 100%;
  width: 100%;
  margin-bottom: 40px;
}
@media (min-width: 56.25em) {
  .pre-foot-block .news {
    max-width: 47%;
    padding-right: 30px;
    margin-right: 30px;
    margin-bottom: 0px;
    border-right: 1px solid #B9975B;
  }
}
.pre-foot-block .news h3 {
  color: #c3a76d;
  font-size: 16px;
  margin-bottom: 20px;
}
.pre-foot-block .news p {
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
}
.pre-foot-block .news p a {
  color: #B9975B;
}
.pre-foot-block .news p:last-of-type {
  margin-bottom: 0px;
}
.pre-foot-block .news .latest-post {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.pre-foot-block .news .latest-post .thumbnail {
  max-width: 55%;
  width: 100%;
}
@media (min-width: 46.875em) {
  .pre-foot-block .news .latest-post .thumbnail {
    max-width: 25%;
  }
}
@media (min-width: 56.25em) {
  .pre-foot-block .news .latest-post .thumbnail {
    max-width: 55%;
  }
}
.pre-foot-block .news .latest-post .thumbnail img {
  width: 100%;
}
.pre-foot-block .news .latest-post .post-info {
  max-width: 45%;
  width: 100%;
  padding-left: 20px;
}
@media (min-width: 46.875em) {
  .pre-foot-block .news .latest-post .post-info {
    max-width: 75%;
  }
}
@media (min-width: 56.25em) {
  .pre-foot-block .news .latest-post .post-info {
    max-width: 45%;
  }
}
.pre-foot-block .news .latest-post .post-info .date {
  color: #c3a76d;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 12px;
}
.pre-foot-block .news .latest-post .post-info p {
  display: none;
}
@media (min-width: 46.875em) {
  .pre-foot-block .news .latest-post .post-info p {
    display: block;
    color: #FFF;
    font-family: "objektiv-mk1", sans-serif;
    font-size: 14px;
    margin-top: 15px;
  }
}
.pre-foot-block .news .latest-post .post-info h4 {
  color: #FFF;
  font-size: 16px;
}
.pre-foot-block .news .latest-post .links {
  max-width: 100%;
  margin-top: 25px;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
@media (min-width: 46.875em) {
  .pre-foot-block .news .latest-post .links {
    justify-content: flex-start;
  }
  .pre-foot-block .news .latest-post .links a {
    margin-right: 25px;
  }
}
.pre-foot-block .form {
  max-width: 100%;
  width: 100%;
}
@media (min-width: 56.25em) {
  .pre-foot-block .form {
    max-width: 50%;
  }
}
.pre-foot-block .form h3 {
  color: #c3a76d;
  font-size: 16px;
  margin-bottom: 20px;
}
.pre-foot-block .form form {
  width: 100%;
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 0 !important;
}
.pre-foot-block .form form .Ndwyt .kgtZbf:first-of-type .dMHKxR {
  padding: 0px !important;
}
.pre-foot-block .form form .Ndwyt .kgtZbf:nth-child(2) {
  margin-bottom: 10px !important;
}
.pre-foot-block .form form .Ndwyt .kgtZbf:nth-child(2) .FormComponent__StyledFormComponentWrapper-e0xun6-0:first-of-type {
  padding-right: 10px !important;
}
.pre-foot-block .form form .Ndwyt .kgtZbf .FormComponent__StyledFormComponentWrapper-e0xun6-0 {
  padding-left: 0px;
  padding-right: 0px;
}
.pre-foot-block .form form .form-row {
  width: 100%;
  max-width: 100%;
}
@media (min-width: 46.875em) {
  .pre-foot-block .form form .form-row {
    max-width: 49%;
  }
}
.pre-foot-block .form form input {
  max-width: 100%;
  width: 100%;
  background-color: transparent;
  border: 0;
  border-bottom: 1px solid #FFF;
  margin-bottom: 35px;
  padding-bottom: 10px;
  font-family: "objektiv-mk1", sans-serif !important;
  color: #7f7d7b !important;
  font-size: 14px;
}
.pre-foot-block .form form input.submit {
  max-width: 140px;
  width: 100%;
  height: 35px;
  text-align: center;
  display: block;
  color: #FFF;
  background-color: #B9975B;
  border-radius: 5px;
  border: 1px solid #c3a76d;
  font-family: "objektiv-mk1", sans-serif;
  text-transform: uppercase;
  line-height: 1;
  font-size: 14px;
  padding-bottom: 5px;
}
.pre-foot-block .form form input.checkbox {
  width: auto;
  padding: 0;
  margin-bottom: 0px;
}
.pre-foot-block .form form input::placeholder {
  font-family: "objektiv-mk1", sans-serif;
  color: #7f7d7b;
  font-size: 14px;
}
.pre-foot-block .form form .check-wrap {
  max-width: 100%;
  width: 100%;
  color: #FFF;
  font-size: 16px;
  font-family: "objektiv-mk1", sans-serif;
  margin-bottom: 35px;
}
.pre-foot-block .form form .check-wrap .control-group {
  display: flex;
  justify-content: flex-start;
}
.pre-foot-block .form form .check-wrap a {
  color: #B9975B;
  text-decoration: none;
}
.pre-foot-block .form form .check-wrap label {
  order: 2;
  line-height: 1;
}
.pre-foot-block .form form .check-wrap input {
  width: auto;
  margin: 0 15px 0 0;
  order: 1;
}
.pre-foot-block .form form button {
  max-width: 140px;
  width: 100%;
  height: 35px;
  text-align: center;
  display: block;
  color: #FFF;
  background-color: #B9975B;
  border-radius: 5px;
  border: 1px solid #c3a76d;
  font-family: "objektiv-mk1", sans-serif;
  text-transform: uppercase;
  line-height: 1;
  font-size: 14px;
}
.pre-foot-block .footer-badge {
  width: 200px;
  margin: 20px auto;
}

footer.page-foot {
  background-color: #f4ead7;
  padding: 35px;
  text-align: center;
}
footer.page-foot .footer-logo {
  max-width: 130px;
  margin: 0 auto 25px;
}

.base-footer-new {
  background: #FFF !important;
  padding-bottom: 2rem !important;
}
.base-footer-new .footer-row {
  max-width: 1088px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
@media (min-width: 56.25em) {
  .base-footer-new .footer-row {
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
}
.base-footer-new .footer-row .col-left {
  width: 100%;
}
@media (min-width: 56.25em) {
  .base-footer-new .footer-row .col-left {
    width: calc(73% - 1rem);
  }
}
.base-footer-new .footer-row .col-left.bottom {
  display: flex !important;
  align-items: flex-end !important;
  justify-content: flex-start !important;
}
.base-footer-new .footer-row .col-left .footer-nav.top {
  display: flex !important;
  justify-content: flex-start !important;
}
.base-footer-new .footer-row .col-left .footer-nav.bottom {
  margin-bottom: 1rem;
  display: flex !important;
  justify-content: center !important;
}
@media (min-width: 56.25em) {
  .base-footer-new .footer-row .col-left .footer-nav.bottom {
    justify-content: flex-start !important;
    margin-bottom: 0px;
    margin-top: 0px;
  }
}
.base-footer-new .footer-row .col-left .footer-nav.bottom ul {
  justify-content: flex-start !important;
  flex-wrap: wrap !important;
}
.base-footer-new .footer-row .col-left .footer-nav.bottom ul li {
  margin: 0px;
}
.base-footer-new .footer-row .col-left .footer-nav ul {
  gap: 1rem;
}
.base-footer-new .footer-row .col-left .footer-nav ul li {
  margin: 0px;
}
.base-footer-new .footer-row .col-left .footer-nav .menu-widget {
  margin-bottom: 1rem;
  width: 100%;
}
@media (min-width: 56.25em) {
  .base-footer-new .footer-row .col-left .footer-nav .menu-widget {
    min-width: calc(20% - 10px);
  }
}
.base-footer-new .footer-row .col-left .footer-nav .menu-widget > a {
  width: 100%;
  display: block;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 950;
  font-size: 15px !important;
  line-height: 28px;
  text-align: left;
  letter-spacing: normal;
  text-decoration: none;
  color: #2D2926 !important;
}
.base-footer-new .footer-row .col-left .footer-nav .menu-widget > a.no-link {
  cursor: default !important;
}
.base-footer-new .footer-row .col-left .footer-nav .menu-widget h3 {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 950;
  font-size: 15px !important;
  line-height: 28px;
  text-align: left;
  letter-spacing: normal;
}
.base-footer-new .footer-row .col-left .footer-nav .menu-widget ul {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 0px;
}
.base-footer-new .footer-row .col-left .footer-nav .menu-widget ul li {
  margin: 0px;
}
.base-footer-new .footer-row .col-left .footer-nav .menu-widget ul li a {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  font-size: 14px !important;
  line-height: 28px;
  text-transform: none !important;
  text-align: left;
}
.base-footer-new .footer-row .col-right {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
}
@media (min-width: 56.25em) {
  .base-footer-new .footer-row .col-right {
    width: 27%;
  }
}
.base-footer-new .footer-row .col-right .footer-nav.socials {
  width: 100%;
  display: flex !important;
  flex-direction: row !important;
  align-items: flex-end !important;
  gap: 3px;
  margin-top: 1rem;
  justify-content: center !important;
}
@media (min-width: 56.25em) {
  .base-footer-new .footer-row .col-right .footer-nav.socials {
    justify-content: flex-start !important;
  }
}
.base-footer-new .footer-row .col-right .footer-nav.socials a {
  color: #B9975B !important;
  width: 36px !important;
}
.base-footer-new .footer-row .col-right .footer-nav.socials a:hover {
  color: #231f20 !important;
}
.base-footer-new .footer-row .col-right p, .base-footer-new .footer-row .col-right h3 {
  width: 100%;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 950;
  font-size: 15px;
  line-height: 28px;
  text-transform: uppercase;
  margin-bottom: 3px;
  letter-spacing: normal !important;
  text-align: center !important;
}
@media (min-width: 56.25em) {
  .base-footer-new .footer-row .col-right p, .base-footer-new .footer-row .col-right h3 {
    text-align: left !important;
  }
}
.base-footer-new .footer-row .col-right .gform_confirmation_message {
  background: black !important;
  border: 1.5px solid #FFF !important;
  border-radius: 5px !important;
  padding: 10px !important;
  outline: none !important;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 18px !important;
  color: #FFF !important;
}
.base-footer-new .footer-row .col-right .gform_wrapper {
  max-width: 300px;
  width: 100%;
  border-radius: 9px;
  padding: 0px;
}
.base-footer-new .footer-row .col-right .gform_wrapper .gform_validation_errors {
  background: #2D2926;
  border: 1.5px solid #B9975B;
  padding: 5px;
  outline: none;
}
.base-footer-new .footer-row .col-right .gform_wrapper .gform_validation_errors * {
  font-family: "objektiv-mk1", sans-serif;
  font-size: 13px;
  color: #B9975B;
}
.base-footer-new .footer-row .col-right .gform_wrapper .gfield_validation_message {
  background: #2D2926;
  border: 1.5px solid #B9975B;
  border-radius: 5px;
  padding: 5px;
  outline: none;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 13px;
  color: #B9975B;
}
.base-footer-new .footer-row .col-right .gform_wrapper .gform_required_legend {
  display: none;
}
.base-footer-new .footer-row .col-right .gform_wrapper .gform_title {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 950;
  font-size: 15px;
  line-height: 28px;
  text-transform: uppercase;
  margin-bottom: 0.5rem;
  letter-spacing: normal !important;
  text-align: center !important;
}
@media (min-width: 56.25em) {
  .base-footer-new .footer-row .col-right .gform_wrapper .gform_title {
    text-align: left !important;
  }
}
.base-footer-new .footer-row .col-right .gform_wrapper label {
  font-family: "objektiv-mk1", sans-serif;
  font-size: 18px;
}
.base-footer-new .footer-row .col-right .gform_wrapper input {
  font-family: "objektiv-mk1", sans-serif;
  font-size: 14px;
  background: transparent;
  border: 2px solid #B9975B;
  border-radius: 8px !important;
  outline: none !important;
  cursor: text;
  padding-left: 10px;
  padding-right: 10px;
  resize: none;
}
.base-footer-new .footer-row .col-right .gform_wrapper .gfield--type-html {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 400;
  font-size: 11px;
  line-height: 17px;
  cursor: text;
  text-align: left;
}
.base-footer-new .footer-row .col-right .gform_wrapper .gform_fields {
  grid-row-gap: 15px !important;
}
.base-footer-new .footer-row .col-right .gform_wrapper .gform_footer {
  padding-top: 0.5rem !important;
  padding-bottom: 0px !important;
  justify-content: center;
}
@media (min-width: 56.25em) {
  .base-footer-new .footer-row .col-right .gform_wrapper .gform_footer {
    justify-content: flex-start;
  }
}
.base-footer-new .footer-row .col-right .gform_wrapper .gform_page_footer {
  margin: 0px !important;
  padding-top: 15px !important;
}
.base-footer-new .footer-row .col-right .gform_wrapper .gform_button {
  background: #B9975B;
  height: 36px;
  width: 100%;
  max-width: 165px;
  border-radius: 30px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 20px) 50%;
  padding: 0px 20px 0px 20px;
}
.base-footer-new .footer-row .col-right .gform_wrapper .gform_button:hover {
  border: 2px solid #2D2926;
  background: #2D2926;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 20px) 50%;
  padding: 0px 20px 0px 20px;
  cursor: pointer !important;
}

.payment_method_stripe label {
  font-size: 20px;
}
.payment_method_stripe p {
  font-size: 20px;
}
.payment_method_stripe #wc-stripe-cc-form .form-row-first {
  width: 100%;
  float: none;
}
.payment_method_stripe #wc-stripe-cc-form .form-row-last {
  width: 100%;
  float: none;
}
.payment_method_stripe #wc-stripe-cc-form .form-row {
  padding: 3px !important;
  margin: 0 0 6px !important;
  display: grid;
}

.payment_box.payment_method_stripe {
  max-width: 600px;
  margin: 0 auto;
}

.woocommerce form .form-row {
  display: grid;
}
.woocommerce .pickup-location-field .ui-datepicker-trigger {
  margin: 5px 0 0 -35px !important;
}

#privacy_policy_field {
  order: 2;
}

#newsletter_form_like_klaviyo_wrapper > h4, #newsletter_form_like_klaviyo_wrapper > p {
  text-align: center;
  color: white;
  margin-bottom: 10px;
}
#newsletter_form_like_klaviyo_wrapper div.row {
  margin-top: 10px;
  padding: 5px;
  display: flex;
  width: 100%;
}
#newsletter_form_like_klaviyo_wrapper div.row p, #newsletter_form_like_klaviyo_wrapper div.row a {
  color: white;
  font-family: Arial, sans-serif;
  font-size: 14px;
}
#newsletter_form_like_klaviyo_wrapper .small {
  font-size: 12px;
}
#newsletter_form_like_klaviyo_wrapper input {
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0px 0px 0px 16px;
  height: 38px;
  text-align: left;
  color: white;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  background-color: white;
  border: 1px solid #b4bbc3;
  margin-bottom: 15px;
}
#newsletter_form_like_klaviyo_wrapper input:nth-child(2) {
  margin-left: 10px;
}
#newsletter_form_like_klaviyo_wrapper button[type=submit] {
  background: #bfa775;
  border-radius: 2px;
  border-style: none;
  border-color: black;
  border-width: 0px;
  color: white;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  white-space: normal;
  padding-top: 11px;
  padding-bottom: 11px;
  text-align: center;
  word-break: break-word;
  align-self: flex-end;
  cursor: pointer;
  height: auto;
  width: 100%;
}

.pre-foot-block .form form > h4, .pre-foot-block .form form > p {
  text-align: center;
  color: white;
  margin-bottom: 10px;
}
.pre-foot-block .form form .gform_fields > * {
  margin-top: 10px;
  padding: 5px;
  width: 100%;
}
.pre-foot-block .form form .gform_fields > * p, .pre-foot-block .form form .gform_fields > * a {
  color: white;
  font-family: Arial, sans-serif;
  font-size: 14px;
}
.pre-foot-block .form form .gfield_html {
  color: white;
  font-family: Arial, sans-serif;
  font-size: 14px;
}
.pre-foot-block .form form .small {
  font-size: 12px;
}
.pre-foot-block .form form input {
  box-sizing: border-box;
  border-radius: 2px;
  padding: 0px 0px 0px 16px;
  height: 38px;
  text-align: left;
  color: white;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 400;
  letter-spacing: 0px;
  background-color: white;
  border: 1px solid #b4bbc3;
}
.pre-foot-block .form form input:nth-child(2) {
  margin-left: 10px;
}
.pre-foot-block .form form input[type=submit] {
  background: #bfa775;
  border-radius: 2px;
  border-style: none;
  border-color: black;
  border-width: 0px;
  color: white !important;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  white-space: normal;
  padding: 11px 16px;
  text-align: center;
  word-break: break-word;
  align-self: flex-end;
  cursor: pointer;
  height: auto;
  width: 100%;
}

.pre-foot-block .form .gform_confirmation_message {
  color: white;
  font-family: Arial, sans-serif;
  font-size: 14px;
}

.form-replacement-wrapper p {
  color: white;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 14px;
  margin-bottom: 10px;
}

#gform_wrapper_11 input[type=text],
#gform_wrapper_11 input[type=email],
#gform_wrapper_11 input[type=tel],
#gform_wrapper_12 input[type=text],
#gform_wrapper_12 input[type=email],
#gform_wrapper_12 input[type=tel],
#gform_wrapper_5 input[type=text],
#gform_wrapper_5 input[type=email],
#gform_wrapper_5 input[type=tel] {
  padding: 8px 0;
}
@media (min-width: 56.25em) {
  #gform_wrapper_11,
#gform_wrapper_12,
#gform_wrapper_5 {
    width: 60%;
    margin: auto;
  }
}

.logged-in header {
  top: 46px !important;
}
@media (min-width: 34.375em) {
  .logged-in header {
    top: 32px !important;
  }
}
.logged-in .mobile-header-btn-group {
  top: 134px !important;
}
@media (min-width: 34.375em) {
  .logged-in .mobile-header-btn-group {
    top: 120px !important;
  }
}

header .light {
  display: block;
}
header .dark {
  display: none;
}
header.page-head {
  width: 100vw;
  height: auto;
  position: fixed;
  background-color: transparent;
  background: linear-gradient(to top, rgba(45, 41, 38, 0) 0%, #2d2926 100%);
  top: 0px;
  left: 0px;
  z-index: 950;
  padding: 0;
  box-shadow: none;
}
@media (min-width: 64.125em) {
  header.page-head {
    box-shadow: none;
  }
}
header.page-head .inner {
  max-width: 1366px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  padding: 20px 1.5rem;
}
@media (min-width: 46.875em) {
  header.page-head .inner {
    flex-wrap: nowrap;
    padding: 20px 43px;
  }
}
header.page-head .inner .pub-manager-link {
  position: absolute;
  top: 0px;
  right: 102px;
  max-width: 190px;
  width: 100%;
  padding: 5px 10px 10px 10px;
  background-color: #B9975B;
  border-radius: 0px 0px 10px 10px;
  cursor: pointer;
  font-size: 12px;
  text-align: center;
}
header.page-head .inner .pub-manager-link a {
  text-decoration: none;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  text-transform: uppercase;
}
header.page-head .site-logo {
  display: block;
  max-width: 180px;
  height: 36px;
}
@media (min-width: 34.375em) {
  header.page-head .site-logo {
    height: 48px;
  }
}
header.page-head .site-logo img {
  width: auto;
  height: 36px;
}
@media (min-width: 34.375em) {
  header.page-head .site-logo img {
    height: 48px;
  }
}

.mobile-header-btn-group {
  position: fixed;
  top: 88px;
  background: #FFF7EB;
  width: 100%;
  height: 35px;
  z-index: 950;
  justify-content: center;
  flex-direction: row;
  display: flex;
}
@media (min-width: 56.25em) {
  .mobile-header-btn-group {
    display: none;
  }
}
.mobile-header-btn-group a.button {
  max-width: 50% !important;
  width: 50% !important;
  background: #B9975B !important;
  background-color: #B9975B !important;
}

header.scrolled-nav, header.hero_replacement_inner {
  background-color: #FFF7EB;
  background: #FFF7EB;
}
@media (min-width: 64.125em) {
  header.scrolled-nav .inner, header.hero_replacement_inner .inner {
    flex-wrap: nowrap;
  }
}
header.scrolled-nav .light, header.hero_replacement_inner .light {
  display: none;
}
header.scrolled-nav .dark, header.hero_replacement_inner .dark {
  display: block;
}
header.scrolled-nav .mobile-menu-btn, header.hero_replacement_inner .mobile-menu-btn {
  background-image: url("../images/butcombe-burger-menu-icon-dark.svg") !important;
}
header.scrolled-nav .mobile-menu-btn.active, header.hero_replacement_inner .mobile-menu-btn.active {
  background-image: url("../images/butcombe-x-close-dark.svg") !important;
}
header.scrolled-nav .main-nav ul li a.button,
header.scrolled-nav .main-nav ul .header-btn a.button, header.hero_replacement_inner .main-nav ul li a.button,
header.hero_replacement_inner .main-nav ul .header-btn a.button {
  color: #231f20 !important;
}
header.scrolled-nav .main-nav ul li a.button:hover,
header.scrolled-nav .main-nav ul .header-btn a.button:hover, header.hero_replacement_inner .main-nav ul li a.button:hover,
header.hero_replacement_inner .main-nav ul .header-btn a.button:hover {
  color: #FFF !important;
}
header.scrolled-nav .main-nav ul li .header-search-field-trigger,
header.scrolled-nav .main-nav ul .header-btn .header-search-field-trigger, header.hero_replacement_inner .main-nav ul li .header-search-field-trigger,
header.hero_replacement_inner .main-nav ul .header-btn .header-search-field-trigger {
  color: #231f20 !important;
}
header.scrolled-nav .main-nav ul li .header-search-field-trigger:hover,
header.scrolled-nav .main-nav ul .header-btn .header-search-field-trigger:hover, header.hero_replacement_inner .main-nav ul li .header-search-field-trigger:hover,
header.hero_replacement_inner .main-nav ul .header-btn .header-search-field-trigger:hover {
  color: #B9975B !important;
}
header.scrolled-nav .main-nav ul li a,
header.scrolled-nav .main-nav ul .header-btn a, header.hero_replacement_inner .main-nav ul li a,
header.hero_replacement_inner .main-nav ul .header-btn a {
  color: #231f20 !important;
}
header.scrolled-nav .main-nav ul li a:hover,
header.scrolled-nav .main-nav ul .header-btn a:hover, header.hero_replacement_inner .main-nav ul li a:hover,
header.hero_replacement_inner .main-nav ul .header-btn a:hover {
  color: #B9975B !important;
}
header.scrolled-nav .main-nav ul li .header-close-btn,
header.scrolled-nav .main-nav ul .header-btn .header-close-btn, header.hero_replacement_inner .main-nav ul li .header-close-btn,
header.hero_replacement_inner .main-nav ul .header-btn .header-close-btn {
  background-image: url("../images/butcombe-search-close-icon-dark.svg") !important;
}
header.menu_active {
  background-color: transparent;
  background: transparent;
}
header.menu_active .light {
  display: block;
}
header.menu_active .dark {
  display: none;
}
header.menu_active .main-nav ul li.active a {
  color: #B9975B !important;
}
header.menu_active .main-nav ul li a.button,
header.menu_active .main-nav ul .header-btn a.button {
  color: #f9f5eb !important;
}
header.menu_active .main-nav ul li a.button:hover,
header.menu_active .main-nav ul .header-btn a.button:hover {
  color: #FFF !important;
}
header.menu_active .main-nav ul li .header-search-field-trigger,
header.menu_active .main-nav ul .header-btn .header-search-field-trigger {
  color: #f9f5eb !important;
}
header.menu_active .main-nav ul li .header-search-field-trigger:hover,
header.menu_active .main-nav ul .header-btn .header-search-field-trigger:hover {
  color: #B9975B !important;
}
header.menu_active .main-nav ul li a,
header.menu_active .main-nav ul .header-btn a {
  color: #f9f5eb !important;
}
header.menu_active .main-nav ul li a:hover,
header.menu_active .main-nav ul .header-btn a:hover {
  color: #B9975B !important;
}
header.menu_active .main-nav ul li .header-close-btn,
header.menu_active .main-nav ul .header-btn .header-close-btn {
  background-image: url("../images/butcombe-search-close-icon.svg") !important;
}

.woocommerce header.page-head .site-logo {
  padding: 10px 0;
}

.table-book-wrap {
  display: none;
}
.table-book-wrap.active {
  display: block;
  max-width: 100vw;
  height: 100vh;
  position: fixed;
  overflow: hidden;
  top: 0px;
  right: 0px;
  left: 0;
  z-index: 999;
  background-color: #f9f5eb;
  padding: 10px;
  text-align: center;
}
@media (min-width: 46.875em) {
  .table-book-wrap.active {
    padding: 25px;
    left: auto;
    max-width: 75vw;
    -webkit-box-shadow: none;
    -moz-box-shadow: none;
    box-shadow: none;
  }
}
@media (min-width: 56.25em) {
  .table-book-wrap.active {
    max-width: 45vw;
  }
}
@media (min-width: 64.125em) {
  .table-book-wrap.active {
    max-width: 40vw;
  }
}
@media (min-width: 96.875em) {
  .table-book-wrap.active {
    max-width: 35vw;
  }
}
.table-book-wrap.active .close-iframe {
  display: block;
  font-family: "objektiv-mk1", sans-serif;
  text-transform: uppercase;
  font-size: 14px;
  color: #231f20;
  text-align: right;
  border-bottom: none;
  order: 1;
  cursor: pointer;
}
.table-book-wrap.active .close-iframe::after {
  content: "✕";
}
.table-book-wrap.active p {
  font-family: "objektiv-mk1", sans-serif;
  margin: 25px 0;
}

.custom-anchor-tag {
  cursor: pointer;
}

.inner.top_inner {
  background-color: #282627 !important;
}
@media (min-width: 64.125em) {
  .inner.top_inner nav ul.top_navbar_menu {
    max-width: 100%;
  }
}
.inner.inner-top-active .my-account-menu {
  display: none;
}
@media (min-width: 46.875em) {
  .inner.inner-top-active .my-account-menu {
    display: none;
  }
}
.inner.inner-top-active .desktop-wrapper {
  display: none;
}
@media (min-width: 68.75em) {
  .inner.inner-top-active .desktop-wrapper {
    width: 100%;
    display: flex;
  }
}
.inner.inner-top-active .desktop-wrapper .right-section {
  width: 100%;
  display: flex;
  align-items: center;
}
.inner.inner-top-active .mobile-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
@media (min-width: 68.75em) {
  .inner.inner-top-active .mobile-wrapper {
    display: none;
  }
}
.inner.inner-top-active .mobile-wrapper .mobile-menu-btn {
  width: 40px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-image: url("../images/butcombe-burger-menu-icon.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 38px 27px;
  transition: all 1s ease-in-out;
}
.inner.inner-top-active .mobile-wrapper .mobile-menu-btn.active {
  background-image: url("../images/butcombe-x-close.svg");
}
.inner.inner-top-active .mobile-wrapper .mobile-menu-btn.disabled {
  background-image: none !important;
}
.inner.inner-top-active .mobile-wrapper .mobile-logo-wrapper {
  width: calc(100% - 80px);
  display: flex;
  align-items: center;
  justify-content: center;
}
.inner.inner-top-active .mobile-wrapper .mobile-search-btn {
  width: 40px;
  height: 48px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.inner.inner-top-active .mobile-wrapper .mobile-search-btn.disabled img {
  display: none;
}

nav ul {
  margin: 0;
  padding: 0;
}
nav ul li {
  list-style-type: none;
}
nav.main-nav {
  z-index: 5;
  padding: 0 2rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}
@media (min-width: 64.125em) {
  nav.main-nav {
    width: 100%;
    display: block;
  }
}
nav.main-nav ul {
  display: none;
}
@media (min-width: 64.125em) {
  nav.main-nav ul {
    display: flex;
    justify-content: space-evenly;
    align-items: center;
    width: 100%;
    gap: 0.75rem;
  }
}
nav.main-nav ul li {
  padding: 0px;
  text-align: center;
}
nav.main-nav ul li.active a {
  color: #B9975B;
}
nav.main-nav ul li a {
  color: #f9f5eb;
  text-decoration: none;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 16px;
}
nav.main-nav ul li a:hover {
  color: #B9975B;
}
nav.main-nav ul li a.button {
  background: transparent;
  border: 3px solid #B9975B;
  padding: 6px 0px 5px 0px !important;
  border-radius: 20px;
  min-width: 155px;
  font-size: 12px !important;
}
nav.main-nav ul li a.button:hover {
  background: #B9975B !important;
}
nav.main-nav ul li.header-btn.search-active {
  opacity: 0;
}
nav.main-nav ul li.header-btn-group {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  min-width: 319px;
  gap: 0.5rem;
}
nav.main-nav ul li#header-search-field-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 120px;
}
nav.main-nav ul li#header-search-field-container.active .header-search-field-trigger {
  display: none;
}
nav.main-nav ul li#header-search-field-container.active .header-search-field-group {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  -webkit-animation: fadeIn 1.5s;
  animation: fadeIn 1.5s;
}
nav.main-nav ul li#header-search-field-container .header-search-field-trigger {
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 90px;
  color: #f9f5eb;
  font-family: "objektiv-mk1", sans-serif !important;
  font-size: 16px;
  cursor: pointer;
}
nav.main-nav ul li#header-search-field-container .header-search-field-trigger:hover {
  color: #B9975B;
}
nav.main-nav ul li#header-search-field-container .header-search-field-trigger .header-search-btn {
  height: 36px;
  width: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
nav.main-nav ul li#header-search-field-container .header-search-field-trigger .header-search-btn img {
  height: 16px;
  width: 16px;
}
nav.main-nav ul li#header-search-field-container .header-search-field-group {
  width: calc(430px + 1.5rem);
  position: absolute;
  right: 20px;
  display: none;
  background: #FFF;
  border: 2px solid #B9975B;
  border-radius: 8px !important;
  z-index: 10;
}
nav.main-nav ul li#header-search-field-container .header-search-field-group input {
  width: calc(100% - 36px) !important;
  height: 36px !important;
  background: transparent !important;
  color: #231f20 !important;
  text-decoration: none !important;
  font-family: "objektiv-mk1", sans-serif !important;
  font-size: 15px !important;
  font-weight: 300 !important;
  border-radius: 8px !important;
  border: 0px !important;
  outline: 0px !important;
  padding: 0px 10px !important;
  padding-top: 0px !important;
  text-shadow: none !important;
}
nav.main-nav ul li#header-search-field-container .header-search-field-group input .autocomplete {
  display: none !important;
}
nav.main-nav ul li#header-search-field-container .header-search-field-group .header-search-btn {
  height: 36px;
  width: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
nav.main-nav ul li#header-search-field-container .header-search-field-group .header-search-btn img {
  height: 16px;
  width: 16px;
}
nav.main-nav ul li#header-search-field-container .header-search-field-group .header-close-btn {
  position: absolute;
  width: 52px;
  right: 0px;
  bottom: -22px;
  cursor: pointer;
  background-image: url("../images/butcombe-search-close-icon.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 52px 18px;
}
nav.footer-nav {
  display: flex;
  justify-content: center;
  flex-direction: column;
}
@media (min-width: 46.875em) {
  nav.footer-nav {
    flex-direction: row;
  }
}
nav.footer-nav ul {
  display: flex;
  justify-content: center;
  align-items: center;
}
nav.footer-nav ul li {
  margin: 0 10px;
}
nav.footer-nav ul li a {
  color: #231f20;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  text-decoration: none;
}
nav.footer-nav ul li a:hover {
  text-decoration: underline;
}
nav.footer-nav.top {
  display: none;
}
@media (min-width: 46.875em) {
  nav.footer-nav.top {
    display: block;
    margin-bottom: 25px;
  }
}
nav.footer-nav.bottom ul:nth-child(2) {
  display: flex;
  justify-content: center;
  flex-direction: column;
  margin: 20px 0;
}
@media (min-width: 46.875em) {
  nav.footer-nav.bottom ul:nth-child(2) {
    flex-direction: row;
  }
}
nav.footer-nav.bottom li {
  display: block;
}
nav.footer-nav.bottom li.social {
  display: block;
  max-width: 25px;
  margin: 0 10px;
}
nav.footer-nav.bottom li.social img {
  width: 100%;
}
nav.footer-nav.bottom li.payment-icons {
  max-width: 90px;
}
nav.footer-nav.bottom li.payment-icons img {
  width: 100%;
}
nav.footer-nav.bottom li.close-menu {
  display: none !important;
}
nav.footer-nav.bottom.shop-css {
  display: flex;
  justify-content: center;
  align-items: center;
}

.ajaxsearchpro h3 {
  font-family: "objektiv-mk1", sans-serif !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}
.ajaxsearchpro h3 * {
  font-family: "objektiv-mk1", sans-serif !important;
  font-weight: 600 !important;
  font-size: 14px !important;
}
.ajaxsearchpro .asp_res_text {
  font-family: "objektiv-mk1", sans-serif !important;
  font-weight: 300 !important;
  font-size: 12px !important;
}
.ajaxsearchpro .asp_res_text * {
  font-family: "objektiv-mk1", sans-serif !important;
  font-weight: 300 !important;
  font-size: 12px !important;
}

.menu-item-children {
  position: fixed;
  opacity: 0;
  z-index: 0;
  background: #2D2926 !important;
  width: 100%;
  height: 100%;
  max-height: 675px;
  top: 0;
  left: -100vw;
  padding-top: 145px;
}
.menu-item-children.active {
  opacity: 1;
  left: 0;
  z-index: 700;
}
.menu-item-children .content-wrapper {
  position: relative;
  width: 100%;
  max-width: 1088px;
  margin: 0 auto;
}
.menu-item-children .content-wrapper ul {
  margin: 0;
  padding: 0;
}
.menu-item-children .content-wrapper ul .close-all-menu-children-container {
  margin-bottom: 1rem;
}
.menu-item-children .content-wrapper ul .close-all-menu-children-container .close-all-menu-children {
  display: flex;
  align-items: center;
  font-size: 15px !important;
}
.menu-item-children .content-wrapper ul .close-all-menu-children-container .close-all-menu-children img {
  transform: rotate(180deg);
}
.menu-item-children .content-wrapper ul .close-all-menu-children-container.active {
  border-top: 1px solid #2D2926;
  border-bottom: 1px solid #2D2926;
}
.menu-item-children .content-wrapper ul li {
  width: 244px;
  list-style-type: none;
  border-top: 1px solid #2D2926;
  border-bottom: 1px solid #2D2926;
}
.menu-item-children .content-wrapper ul li.active {
  border-top: 1px solid #B9975B;
  border-bottom: 1px solid #B9975B;
  color: #B9975B;
}
.menu-item-children .content-wrapper ul li.active a {
  color: #B9975B;
}
.menu-item-children .content-wrapper ul li a {
  letter-spacing: normal;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 20px;
  line-height: 50px;
  color: #FFF;
  text-transform: uppercase;
  text-decoration: none;
}
.menu-item-children .content-wrapper ul li a:hover {
  color: #B9975B;
}
.menu-item-children .content-wrapper ul li .menu-child-item-children {
  display: none;
  position: absolute;
  top: 0px;
  left: calc(244px + 1rem);
  width: 524px;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  transition: all 0.5s ease-in-out;
}
.menu-item-children .content-wrapper ul li .menu-child-item-children.active {
  display: flex;
}
.menu-item-children .content-wrapper ul li .menu-child-item-children .menu-child-item {
  width: calc(50% - 0.4rem);
}
.menu-item-children .content-wrapper ul li .menu-child-item-children .menu-child-item a {
  letter-spacing: normal;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  font-size: 18px;
  line-height: 40px;
  color: #FFF;
  text-transform: uppercase;
  text-decoration: none;
}
.menu-item-children .content-wrapper ul li .menu-child-item-children .menu-child-item a:hover {
  color: #B9975B;
}
.menu-item-children .content-wrapper ul li .menu-list-of-benefits {
  position: absolute;
  top: 0;
  right: 0;
  width: 284px;
}
.menu-item-children .content-wrapper ul li .menu-list-of-benefits .benefit-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  background: #3C3632;
  margin-bottom: 5px;
  padding: 1rem 0.5rem;
  gap: 0.5rem;
}
.menu-item-children .content-wrapper ul li .menu-list-of-benefits .benefit-wrapper .image-wrapper {
  width: 60px;
  margin-top: 5px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}
.menu-item-children .content-wrapper ul li .menu-list-of-benefits .benefit-wrapper .text-wrapper {
  width: calc(100% - 60px - 1rem);
}
.menu-item-children .content-wrapper ul li .menu-list-of-benefits .benefit-wrapper .text-wrapper h3 {
  font-family: "objektiv-mk1", sans-serif;
  font-size: 16px !important;
  font-weight: bold;
  color: #B9975B;
  text-transform: none;
  letter-spacing: normal;
}
.menu-item-children .content-wrapper ul li .menu-list-of-benefits .benefit-wrapper .text-wrapper p {
  margin-top: 5px;
  margin-bottom: 5px;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 14px;
  font-weight: 300;
  color: #FFF;
  letter-spacing: normal;
}

.mobile-menu-search,
.mobile-menu,
.mobile-menu-item-level2,
.mobile-menu-item-level3 {
  position: fixed;
  opacity: 0;
  z-index: 700;
  background: #2D2926 !important;
  width: 100vw;
  height: 100vh;
  top: 0;
  left: -100vw;
  transition: all 0.5s linear;
  padding: 175px 1.5rem 1.5rem 1.5rem;
}
.mobile-menu-search.active,
.mobile-menu.active,
.mobile-menu-item-level2.active,
.mobile-menu-item-level3.active {
  opacity: 1;
  left: 0;
}
@media (min-width: 46.875em) {
  .mobile-menu-search,
.mobile-menu,
.mobile-menu-item-level2,
.mobile-menu-item-level3 {
    padding: 160px 1.5rem 1.5rem 1.5rem;
  }
}
@media (min-width: 64.125em) {
  .mobile-menu-search,
.mobile-menu,
.mobile-menu-item-level2,
.mobile-menu-item-level3 {
    padding: 145px 1.5rem 1.5rem 1.5rem;
  }
}
.mobile-menu-search a,
.mobile-menu-search .mobile-menu-close,
.mobile-menu a,
.mobile-menu .mobile-menu-close,
.mobile-menu-item-level2 a,
.mobile-menu-item-level2 .mobile-menu-close,
.mobile-menu-item-level3 a,
.mobile-menu-item-level3 .mobile-menu-close {
  letter-spacing: normal;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 20px;
  line-height: 50px;
  color: #FFF;
  text-transform: uppercase;
  text-decoration: none;
  cursor: pointer;
}
.mobile-menu-search a:hover,
.mobile-menu-search .mobile-menu-close:hover,
.mobile-menu a:hover,
.mobile-menu .mobile-menu-close:hover,
.mobile-menu-item-level2 a:hover,
.mobile-menu-item-level2 .mobile-menu-close:hover,
.mobile-menu-item-level3 a:hover,
.mobile-menu-item-level3 .mobile-menu-close:hover {
  color: #B9975B;
}
.mobile-menu-search a img,
.mobile-menu a img,
.mobile-menu-item-level2 a img,
.mobile-menu-item-level3 a img {
  width: 20px;
  height: 15px;
  object-fit: contain;
}
.mobile-menu-search .mobile-menu-close,
.mobile-menu .mobile-menu-close,
.mobile-menu-item-level2 .mobile-menu-close,
.mobile-menu-item-level3 .mobile-menu-close {
  font-size: 15px;
  margin-bottom: 1rem;
}
.mobile-menu-search .mobile-menu-close img,
.mobile-menu .mobile-menu-close img,
.mobile-menu-item-level2 .mobile-menu-close img,
.mobile-menu-item-level3 .mobile-menu-close img {
  transform: rotate(180deg);
}
.mobile-menu-search ul,
.mobile-menu ul,
.mobile-menu-item-level2 ul,
.mobile-menu-item-level3 ul {
  margin: 0;
  padding: 0;
}
.mobile-menu-search ul li,
.mobile-menu ul li,
.mobile-menu-item-level2 ul li,
.mobile-menu-item-level3 ul li {
  width: 100%;
  list-style-type: none;
}

.mobile-menu-item-level3 a {
  font-weight: 300;
  font-size: 17px;
  line-height: 38px;
}
.mobile-menu-item-level3 .mobile-menu-wrapper {
  height: 500px;
  overflow-y: scroll;
}

.mobile-menu-search .header-search-field-group {
  position: relative;
  background: #FFF;
  border: 2px solid #B9975B;
  border-radius: 8px !important;
  z-index: 10;
}
.mobile-menu-search .header-search-field-group input {
  width: calc(100% - 36px) !important;
  height: 36px !important;
  background: transparent !important;
  color: #231f20 !important;
  text-decoration: none !important;
  font-family: "objektiv-mk1", sans-serif !important;
  font-size: 15px !important;
  font-weight: 300 !important;
  border-radius: 8px !important;
  border: 0px !important;
  outline: 0px !important;
  padding: 0px 10px !important;
  padding-top: 0px !important;
  text-shadow: none !important;
}
.mobile-menu-search .header-search-field-group input .autocomplete {
  display: none !important;
}
.mobile-menu-search .header-search-field-group .header-search-btn {
  height: 36px;
  width: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.mobile-menu-search .header-search-field-group .header-search-btn img {
  height: 16px;
  width: 16px;
}
.mobile-menu-search .header-search-field-group .mobile-header-close-btn {
  position: absolute;
  width: 52px;
  right: 0px;
  bottom: -28px;
  cursor: pointer;
  background-image: url("../images/butcombe-search-close-icon.svg");
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: 52px 18px;
}

.ui-autocomplete {
  z-index: 1000 !important;
}

h1, h2, h3, h4, h5 {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 2px;
}

h1.home-banner, h2.home-banner {
  font-size: 36px;
  line-height: 43px;
  margin-bottom: 10px;
  width: 100%;
  text-align: center;
}
@media (min-width: 46.875em) {
  h1.home-banner, h2.home-banner {
    font-size: 52px;
    line-height: 46px;
    margin-bottom: 25px;
  }
}

a.button {
  max-width: 140px;
  width: 100%;
  height: 35px;
  text-align: center;
  display: flex !important;
  color: #FFF !important;
  background-color: transparent !important;
  border: 1px solid #c3a76d !important;
  font-family: "objektiv-mk1", sans-serif;
  text-transform: uppercase;
  line-height: 1 !important;
  text-decoration: none !important;
  font-size: 14px !important;
  justify-content: center;
  align-items: center;
}
a.button.gold {
  background-color: #c3a76d !important;
}
a.button.centered {
  margin: 0 auto !important;
}
a.button.wider {
  max-width: 200px;
  margin-right: 15px !important;
}
a.button.white {
  background-color: #FFF !important;
  color: #231f20 !important;
  border: 1px solid #231f20 !important;
}
a.button.product-page {
  margin-top: 15px !important;
  font-size: 18px !important;
  padding-top: 5px !important;
}
@media (min-width: 46.875em) {
  a.button.product-page {
    margin-left: 15px !important;
  }
}

.standard-text-block.no-bottom-padding {
  padding-bottom: 0px !important;
}
.standard-text-block h1, .standard-text-block h2 {
  font-size: 26px;
  line-height: 32px;
  margin-bottom: 20px;
}
@media (min-width: 46.875em) {
  .standard-text-block h1, .standard-text-block h2 {
    font-size: 33px;
    line-height: 36px;
  }
}
.standard-text-block h3 {
  margin-bottom: 20px;
}
.standard-text-block a {
  color: #c3a76d;
}
.standard-text-block p {
  font-size: 16px;
  line-height: 26px;
  max-width: 720px;
  width: 100%;
  margin: 0 auto 15px;
  text-align: center;
  font-family: "objektiv-mk1", sans-serif;
  overflow: hidden;
}
.standard-text-block p strong {
  letter-spacing: 2px;
  font-family: "objektiv-mk1", sans-serif;
}
.standard-text-block ul, .standard-text-block ol {
  padding: 0 0 0 20px;
  max-width: 720px;
  width: 100%;
  margin: 0 auto 15px;
  font-family: "objektiv-mk1", sans-serif;
}
.standard-text-block ul li, .standard-text-block ol li {
  text-align: left;
  font-size: 16px;
  font-family: "objektiv-mk1", sans-serif;
}
.standard-text-block ul li a, .standard-text-block ol li a {
  font-family: "objektiv-mk1", sans-serif;
}
.standard-text-block blockquote p {
  font-style: italic;
  font-size: 18px;
}
.standard-text-block figure.wp-block-image {
  max-width: 720px;
  width: 100%;
  margin: 0 auto;
}
.standard-text-block figure.wp-block-image img {
  width: 100%;
}
.standard-text-block figure.wp-block-image figcaption {
  text-align: left;
}
.standard-text-block.home p {
  text-align: center;
}

.text-primary {
  color: #231f20 !important;
}

.home .hero-wrap {
  position: relative;
}
.home .hero-wrap .hero {
  width: 100%;
  height: 30vh;
}
@media (min-width: 56.25em) {
  .home .hero-wrap .hero {
    height: 75vh;
  }
}
.home .hero-wrap .hero .slick-list {
  height: 30vh;
}
@media (min-width: 56.25em) {
  .home .hero-wrap .hero .slick-list {
    height: 75vh;
  }
}
.home .hero-wrap .hero .slick-list .slick-track {
  height: 30vh;
}
@media (min-width: 56.25em) {
  .home .hero-wrap .hero .slick-list .slick-track {
    height: 75vh;
  }
}
.home .hero-wrap .hero .slick-list .slick-track .slick-slide {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
}
.home .hero-wrap .hero .slick-list .slick-track .slick-slide .hero-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: RGBA(38, 36, 32, 0.5);
  color: #FFF;
  text-align: center;
  display: grid;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0px;
}
.home .hero-wrap .hero .slick-list .slick-track .slick-slide .hero-inner .home-banner-text {
  font-family: "objektiv-mk1", sans-serif;
  margin-bottom: 25px;
}
.home .hero-wrap .hero button {
  position: absolute;
  top: 49%;
  width: 15px;
  height: 20px;
  font-size: 0px;
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  display: none !important;
}
@media (min-width: 46.875em) {
  .home .hero-wrap .hero button {
    display: block !important;
  }
}
.home .hero-wrap .hero button.slick-prev {
  background-image: url("../images/prev-arrow.png");
  left: 60px;
}
@media (min-width: 56.25em) {
  .home .hero-wrap .hero button.slick-prev {
    left: 75px;
  }
}
.home .hero-wrap .hero button.slick-next {
  background-image: url("../images/next-arrow.png");
  right: 60px;
}
@media (min-width: 56.25em) {
  .home .hero-wrap .hero button.slick-next {
    right: 75px;
  }
}

.hero-wrap {
  position: relative;
}
.hero-wrap .hero {
  width: 100%;
  height: 30vh;
}
@media (min-width: 56.25em) {
  .hero-wrap .hero {
    height: 60vh;
  }
}
.hero-wrap .hero .slick-list {
  height: 30vh;
}
@media (min-width: 56.25em) {
  .hero-wrap .hero .slick-list {
    height: 60vh;
  }
}
.hero-wrap .hero .slick-list .slick-track {
  height: 30vh;
}
@media (min-width: 56.25em) {
  .hero-wrap .hero .slick-list .slick-track {
    height: 60vh;
  }
}
.hero-wrap .hero .slick-list .slick-track .slick-slide {
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center center;
  position: relative;
  height: 30vh;
}
@media (min-width: 56.25em) {
  .hero-wrap .hero .slick-list .slick-track .slick-slide {
    height: 60vh;
  }
}
.hero-wrap .hero .slick-list .slick-track .slick-slide .hero-bg-wrap {
  height: 30vh;
}
@media (min-width: 56.25em) {
  .hero-wrap .hero .slick-list .slick-track .slick-slide .hero-bg-wrap {
    height: 60vh;
  }
}
.hero-wrap .hero .slick-list .slick-track .slick-slide .hero-inner {
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: RGBA(38, 36, 32, 0.5);
  color: #FFF;
  text-align: center;
  display: grid;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  align-content: center;
  padding: 0 20px;
}
@media (min-width: 46.875em) {
  .hero-wrap .hero .slick-list .slick-track .slick-slide .hero-inner {
    padding: 0 60px;
  }
}
.hero-wrap .hero .slick-list .slick-track .slick-slide .hero-inner .home-banner-text {
  font-family: "objektiv-mk1", sans-serif;
  margin-bottom: 25px;
}
.hero-wrap .hero button {
  position: absolute;
  top: 49%;
  width: 15px;
  height: 20px;
  font-size: 0px;
  background-color: transparent;
  background-repeat: no-repeat;
  border: 0;
  display: none !important;
}
@media (min-width: 46.875em) {
  .hero-wrap .hero button {
    display: block !important;
  }
}
.hero-wrap .hero button.slick-prev {
  background-image: url("../images/prev-arrow.png");
  left: 60px;
}
@media (min-width: 56.25em) {
  .hero-wrap .hero button.slick-prev {
    left: 75px;
  }
}
.hero-wrap .hero button.slick-next {
  background-image: url("../images/next-arrow.png");
  right: 60px;
}
@media (min-width: 56.25em) {
  .hero-wrap .hero button.slick-next {
    right: 75px;
  }
}

.single-product .hero-wrap .hero .slick-list .slick-track .slick-slide .hero-inner h1, .single-product .hero-wrap .hero .slick-list .slick-track .slick-slide .hero-inner h2,
.tax-product_cat .hero-wrap .hero .slick-list .slick-track .slick-slide .hero-inner h1,
.tax-product_cat .hero-wrap .hero .slick-list .slick-track .slick-slide .hero-inner h2 {
  font-size: 24px;
  line-height: normal;
}
@media (min-width: 46.875em) {
  .single-product .hero-wrap .hero .slick-list .slick-track .slick-slide .hero-inner h1, .single-product .hero-wrap .hero .slick-list .slick-track .slick-slide .hero-inner h2,
.tax-product_cat .hero-wrap .hero .slick-list .slick-track .slick-slide .hero-inner h1,
.tax-product_cat .hero-wrap .hero .slick-list .slick-track .slick-slide .hero-inner h2 {
    font-size: 40px;
    line-height: normal;
  }
}

.single-post .hero .hero-inner .home-banner {
  font-size: 42px;
  line-height: 38px;
  padding-top: 30px;
}

.hero .hero-inner h1.home-banner {
  font-size: 26px;
}
@media (min-width: 46.875em) {
  .hero .hero-inner h1.home-banner {
    font-size: 36px;
    line-height: 43px;
    position: relative;
  }
}
@media (min-width: 56.25em) {
  .hero .hero-inner h1.home-banner {
    font-size: 52px;
    line-height: 46px;
  }
}
.hero-social {
  position: absolute;
  right: 0px;
  top: 0px;
  z-index: 900;
  width: 55px;
  height: 100%;
  padding: 0 10px;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
.hero-social .social img {
  width: 100%;
}

.home .butcombe-badge {
  display: block;
}

.butcombe-badge {
  display: none;
}

.butcombe-badge {
  position: absolute;
  z-index: 910;
  bottom: -40px;
  left: 0px;
  text-align: center;
  width: 100%;
}
@media (min-width: 56.25em) {
  .butcombe-badge {
    bottom: -60px;
  }
}
.butcombe-badge img {
  width: 100%;
  max-width: 75px;
}
@media (min-width: 56.25em) {
  .butcombe-badge img {
    max-width: 120px;
  }
}

.page-template-template-second-level-property .hero-wrap,
.page-template-template-top-level-property .hero-wrap {
  display: none;
}

.hero_replacement {
  min-height: 98px;
  background-color: transparent;
}
.hero_replacement.hero_replacement_woocommerce {
  min-height: auto;
}
@media (min-width: 46.875em) {
  .hero_replacement.hero_replacement_woocommerce {
    min-height: 100px;
  }
}
@media (min-width: 64.125em) {
  .hero_replacement.hero_replacement_woocommerce {
    min-height: 200px;
  }
}

#map .gm-ui-hover-effect {
  opacity: 1 !important;
  font-size: 20px !important;
  width: 40px !important;
  height: 40px !important;
  top: 0 !important;
  right: 0 !important;
  padding: 7px !important;
}
#map .gm-ui-hover-effect img {
  width: 100% !important;
  height: 100% !important;
  margin: 0 !important;
}
#map .map_info_window {
  padding: 10px;
}
#map .map_info_window h1, #map .map_info_window h2, #map .map_info_window h3, #map .map_info_window h4, #map .map_info_window h5, #map .map_info_window h6 {
  margin-bottom: 20px;
}
#map .map_info_window img {
  width: 100%;
  height: auto;
  margin-bottom: 10px;
  display: block;
}
#map .map_info_window p {
  margin-bottom: 10px;
}

div.woocommerce {
  margin: 20px;
}
@media (min-width: 90em) {
  div.woocommerce {
    margin: 20px 0;
  }
}
div.woocommerce > h2 {
  text-align: center;
  margin: 20px 0;
}
div.woocommerce p,
div.woocommerce blockquote,
div.woocommerce pre,
div.woocommerce dl,
div.woocommerce dd,
div.woocommerce form,
div.woocommerce fieldset,
div.woocommerce legend,
div.woocommerce table,
div.woocommerce th,
div.woocommerce td,
div.woocommerce caption,
div.woocommerce li,
div.woocommerce a,
div.woocommerce select,
div.woocommerce address,
div.woocommerce hr {
  font-family: "objektiv-mk1", sans-serif;
  font-size: 16px;
  color: #231f20;
}
div.woocommerce fieldset {
  border: none;
}
div.woocommerce p {
  margin-bottom: 10px;
}
div.woocommerce #respond input#submit.alt, div.woocommerce a.button.alt, div.woocommerce button.button.alt, div.woocommerce input.button.alt {
  background-color: #B9975B;
}
div.woocommerce #respond input#submit.alt:hover, div.woocommerce a.button.alt:hover, div.woocommerce button.button.alt:hover, div.woocommerce input.button.alt:hover {
  background-color: #9e7d43;
}
div.woocommerce .woocommerce-account .woocommerce-MyAccount-navigation {
  width: 20%;
}
div.woocommerce .woocommerce-account .woocommerce-MyAccount-content {
  width: 78%;
}
div.woocommerce .woocommerce-form.woocommerce-form-login,
div.woocommerce .woocommerce-ResetPassword {
  border: 1px solid #231f20;
  padding: 20px;
  border-radius: 5px;
  margin-bottom: 20px;
}
div.woocommerce .woocommerce-ResetPassword.form-row {
  width: 100%;
}
div.woocommerce .woocommerce-error,
div.woocommerce .woocommerce-info,
div.woocommerce .woocommerce-message {
  border-top: 3px solid #c3a76d;
  background-color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  color: #231f20;
  padding: 15px;
  text-align: center;
}
div.woocommerce .woocommerce-info::before {
  color: #c3a76d;
}
div.woocommerce .subscription_details a.button {
  max-width: none !important;
}
div.woocommerce .woocommerce-MyAccount-content .button {
  height: auto;
}
div.woocommerce .woocommerce-form-coupon .button,
div.woocommerce .woocommerce-form-login__submit,
div.woocommerce .woocommerce-MyAccount-content .button,
div.woocommerce .woocommerce-Button.button,
div.woocommerce table.my_account_orders .button,
div.woocommerce .woocommerce-Button--next.button {
  background-color: #c3a76d !important;
  max-width: max-content;
  min-width: 140px;
  margin-bottom: 10px;
  line-height: 1.5 !important;
  color: #FFF !important;
}
div.woocommerce table.my_account_orders {
  border: 0 !important;
}
@media (min-width: 46.875em) {
  div.woocommerce table.my_account_orders {
    border: 1px solid rgba(0, 0, 0, 0.1);
  }
}
div.woocommerce table.my_account_orders td.woocommerce-orders-table__cell-order-actions {
  border-top: 0;
}
@media (min-width: 46.875em) {
  div.woocommerce table.my_account_orders td.woocommerce-orders-table__cell-order-actions {
    border-top: 1px solid rgba(0, 0, 0, 0.1);
  }
}
div.woocommerce table.my_account_orders tr:nth-child(2n) td {
  background-color: rgba(0, 0, 0, 0.1);
}
div.woocommerce table.my_account_orders .woocommerce-orders-table__cell-order-actions:before {
  display: none;
}
div.woocommerce table.my_account_orders .woocommerce-orders-table__cell-order-actions .button {
  max-width: 100%;
}
@media (min-width: 46.875em) {
  div.woocommerce table.my_account_orders .woocommerce-orders-table__cell-order-actions .button {
    min-width: 140px;
    margin-bottom: 5px;
  }
}
@media (min-width: 46.875em) {
  div.woocommerce table.my_account_orders .woocommerce-orders-table__cell-order-actions .button:last-child {
    margin-bottom: 0;
  }
}
div.woocommerce .woocommerce-form-coupon input,
div.woocommerce .woocommerce-form input,
div.woocommerce .woocommerce-MyAccount-content input,
div.woocommerce .woocommerce-Input {
  padding: 10px 10px 10px 0;
  background-color: transparent;
  border-right: 0;
  border-left: 0;
  border-top: 0;
  border-bottom: 2px solid #231f20;
  font-size: 16px;
}
div.woocommerce .woocommerce-MyAccount-navigation {
  display: none;
}
@media (min-width: 46.875em) {
  div.woocommerce .woocommerce-MyAccount-navigation {
    display: block !important;
  }
}
div.woocommerce .woocommerce-MyAccount-navigation li {
  margin: 0 0 10px 0;
  border-radius: 5px;
  background-color: #c3a76d;
}
div.woocommerce .woocommerce-MyAccount-navigation li.is-active {
  background-color: #231f20;
}
div.woocommerce .woocommerce-MyAccount-navigation li a {
  text-decoration: none;
  padding: 10px;
  color: #FFF;
  display: block;
  width: 100%;
}
div.woocommerce .woocommerce-form-login-toggle .woocommerce-info {
  margin-bottom: 20px;
}
div.woocommerce .woocommerce-form-login-toggle .woocommerce-info a {
  background-color: transparent !important;
  color: #B9975B;
}
@media (min-width: 46.875em) {
  div.woocommerce .woocommerce-form-login-toggle .woocommerce-info a {
    background-color: transparent !important;
  }
}
div.woocommerce .woocommerce-cart-form .wcsatt-options {
  padding: 0;
}

.woocommerce-checkout .woocommerce-form.woocommerce-form-login,
.woocommerce-checkout .checkout_coupon {
  max-width: 100%;
  border: 0 !important;
}

.woocommerce-form-coupon.toggle-gift-card {
  padding: 0px;
}
@media (min-width: 56.25em) {
  .woocommerce-form-coupon.toggle-gift-card {
    padding: 25px;
  }
}
.woocommerce-form-coupon.toggle-gift-card .form-row {
  display: inline-block;
}
.woocommerce-form-coupon.toggle-gift-card #gift_card_code {
  width: 100%;
  margin-bottom: 20px;
}
@media (min-width: 56.25em) {
  .woocommerce-form-coupon.toggle-gift-card #gift_card_code {
    width: 320px;
    margin-bottom: 0;
  }
}

.single-product .wcsatt-options-wrapper {
  width: 100%;
}
.single-product .wcsatt-options-wrapper .wcsatt-options-prompt-radios {
  padding: 0;
}
.single-product .wcsatt-options-wrapper .wcsatt-options-prompt-radios label.wcsatt-options-prompt-label {
  width: 100% !important;
  margin-top: 10px;
}
.single-product .wcsatt-options-product-wrapper .wcsatt-options-product-dropdown-label {
  font-family: "objektiv-mk1", sans-serif;
  float: left;
  margin-right: 10px;
}
.single-product .wcsatt-options-product-wrapper ul.wcsatt-options-product,
.single-product .wcsatt-options-product-wrapper .wcsatt-options-product-dropdown {
  margin: 0 0 20px 0;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 16px;
}

.woocommerce div.product.product_cat-brewery-tour .entry-summary form .qty {
  margin-bottom: 10px;
}
.woocommerce div.product.product_cat-brewery-tour .entry-summary form .variations {
  margin-bottom: 0;
}
.woocommerce div.product.product_cat-brewery-tour .entry-summary form .variations td {
  display: block;
  line-height: normal;
}
.woocommerce div.product.product_cat-brewery-tour .entry-summary form .variations label {
  width: 100%;
  margin-bottom: 10px;
}
.woocommerce div.product.product_cat-brewery-tour .entry-summary form .variations #booking-option {
  line-height: 25px;
  padding: 5px 15px;
  width: 100%;
}
.woocommerce div.product.product_cat-brewery-tour .entry-summary form .wcpa_form_outer {
  margin: 0;
}
.woocommerce div.product.product_cat-brewery-tour .entry-summary form .wcpa_form_outer .wcpa_form_item {
  margin-top: 0;
}
.woocommerce div.product.product_cat-brewery-tour .entry-summary form .wcpa_form_outer .wcpa_form_item label {
  display: block !important;
  width: 100% !important;
  margin-bottom: 10px !important;
}

.product-template-default.single.single-product.theme-standard.woocommerce.woocommerce-page div[role=main] {
  margin-top: 30px;
}

.woocommerce-error li, .woocommerce-info li, .woocommerce-message li {
  text-align: left;
  padding: 5px 0;
}

.woocommerce-content-wrapper {
  width: 1088px;
  max-width: 90vw !important;
  margin: 0 auto;
  padding-top: 2rem;
}
@media (min-width: 56.25em) {
  .woocommerce-content-wrapper {
    padding-top: 0px;
  }
}
.woocommerce-content-wrapper .type-product {
  min-height: 500px !important;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
@media (min-width: 56.25em) {
  .woocommerce-content-wrapper .type-product {
    flex-direction: row;
  }
}
.woocommerce-content-wrapper .woocommerce-product-gallery {
  float: unset !important;
}
.woocommerce-content-wrapper .summary {
  float: unset !important;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.woocommerce-content-wrapper .woocommerce-breadcrumb {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
}
.woocommerce-content-wrapper .woocommerce-breadcrumb a {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
}
.woocommerce-content-wrapper .cart {
  float: right;
}
.woocommerce-content-wrapper .cart a {
  line-height: 31px;
  display: flex;
  align-items: center;
  text-decoration: none;
}
.woocommerce-content-wrapper .cart img {
  height: 25px;
}
.woocommerce-content-wrapper .button,
.woocommerce-content-wrapper a.button.product-page,
.woocommerce-content-wrapper .button.single_add_to_cart_button {
  background: #B9975B !important;
  min-width: 160px !important;
  font-family: "objektiv-mk1", sans-serif !important;
  float: unset !important;
  margin: 0px !important;
  padding: 20px !important;
  text-transform: uppercase !important;
  margin-right: 15px !important;
  height: 50px !important;
  line-height: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
}
.woocommerce-category-wrapper {
  background: #FFF7EB;
  width: 1088px;
  max-width: 90vw !important;
  margin: 0 auto;
  padding-top: 2rem;
}
@media (min-width: 56.25em) {
  .woocommerce-category-wrapper {
    padding-top: 0px;
  }
}
.woocommerce-category-wrapper .woocommerce-wrap {
  background: #FFF7EB;
}
.woocommerce-category-wrapper .type-product {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  color: #2D2926;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 1rem;
}
@media (min-width: 56.25em) {
  .woocommerce-category-wrapper .type-product {
    flex-direction: row;
  }
}
.woocommerce-category-wrapper .type-product .woocommerce-loop-product__link {
  color: #2D2926 !important;
}
.woocommerce-category-wrapper .block.woocommerce-wrap ul.products li {
  display: flex;
  justify-content: center;
}
.woocommerce-category-wrapper .woocommerce-breadcrumb {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
}
.woocommerce-category-wrapper .woocommerce-breadcrumb a {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
}
.woocommerce-category-wrapper .button,
.woocommerce-category-wrapper a.button.product-page,
.woocommerce-category-wrapper .button.single_add_to_cart_button {
  background: #B9975B !important;
  min-width: 160px !important;
  font-family: "objektiv-mk1", sans-serif !important;
  float: unset !important;
  margin: 0px !important;
  padding: 20px !important;
  text-transform: uppercase !important;
  height: 50px !important;
  line-height: 20px !important;
  display: flex !important;
  align-items: center !important;
  justify-content: center !important;
  color: #FFF !important;
}
.woocommerce-generic-wrapper > .woocommerce {
  width: 1088px;
  max-width: 90vw !important;
  margin: 0 auto;
}

.buttons-row {
  display: flex;
  justify-content: center;
}
.buttons-row a {
  display: inline-block;
  border-radius: 2px;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0px;
  line-height: 1;
  white-space: normal;
  padding: 11px;
  text-align: center;
  word-break: break-word;
  cursor: pointer;
  text-transform: uppercase;
  text-decoration: none;
  font-family: "objektiv-mk1", sans-serif;
}
.buttons-row a:not(:last-of-type) {
  margin-right: 20px;
}

.find-a-pub-filter-main .button {
  background-color: #c3a76d !important;
  padding: 5px 20px 9px !important;
  max-width: 100%;
  display: inline-block !important;
  width: auto;
  height: auto;
  line-height: normal !important;
  margin-top: 32px;
}
.find-a-pub-filter-main .find-a-pub-filter {
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  margin: auto;
}
@media (min-width: 56.25em) {
  .find-a-pub-filter-main .find-a-pub-filter {
    width: calc(100% - 180px);
    flex-direction: row;
  }
}
.find-a-pub-filter-main .find-a-pub-filter .section_wrapper {
  margin-bottom: 40px;
}
.find-a-pub-filter-main .find-a-pub-filter .section_wrapper:last-of-type {
  margin-bottom: 10px;
}
@media (min-width: 56.25em) {
  .find-a-pub-filter-main .find-a-pub-filter .section_wrapper {
    margin-bottom: 0;
  }
}
.find-a-pub-filter-main .find-a-pub-filter .section_wrapper .section-heading {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 2px;
  font-size: 24px;
  line-height: 30px;
  margin-bottom: 20px;
  display: block;
  width: 100%;
  text-align: center;
}
@media (min-width: 56.25em) {
  .find-a-pub-filter-main .find-a-pub-filter .section_wrapper .section-heading {
    text-align: left;
  }
}
.find-a-pub-filter-main .find-a-pub-filter .section_wrapper .properties-main-wrap {
  padding: 0;
}
@media (min-width: 56.25em) {
  .find-a-pub-filter-main .find-a-pub-filter .section_wrapper .properties-main-wrap {
    width: 32vw;
    padding-right: 5vw;
  }
}
.find-a-pub-filter-main .find-a-pub-filter .section_wrapper .properties-main-wrap .buttons-row {
  display: flex;
  flex-direction: column;
  width: 100%;
}
.find-a-pub-filter-main .find-a-pub-filter .section_wrapper .properties-main-wrap .buttons-row > * {
  width: 100%;
}
.find-a-pub-filter-main .find-a-pub-filter .section_wrapper .properties-main-wrap .buttons-row .input-div-pubsearch {
  display: flex;
  padding: 0;
  border-radius: 5px;
}
.find-a-pub-filter-main .find-a-pub-filter .section_wrapper .properties-main-wrap .buttons-row .input-div-pubsearch input#postcode-search-input {
  width: 100%;
  padding: 10px;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 16px;
  border: none;
  border-top-left-radius: 5px;
  border-bottom-left-radius: 5px;
}
.find-a-pub-filter-main .find-a-pub-filter .section_wrapper .properties-main-wrap .buttons-row .input-div-pubsearch .find-by-name {
  max-width: 100%;
  display: inline-block !important;
  margin-top: 0;
  border-radius: 0;
  white-space: nowrap;
  padding: 12px 20px !important;
  border-top-right-radius: 5px;
  border-bottom-right-radius: 5px;
}
.find-a-pub-filter-main .find-a-pub-filter .section_wrapper .properties-main-wrap .buttons-row .button {
  background-color: #c3a76d !important;
  max-width: 100%;
  display: inline-block !important;
  border-radius: 5px;
  white-space: nowrap;
  padding: 12px 20px !important;
  font-size: 16px;
}
.find-a-pub-filter-main .find-a-pub-filter .section_wrapper .properties-main-wrap .buttons-row #location-selector {
  color: #FFF !important;
  border: 1px solid #c3a76d !important;
  text-decoration: none !important;
  font-size: 14px !important;
  cursor: pointer;
  text-transform: uppercase;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 700;
  letter-spacing: 0;
  text-align: center;
  -moz-appearance: none;
  /* Firefox */
  -webkit-appearance: none;
  /* Safari and Chrome */
  appearance: none;
  background-image: url("/content/themes/standard/images/white_triangle_down.png");
  background-repeat: no-repeat;
  background-position-x: 95%;
  background-position-y: 50%;
}
.find-a-pub-filter-main .find-a-pub-filter .section_wrapper .properties-main-wrap .pubhotel-alert {
  padding: 20px;
  background-color: #B9975B;
  color: white;
  margin: 20px 0;
  border-radius: 5px;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 16px;
  display: none;
}
.find-a-pub-filter-main .find-a-pub-filter .section_wrapper .properties-main-wrap .pubhotel-alert .closebtn {
  margin-left: 15px;
  color: white;
  font-weight: bold;
  float: right;
  font-size: 22px;
  line-height: 20px;
  cursor: pointer;
  transition: 0.3s;
}
.find-a-pub-filter-main .find-a-pub-filter .section_wrapper .properties-main-wrap .pubhotel-searching {
  width: 100%;
  display: none;
  margin: 20px 0;
}
.find-a-pub-filter-main .find-a-pub-filter .section_wrapper .properties-main-wrap .pubhotel-searching .loader {
  border: 5px solid #B9975B !important;
  border-radius: 50%;
  border-top: 5px solid #231f20 !important;
  width: 32px;
  height: 32px;
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  float: left;
}
.find-a-pub-filter-main .find-a-pub-filter .section_wrapper .properties-main-wrap .pubhotel-searching span {
  line-height: 32px;
  display: inline-block;
  vertical-align: middle;
  margin-left: 10px;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 16px;
  color: #231f20;
}
.find-a-pub-filter-main .find-a-pub-filter .section_wrapper .properties-main-wrap .pubhotel-searching .pubhotel-text-center {
  text-align: center;
  max-width: 320px;
  margin: 0 auto;
}
.find-a-pub-filter-main .find-a-pub-filter .sub-heading {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  margin: 0;
  letter-spacing: 2px;
  font-size: 25px;
  line-height: 28px;
  margin-bottom: 15px;
}
.find-a-pub-filter-main .find-a-pub-filter .filters-buttons-area {
  display: flex;
  justify-content: center;
  align-items: center;
  max-width: 100%;
  flex-wrap: wrap;
}
@media (min-width: 56.25em) {
  .find-a-pub-filter-main .find-a-pub-filter .filters-buttons-area {
    justify-content: flex-start;
  }
}
.find-a-pub-filter-main .find-a-pub-filter .filters-buttons-area .filter-button {
  display: block;
  background-color: white;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  font-size: 14px;
  line-height: 14px;
  letter-spacing: 2px;
  margin: 0;
  margin-bottom: 15px;
  margin-right: 15px;
  margin-left: 15px;
  padding: 12px 17px 11px;
  border-radius: 5px;
  cursor: pointer;
}
@media (min-width: 56.25em) {
  .find-a-pub-filter-main .find-a-pub-filter .filters-buttons-area .filter-button {
    margin-left: 0;
  }
}
.find-a-pub-filter-main .find-a-pub-filter .filters-buttons-area .filter-button.active {
  background-color: #B9975B;
}

#map-canvas {
  height: 50vh;
  margin-bottom: 30px;
}

#tenanted_pubhotel > li.hidden,
#pubhotel_search_result > li.hidden {
  display: none;
}

.post_content_wrapper {
  margin: 20px auto;
  max-width: 80vw;
}
@media (min-width: 56.25em) {
  .post_content_wrapper {
    max-width: 50vw;
  }
}

.flipbook_content_wrapper {
  max-width: 80vw !important;
}
@media (min-width: 56.25em) {
  .flipbook_content_wrapper {
    margin: auto auto !important;
    max-width: 100vw !important;
  }
}

body.page-template-template-pubs-rooms div[role=main] .block.standard-text-block:first-of-type {
  padding-top: 20px;
}

body.page-template-template-second-level-property div[role=main] .block.standard-text-block:first-of-type,
body.page-template-template-pubs-rooms div[role=main] .block.standard-text-block:first-of-type {
  padding-bottom: 0;
}
body.page-template-template-second-level-property div[role=main] .block.standard-text-block:first-of-type > h2,
body.page-template-template-pubs-rooms div[role=main] .block.standard-text-block:first-of-type > h2 {
  line-height: normal;
}
.side_popup {
  position: fixed;
  overflow: hidden;
  z-index: 1;
  background: #2D2926;
  border-radius: 5px;
  transition: right 2s;
  right: -300px;
  max-width: 100px;
  bottom: 3px;
}
@media (min-width: 34.375em) {
  .side_popup {
    max-width: unset;
    bottom: 20px;
  }
}
.side_popup .cnt {
  display: block;
  width: 100%;
  height: 100%;
  color: #B9975B;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 700;
  text-transform: uppercase;
  letter-spacing: 2px;
  text-decoration: none;
  padding: 20px 10px 15px 20px;
  font-size: 12px;
  line-height: 18px;
}
@media (min-width: 34.375em) {
  .side_popup .cnt {
    padding: 30px;
    font-size: 20px;
    line-height: 20px;
  }
}
.side_popup .close-popup {
  color: #B9975B;
  position: absolute;
  top: 5px;
  left: 5px;
  cursor: pointer;
}
.side_popup .close-popup::after {
  content: "✕";
}

.find-a-pub-search-banner {
  max-width: 1920px;
  width: 100vw;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (min-width: 68.75em) {
  .find-a-pub-search-banner {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.find-a-pub-search-banner .content-wrapper {
  width: 100%;
  max-width: 1088px;
  margin: 0 auto;
  padding: 1.5rem;
  background: #FFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}
@media (min-width: 56.25em) {
  .find-a-pub-search-banner .content-wrapper {
    flex-direction: row;
    gap: 1rem;
  }
}
.find-a-pub-search-banner .content-wrapper .input-wrapper {
  max-width: 100%;
  background: #FFF;
  border: 2px solid #B9975B;
  border-radius: 8px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
@media (min-width: 56.25em) {
  .find-a-pub-search-banner .content-wrapper .input-wrapper {
    width: 37%;
  }
}
@media (min-width: 56.25em) {
  .find-a-pub-search-banner .content-wrapper .input-wrapper.cinema {
    width: 17%;
  }
}
.find-a-pub-search-banner .content-wrapper .input-wrapper .banner-search-field,
.find-a-pub-search-banner .content-wrapper .input-wrapper .banner-search-field-text {
  width: calc(100% - 36px);
  height: 36px;
  background: transparent;
  border-radius: 8px !important;
  color: #231f20;
  text-decoration: none;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 14px;
  font-weight: 300;
  border: 0px;
  outline: 0px;
  padding: 0px 10px;
}
.find-a-pub-search-banner .content-wrapper .input-wrapper .banner-search-btn {
  height: 36px;
  width: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.find-a-pub-search-banner .content-wrapper .input-wrapper .banner-search-btn img {
  height: 16px;
  width: 16px;
}
.find-a-pub-search-banner .content-wrapper .heading {
  max-width: 35%;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  text-align: center;
  letter-spacing: normal;
  font-size: 20px;
}
@media (min-width: 34.375em) {
  .find-a-pub-search-banner .content-wrapper .heading {
    font-size: 24px;
  }
}
.find-a-pub-search-banner .content-wrapper .content {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: normal;
  text-align: center;
  letter-spacing: normal;
  font-size: 16px;
}
@media (min-width: 34.375em) {
  .find-a-pub-search-banner .content-wrapper .content {
    font-size: 19px;
  }
}
.find-a-pub-search-banner .content-wrapper .select-wrapper {
  border: 2px solid #B9975B;
  border-radius: 8px !important;
  width: 100%;
}
@media (min-width: 56.25em) {
  .find-a-pub-search-banner .content-wrapper .select-wrapper {
    width: 20%;
  }
}
.find-a-pub-search-banner .content-wrapper .select-wrapper select {
  height: 36px;
  width: 100%;
  background: #FFF7EB;
  border-radius: 8px !important;
  color: #231f20;
  text-decoration: none;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 14px;
  font-weight: 300;
  border: 0px;
  outline: 0px;
  padding: 0px 10px;
}
.find-a-pub-search-banner .content-wrapper .button-wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 0.75rem;
  min-width: 155px;
  width: 100%;
}
@media (min-width: 56.25em) {
  .find-a-pub-search-banner .content-wrapper .button-wrapper {
    margin-top: 0px;
    width: 15%;
  }
}
.find-a-pub-search-banner .content-wrapper .button-wrapper .cta-button {
  background: #B9975B;
  height: 46px;
  width: 100%;
  max-width: 176px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 25px) 50%;
  padding: 0px 29px 0px 25px;
}
.find-a-pub-search-banner .content-wrapper .button-wrapper .cta-button:hover {
  background: #2D2926;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 25px) 50%;
  padding: 0px 29px 0px 25px;
}
.find-a-pub-search-banner .content-wrapper .button-wrapper .cta-button.disabled {
  background: #898F9A;
}

.pubs_list_autocomplete.ui-widget.ui-widget-content {
  border: 1px solid #c3a76d;
  background-color: white;
  font-family: "objektiv-mk1", sans-serif;
  border-radius: 4px;
}
.pubs_list_autocomplete.ui-widget.ui-widget-content .ui-menu-item-wrapper {
  font-size: 15px;
}

.four-column-image-block {
  position: relative;
  margin: 0px;
  padding: 0px 0px 0px 0px;
}
.four-column-image-block .section__shell {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1088px;
  margin: 0 auto;
}
.four-column-image-block .section__content {
  position: relative;
  margin: auto auto;
  width: 100%;
}
.four-column-image-block .section__content .section_heading {
  width: 100%;
  text-align: center;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 24px;
  font-weight: 800 !important;
  line-height: 50px;
  color: #B9975B;
  letter-spacing: normal;
  margin-bottom: 1rem;
}
.four-column-image-block .section__content .four-column-image-block-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0.75rem;
  margin-left: 1rem;
  margin-right: 1rem;
}
@media (min-width: 68.75em) {
  .four-column-image-block .section__content .four-column-image-block-list {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.four-column-image-block .section__content .four-column-image-block-list .section__column {
  margin-bottom: 0.75rem;
  width: calc(50% - 0.4rem);
}
@media (min-width: 34.375em) {
  .four-column-image-block .section__content .four-column-image-block-list .section__column {
    width: calc(25% - 0.6rem);
    max-width: 260px;
  }
}
.four-column-image-block .section__content .four-column-image-block-list .section__column .section__inner {
  position: relative;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  width: 100%;
  height: 126px;
}
@media (min-width: 34.375em) {
  .four-column-image-block .section__content .four-column-image-block-list .section__column .section__inner {
    height: 194px;
  }
}
.four-column-image-block .section__content .four-column-image-block-list .section__column .section__inner .image-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.four-column-image-block .section__content .four-column-image-block-list .section__column .section__inner .image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.four-column-image-block .section__content .four-column-image-block-list .section__column .section__inner .card-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}
.four-column-image-block .section__content .four-column-image-block-list .section__column .section__inner .card-wrapper .heading {
  width: calc(100% - 1.5rem);
  position: absolute;
  bottom: 0.75rem;
  font-family: "objektiv-mk1", sans-serif;
  font-style: normal;
  font-weight: 800;
  color: #FFF;
  text-transform: uppercase;
  font-size: 18px;
  line-height: 23px;
  text-align: left;
}
@media (min-width: 34.375em) {
  .four-column-image-block .section__content .four-column-image-block-list .section__column .section__inner .card-wrapper .heading {
    font-size: 19px;
    line-height: 34px;
    text-align: center;
  }
}
.four-column-image-block .section__content .four-column-image-block-list .section__column .section__outer {
  position: relative;
}
.four-column-image-block .section__content .four-column-image-block-list .section__column .section__outer .subtitle {
  width: 100%;
  text-align: center;
  font-family: "objektiv-mk1", sans-serif;
  font-style: normal;
  font-weight: 800;
  line-height: 15px;
  color: #2D2926;
  text-transform: uppercase;
  margin-top: 0.5rem;
  font-size: 12px;
}
@media (min-width: 34.375em) {
  .four-column-image-block .section__content .four-column-image-block-list .section__column .section__outer .subtitle {
    margin-top: 1rem;
    font-size: 16px;
  }
}

.full-width-banner-ads-section {
  max-width: 1920px;
  width: 100vw;
}
.full-width-banner-ads-section .content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}
.full-width-banner-ads-section .content-wrapper .icon-wrapper {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}
.full-width-banner-ads-section .content-wrapper .card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 0px 0px;
}
@media (min-width: 34.375em) {
  .full-width-banner-ads-section .content-wrapper .card-wrapper {
    margin: 1rem;
  }
}
.full-width-banner-ads-section .content-wrapper .card-wrapper .heading {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  text-align: center;
  font-size: 19px;
}
@media (min-width: 34.375em) {
  .full-width-banner-ads-section .content-wrapper .card-wrapper .heading {
    font-size: 30px;
  }
}
.full-width-banner-ads-section .content-wrapper .card-wrapper .content {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: normal;
  text-align: center;
  font-size: 16px;
}
@media (min-width: 34.375em) {
  .full-width-banner-ads-section .content-wrapper .card-wrapper .content {
    font-size: 19px;
    line-height: 31px;
  }
}
.full-width-banner-ads-section .content-wrapper .card-wrapper .content b {
  font-weight: 800;
}

.global-contact-us-button {
  position: fixed;
  right: 10px;
  bottom: 0px;
  z-index: 20;
  cursor: pointer;
}
.global-contact-us-button .desktop {
  display: none;
}
.global-contact-us-button .mobile {
  display: block;
}
@media (min-width: 34.375em) {
  .global-contact-us-button .desktop {
    display: block;
  }
  .global-contact-us-button .mobile {
    display: none;
  }
}

#global-contact-us-popup {
  overflow: hidden;
  position: fixed;
  border-radius: 8px;
  border: 3px solid #B9975B;
  right: 10px;
  bottom: 10px;
  z-index: 1;
  height: 0px;
  width: 350px;
  opacity: 0;
  transition: all 0.5s linear;
}
#global-contact-us-popup .popup-close-btn {
  position: absolute;
  top: 15px;
  right: 15px;
  color: #B9975B;
}
#global-contact-us-popup iframe {
  border-radius: 4px;
}
#global-contact-us-popup.active {
  z-index: 30;
  height: 550px;
  opacity: 1;
}

.global-floating-cta-form {
  position: fixed;
  left: 0px;
  bottom: 0px;
  background: #2D2926;
  z-index: 20;
  min-width: 215px;
  padding: 20px 10px;
}
@media (min-width: 34.375em) {
  .global-floating-cta-form {
    padding: 30px;
  }
}
.global-floating-cta-form.active {
  transition: all 0.5s linear;
  padding: 10px 10px 5px 10px;
}
@media (min-width: 34.375em) {
  .global-floating-cta-form.active {
    padding: 10px 30px 5px 30px;
  }
}
.global-floating-cta-form.active .content-wrapper .content {
  transition: all 0.5s linear !important;
  line-height: 5px !important;
  height: 5px !important;
  margin-bottom: 0px !important;
  color: #2D2926 !important;
}
.global-floating-cta-form.active .content-wrapper .cta-button {
  transition: all 0.5s linear !important;
  display: none !important;
}
.global-floating-cta-form.active .form-close-btn {
  display: none;
}
.global-floating-cta-form.active .form-open-btn {
  display: block;
}
.global-floating-cta-form .form-close-btn,
.global-floating-cta-form .form-open-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  cursor: pointer;
  height: 18px;
  width: 18px;
}
.global-floating-cta-form .form-open-btn {
  display: none;
  rotate: -90deg;
}
.global-floating-cta-form .content-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.global-floating-cta-form .content-wrapper .heading {
  color: #B9975B;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 18px;
  font-weight: 800;
  line-height: 22px;
  text-transform: uppercase;
}
.global-floating-cta-form .content-wrapper .content {
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 15px;
  font-weight: 800;
  line-height: 22px;
  margin-bottom: 1rem;
  text-transform: uppercase;
}
.global-floating-cta-form .content-wrapper .cta-button {
  background: transparent;
  border: 1px solid #B9975B;
  height: 30px;
  width: 157px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 11px;
  font-weight: 300;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 14px 8px;
  background-position: calc(100% - 10px) 50%;
  padding-right: 20px;
}
.global-floating-cta-form .content-wrapper .cta-button:hover {
  background: #B9975B;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 14px 8px;
  background-position: calc(100% - 10px) 50%;
  padding-right: 20px;
}

.flexible-hero-block {
  position: relative;
  width: 100%;
  max-width: 100vw;
  height: 100vh;
  max-height: 1920px;
}
.flexible-hero-block.half_height {
  height: 684px;
}
@media (min-width: 56.25em) {
  .flexible-hero-block.half_height {
    height: 452px;
  }
}
.flexible-hero-block .hero-block-overlay {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.3);
}
.flexible-hero-block .hero-block-wrapper {
  position: relative;
  width: 100%;
  height: 100%;
}
.flexible-hero-block .hero-block-wrapper .hero-bg-wrap {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-repeat: no-repeat;
  background-size: cover;
  background-position: 50% 50%;
}
.flexible-hero-block .hero-block-wrapper .hero-bg-wrap .hero-inner {
  width: 100%;
  max-width: 1088px;
  margin: 0 auto;
  min-height: 274px;
  z-index: 10;
  padding: 1.25rem;
}
@media (min-width: 68.75em) {
  .flexible-hero-block .hero-block-wrapper .hero-bg-wrap .hero-inner {
    padding: 0px;
  }
}
.flexible-hero-block .hero-block-wrapper .hero-bg-wrap .hero-inner .heading {
  width: 100%;
  font-size: 36px;
  margin-bottom: 1rem;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  line-height: 50px;
}
@media (min-width: 34.375em) {
  .flexible-hero-block .hero-block-wrapper .hero-bg-wrap .hero-inner .heading {
    width: 50%;
    font-size: 45px;
    margin-bottom: 1.5rem;
  }
}
.flexible-hero-block .hero-block-wrapper .hero-bg-wrap .hero-inner .content {
  width: 100%;
  font-size: 18px;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  line-height: 31px;
  margin-bottom: 2rem;
}
@media (min-width: 34.375em) {
  .flexible-hero-block .hero-block-wrapper .hero-bg-wrap .hero-inner .content {
    width: 50%;
    font-size: 21px;
  }
}
.flexible-hero-block .hero-block-wrapper .hero-bg-wrap .hero-inner .cta-button {
  background: #B9975B;
  height: 46px;
  width: 237px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 25px) 50%;
  padding: 0px 29px 0px 25px;
}
.flexible-hero-block .hero-block-wrapper .hero-bg-wrap .hero-inner .cta-button:hover {
  background: #2D2926;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 25px) 50%;
  padding: 0px 29px 0px 25px;
}
.flexible-hero-block .find-a-pub-search-banner {
  position: absolute !important;
  z-index: 11;
  bottom: 40px;
}
@media (min-width: 56.25em) {
  .flexible-hero-block .find-a-pub-search-banner {
    bottom: 60px;
  }
}
.flexible-hero-block .find-a-pub-search-banner .card-wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  margin: 1rem 0px;
}
@media (min-width: 56.25em) {
  .flexible-hero-block .find-a-pub-search-banner .card-wrapper {
    margin: 1rem 2rem;
  }
}
.flexible-hero-block .find-a-pub-search-banner .card-wrapper .heading {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  text-align: center;
  color: #FFF;
  letter-spacing: normal;
  line-height: 41px;
  font-size: 36px;
}
@media (min-width: 34.375em) {
  .flexible-hero-block .find-a-pub-search-banner .card-wrapper .heading {
    font-size: 30px;
  }
}
.flexible-hero-block .find-a-pub-search-banner .card-wrapper .content {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: normal;
  text-align: center;
  color: #FFF;
  letter-spacing: normal;
  font-size: 17px;
  line-height: 26px;
}
@media (min-width: 34.375em) {
  .flexible-hero-block .find-a-pub-search-banner .card-wrapper .content {
    font-size: 19px;
    line-height: 31px;
  }
}
.flexible-hero-block .find-a-pub-search-banner .card-wrapper .content b {
  font-weight: 800;
}

.instagram-feed-block {
  position: relative;
  margin: 0px;
  padding: 0px 0px 0px 0px;
}
.instagram-feed-block .section__shell {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1088px;
  margin: 0 auto;
}
.instagram-feed-block .section__content {
  position: relative;
  margin: auto auto;
  width: 100%;
}
.instagram-feed-block .section__content .section_heading {
  width: 100%;
  text-align: center;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800 !important;
  line-height: 50px;
  color: #B9975B;
  letter-spacing: normal;
  margin-bottom: 1rem;
  font-size: 20px;
}
@media (min-width: 34.375em) {
  .instagram-feed-block .section__content .section_heading {
    font-size: 24px;
  }
}
.instagram-feed-block .section__content .instagram-feed-block-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  gap: 0.5rem;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-bottom: 0.5rem;
}
@media (min-width: 68.75em) {
  .instagram-feed-block .section__content .instagram-feed-block-list {
    margin-left: 0px;
    margin-right: 0px;
    gap: 0.75rem;
    margin-bottom: 0.75rem;
  }
}
.instagram-feed-block .section__content .instagram-feed-block-list .section__column {
  width: 33.33%;
  height: 106px;
  max-width: 106px;
}
@media (min-width: 34.375em) {
  .instagram-feed-block .section__content .instagram-feed-block-list .section__column {
    width: 16%;
    height: 168px;
    max-width: 168px;
  }
}
.instagram-feed-block .section__content .instagram-feed-block-list .section__column .section__inner {
  position: relative;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  height: 100%;
  width: 100%;
}
.instagram-feed-block .section__content .instagram-feed-block-list .section__column .section__inner .image-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.instagram-feed-block .section__content .instagram-feed-block-list .section__column .section__inner .image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}

.link-buttons-block {
  position: relative;
  margin: 0px;
  padding: 0px;
  background: #2D2926;
}
.link-buttons-block .section__shell {
  position: relative;
  width: 100%;
  max-width: 1088px;
  margin: 0 auto;
}
.link-buttons-block .section__content {
  position: relative;
  margin: auto auto;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 81.25em) {
  .link-buttons-block .section__content {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.link-buttons-block .section__content .section-heading {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 24px;
  color: #B9975B;
  line-height: 50px;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 1rem;
}
.link-buttons-block .section__content .link-buttons-block-flexbox {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 0.75rem;
}
.link-buttons-block .section__content .section__column {
  width: calc(50% - 0.4rem) !important;
}
@media (min-width: 34.375em) {
  .link-buttons-block .section__content .section__column {
    width: 168px !important;
  }
}
.link-buttons-block .section__content .section__column .section__inner {
  width: 100%;
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5rem;
  border: 2px solid #B9975B;
  border-radius: 8px;
  cursor: pointer;
}
.link-buttons-block .section__content .section__column .section__inner:hover {
  background: #B9975B;
}
.link-buttons-block .section__content .section__column .section__inner .card-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
}
.link-buttons-block .section__content .section__column .section__inner .card-wrapper .heading {
  letter-spacing: 1.5px;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 12px;
  line-height: 15px;
  color: #FFF;
  text-transform: uppercase;
  text-decoration: none;
}

.locations-search-banner {
  max-width: 1920px;
  width: 100vw;
  padding-top: 0px;
  padding-bottom: 0px;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 68.75em) {
  .locations-search-banner {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.locations-search-banner .content-wrapper {
  width: 100%;
  max-width: 1088px;
  margin: 0 auto;
  gap: 1rem;
  padding: 1.5rem 2rem 2rem 2rem;
  background: #FFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.locations-search-banner .content-wrapper .heading {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  text-align: center;
  letter-spacing: normal;
  font-size: 20px;
}
@media (min-width: 34.375em) {
  .locations-search-banner .content-wrapper .heading {
    font-size: 24px;
  }
}
.locations-search-banner .content-wrapper .input-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
@media (min-width: 34.375em) {
  .locations-search-banner .content-wrapper .input-wrapper {
    flex-direction: row;
    justify-content: space-between;
  }
}
.locations-search-banner .content-wrapper .input-wrapper .select-wrapper {
  border: 2px solid #B9975B;
  border-radius: 8px !important;
  width: 100%;
}
@media (min-width: 34.375em) {
  .locations-search-banner .content-wrapper .input-wrapper .select-wrapper {
    width: calc(33.33% - 0.5rem);
  }
}
.locations-search-banner .content-wrapper .input-wrapper .select-wrapper select {
  height: 36px;
  width: 100%;
  background: #FFF7EB;
  border-radius: 8px !important;
  color: #231f20;
  text-decoration: none;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 14px;
  font-weight: 300;
  border: 0px;
  outline: 0px;
  padding: 0px 10px;
}

.post-type-search-block {
  position: relative;
  margin: 0px;
  padding: 0px;
}
.post-type-search-block .section__shell {
  position: relative;
  height: 100%;
  max-width: 1088px;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
}
.post-type-search-block .section__filter {
  position: relative;
  width: 100%;
}
.post-type-search-block .section__filter.desktop-version {
  display: none;
}
@media (min-width: 56.25em) {
  .post-type-search-block .section__filter.desktop-version {
    display: block;
  }
}
.post-type-search-block .section__filter.mobile-version {
  display: block;
}
@media (min-width: 56.25em) {
  .post-type-search-block .section__filter.mobile-version {
    display: none;
  }
}
.post-type-search-block .section__filter .search-heading {
  font-family: "objektiv-mk1", sans-serif;
  color: #B9975B;
  font-weight: 800;
  font-size: 24px;
  line-height: 50px;
  letter-spacing: normal;
  width: 100%;
  text-align: center;
}
.post-type-search-block .section__filter .search-filter {
  margin: 17px auto 0px auto;
  max-width: calc(100% - 1.5rem);
}
@media (min-width: 34.375em) {
  .post-type-search-block .section__filter .search-filter {
    max-width: calc(672px + 3rem);
  }
}
@media (min-width: 56.25em) {
  .post-type-search-block .section__filter .search-filter {
    max-width: calc(840px + 3.75rem);
  }
}
@media (min-width: 68.75em) {
  .post-type-search-block .section__filter .search-filter {
    max-width: calc(1008px + 4rem);
  }
}
.post-type-search-block .section__filter .search-filter .search-category-button-showmore {
  cursor: pointer;
  height: 36px;
  width: 100%;
  max-width: 168px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #2D2926;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-decoration: underline;
  text-transform: uppercase;
  padding: 0px;
  margin: 0 auto;
}
.post-type-search-block .section__filter .search-filter .search-category-button-showmore:hover {
  color: #B9975B;
}
.post-type-search-block .section__filter .search-filter .search-category-button {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 12px;
  letter-spacing: 1.5px;
  height: 48px;
  color: #2D2926;
  background: #FFF;
  border: 1px solid #EFE6D8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  width: calc(50% - 0.5rem);
}
@media (min-width: 34.375em) {
  .post-type-search-block .section__filter .search-filter .search-category-button {
    width: 168px;
  }
}
.post-type-search-block .section__filter .search-filter .search-category-button.selected, .post-type-search-block .section__filter .search-filter .search-category-button:hover {
  background: #2D2926;
  color: #FFF;
}
.post-type-search-block .section__filter .search-filter .search-category-button-group-container {
  width: 100%;
}
.post-type-search-block .section__filter .search-filter .search-category-button-group-container.hidden {
  display: none;
}
.post-type-search-block .section__filter .search-filter .search-category-button-group-container .search-category-button-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
}
.post-type-search-block .section__filter .search-filter .search-category-button-group-container .search-category-button-group.template-2 {
  gap: 0.75rem;
}
.post-type-search-block .section__filter .search-filter .search-category-button-group-container .search-category-button-group.template-2 .search-category-button {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 12px;
  letter-spacing: 1.5px;
  height: 24px;
  color: #2D2926;
  background: unset;
  border: 0px;
  border-radius: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: left;
  text-transform: uppercase;
  width: auto;
  padding-right: 0.75rem;
  border-right: 1px solid #2D2926;
}
.post-type-search-block .section__filter .search-filter .search-category-button-group-container .search-category-button-group.template-2 .search-category-button.selected, .post-type-search-block .section__filter .search-filter .search-category-button-group-container .search-category-button-group.template-2 .search-category-button:hover {
  color: #B9975B;
}
.post-type-search-block .section__content {
  position: relative;
  margin: 0 auto;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (min-width: 81.25em) {
  .post-type-search-block .section__content {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.post-type-search-block .section__content .section-heading {
  font-family: "objektiv-mk1", sans-serif;
  color: #B9975B;
  font-weight: 800;
  font-size: 24px;
  line-height: 50px;
  letter-spacing: normal;
  width: 100%;
  text-align: center;
  margin-top: 2rem;
  margin-bottom: 1rem;
}
.post-type-search-block .section__content .post-type-search-block-flexbox {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
@media (min-width: 46.875em) {
  .post-type-search-block .section__content .post-type-search-block-flexbox {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column {
  margin-bottom: 0.5rem;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}
@media (min-width: 46.875em) {
  .post-type-search-block .section__content .post-type-search-block-flexbox .section__column {
    width: 352px;
    max-width: calc(33.33% - 0.5rem);
  }
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column .section__inner {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column .section__inner .image-wrapper {
  position: relative;
  width: 100%;
  height: 230px;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column .section__inner .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column .section__inner .image-wrapper.filter-grayscale {
  transition: all 0.5s;
  filter: grayscale(1);
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column .section__inner .image-wrapper.filter-grayscale:hover {
  filter: grayscale(0);
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column .section__inner .image-wrapper .boutique-overlay {
  display: none;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column .section__inner .image-wrapper.is-boutique .boutique-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 35px;
  width: 157px;
  max-width: 100%;
  top: 0;
  left: 0;
  background: #B9975B;
  text-transform: uppercase;
  color: #FFF7EB;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 12px;
  letter-spacing: 1.5px;
  border-bottom-right-radius: 8px;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column .section__inner .card-wrapper {
  padding: 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column .section__inner .card-wrapper .heading {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 18px;
  line-height: 28px;
  text-transform: uppercase;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column .section__inner .card-wrapper .content {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  color: #2D2926;
  letter-spacing: normal;
  margin-top: 0.5rem;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column .section__inner .card-wrapper .content a {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  color: #2D2926 !important;
  text-decoration: none;
  letter-spacing: normal;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column .section__inner .card-wrapper .content a:hover {
  color: #B9975B !important;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column .section__inner .card-wrapper .content .cta-link {
  margin-top: 0.5rem;
  background: transparent;
  width: 100%;
  display: block;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  color: #2D2926 !important;
  text-decoration: none;
  letter-spacing: normal;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column .section__inner .card-wrapper .content .cta-link:hover {
  color: #B9975B !important;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column .section__inner .card-wrapper .find-out-more {
  display: block;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: bold;
  font-size: 12px;
  line-height: 22px;
  letter-spacing: 1.5px;
  text-transform: uppercase;
  padding-top: 0.5rem;
  color: #B9975B !important;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column .section__inner .card-wrapper .find-out-more:hover {
  color: #2D2926 !important;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column .section__inner .cta-button {
  margin-bottom: 0.5rem;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column.template-1 .section__inner .card-wrapper .cta-button {
  margin-top: 0.5rem;
  background: transparent;
  width: 100%;
  display: block;
  color: #B9975B;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column.template-1 .section__inner .card-wrapper .cta-button:hover {
  color: #2D2926;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column.template-1 .section__inner .card-wrapper .cta-button-wrapper {
  display: block;
  width: 100%;
  height: 36px;
  margin-top: 0.5rem;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column.template-1 .section__inner .card-wrapper .cta-room-button {
  background: #B9975B;
  height: 36px;
  width: 100%;
  max-width: 187px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 20px) 50%;
  padding: 0px 20px 0px 20px;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column.template-1 .section__inner .card-wrapper .cta-room-button:hover {
  background: #2D2926;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 20px) 50%;
  padding: 0px 20px 0px 20px;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column.template-1 .section__inner .card-wrapper .cta-table-button {
  background: #2D2926;
  height: 36px;
  width: 100%;
  max-width: 187px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 20px) 50%;
  padding: 0px 20px 0px 20px;
}
.post-type-search-block .section__content .post-type-search-block-flexbox .section__column.template-1 .section__inner .card-wrapper .cta-table-button:hover {
  background: #B9975B;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 20px) 50%;
  padding: 0px 20px 0px 20px;
}
.post-type-search-block .section__content .pager-container {
  font-family: "objektiv-mk1", sans-serif;
  font-size: 19px;
  margin-top: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
}
.post-type-search-block .section__content .pager-container .pager-button {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 43px;
  width: 43px;
  color: #B9975B;
  background: #FFF;
  border: 1px solid #B9975B;
  border-radius: 50%;
}
.post-type-search-block .section__content .pager-container .pager-button:hover {
  background: #B9975B;
  color: #FFF;
}
.post-type-search-block .section__content .pager-container .pager-button.pager-disabled {
  background: lightgray;
  border: 1px solid lightgray;
  color: #FFF;
}
.post-type-search-block .section__content .pager-container .pager-button.pager-disabled:hover {
  background: lightgray;
  border: 1px solid lightgray;
  color: #FFF;
}

ul.managed-list .pub-hotel {
  margin-right: 0px;
  margin-bottom: 0.75rem;
}
@media (min-width: 46.875em) {
  ul.managed-list .pub-hotel {
    margin-bottom: 0px;
  }
}
ul.managed-list li .property-details .content-heading {
  margin-top: 0px !important;
}
ul.managed-list li .property-details .content {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  color: #2D2926;
  letter-spacing: normal;
  margin-top: 0.5rem;
}
ul.managed-list li .property-details .content a {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  color: #2D2926 !important;
  text-decoration: none;
  letter-spacing: normal;
}
ul.managed-list li .property-details .content a:hover {
  color: #B9975B !important;
}
ul.managed-list li .property-details .content .cta-link {
  margin-top: 0.5rem !important;
  background: transparent !important;
  width: 100% !important;
  display: block !important;
  font-family: "objektiv-mk1", sans-serif !important;
  font-weight: 300 !important;
  font-size: 15px !important;
  line-height: 25px !important;
  color: #2D2926 !important;
  text-decoration: none !important;
  letter-spacing: normal !important;
}
ul.managed-list li .property-details .content .cta-link:hover {
  color: #B9975B !important;
}
ul.managed-list li .property-details .content-link {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  color: #2D2926 !important;
  text-decoration: none !important;
  letter-spacing: normal;
}
ul.managed-list li .property-details .content-link:hover {
  color: #B9975B !important;
}
ul.managed-list li .property-details .cta-link {
  margin-top: 0.5rem;
  background: transparent;
  width: 100%;
  display: block;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  color: #2D2926 !important;
  text-decoration: none;
  letter-spacing: normal;
}
ul.managed-list li .property-details .cta-link:hover {
  color: #B9975B !important;
}

.pub-maps-block {
  position: relative;
  margin: 0px;
  padding: 0px;
}
.pub-maps-block .section__shell {
  position: relative;
  height: 100%;
  max-width: 1088px;
  margin: 0 auto;
}
.pub-maps-block .section__shell .section-heading {
  font-family: "objektiv-mk1", sans-serif;
  color: #B9975B;
  font-size: 24px;
  font-weight: 800;
  line-height: 50px;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 24px;
  text-transform: uppercase;
}
.single-column-card-block {
  position: relative;
  margin: 0px;
}
.single-column-card-block .section__shell {
  position: relative;
  width: 100%;
  max-width: 1088px;
  margin: 0 auto;
}
.single-column-card-block .section__content {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0px 1rem !important;
}
@media (min-width: 34.375em) {
  .single-column-card-block .section__content {
    padding: 0px 75px !important;
  }
}
.single-column-card-block .section__content .media-wrapper {
  position: relative;
  text-align: center;
  overflow: hidden;
  width: 338px;
  height: 189px;
}
@media (min-width: 34.375em) {
  .single-column-card-block .section__content .media-wrapper {
    width: 1088px;
    height: 500px;
  }
}
.single-column-card-block .section__content .media-wrapper iframe {
  width: 100% !important;
  object-fit: cover;
}
.single-column-card-block .section__content .card-wrapper {
  width: 100% !important;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.single-column-card-block .section__content .card-wrapper .heading {
  letter-spacing: normal;
  text-align: center;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  color: #B9975B;
  font-size: 20px;
  line-height: 31px;
}
@media (min-width: 34.375em) {
  .single-column-card-block .section__content .card-wrapper .heading {
    font-size: 24px;
    line-height: 50px;
  }
}
.single-column-card-block .section__content .card-wrapper .content {
  width: 100%;
  max-width: 800px;
  text-align: center;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  margin-top: 0.75rem;
  font-size: 15px;
  line-height: 25px;
}
@media (min-width: 34.375em) {
  .single-column-card-block .section__content .card-wrapper .content {
    font-size: 19px;
    line-height: 29px;
  }
}
.single-column-card-block .section__content .card-wrapper .content p {
  margin-bottom: 17px;
}
.single-column-card-block .section__content .card-wrapper .content a {
  color: #FFF;
}
.single-column-card-block .section__content .card-wrapper .content a:hover {
  color: #B9975B;
}
.single-column-card-block .section__content .card-wrapper .cta-button {
  font-family: "objektiv-mk1", sans-serif;
  font-size: 17px;
  line-height: 39px;
  letter-spacing: normal;
  border-radius: 30px;
  min-width: 145px;
  text-decoration: none;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  background: #B9975B;
}
.single-column-card-block .section__content .card-wrapper .cta-button:hover {
  color: #B9975B;
  background: #FFF;
}

.single-column-full-image-cta-block {
  position: relative;
  margin: 0px;
  max-width: 1920px;
  width: 100vw;
}
.single-column-full-image-cta-block .section__shell {
  width: 100%;
  position: relative;
  max-height: 412px;
}
@media (min-width: 64.125em) {
  .single-column-full-image-cta-block .section__shell {
    max-height: 500px;
  }
}
.single-column-full-image-cta-block .section__overlay {
  width: 100%;
  height: 100%;
  position: absolute;
}
.single-column-full-image-cta-block .section__overlay img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.single-column-full-image-cta-block .section__content {
  max-width: 1088px;
  position: relative;
  width: 100%;
  min-height: 412px;
  max-height: 500px;
  margin: 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-left: 1rem;
  padding-right: 1rem;
}
@media (min-width: 68.75em) {
  .single-column-full-image-cta-block .section__content {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.single-column-full-image-cta-block .section__content.content-position-left {
  justify-content: flex-start;
}
.single-column-full-image-cta-block .section__content.content-position-center {
  justify-content: flex-start;
}
@media (min-width: 34.375em) {
  .single-column-full-image-cta-block .section__content.content-position-center {
    justify-content: center;
  }
}
.single-column-full-image-cta-block .section__content.content-position-center .card-wrapper {
  text-align: flex-start;
  align-items: flex-start;
}
@media (min-width: 34.375em) {
  .single-column-full-image-cta-block .section__content.content-position-center .card-wrapper {
    text-align: center;
    align-items: center;
  }
}
.single-column-full-image-cta-block .section__content .card-wrapper {
  z-index: 2;
  position: relative;
  right: 0px;
  width: 100%;
  padding-left: 1rem;
  padding-right: 1rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media (min-width: 64.125em) {
  .single-column-full-image-cta-block .section__content .card-wrapper {
    position: unset;
    right: unset;
    width: 40%;
    padding-left: 0px;
    padding-right: 0px;
  }
}
.single-column-full-image-cta-block .section__content .card-wrapper .heading {
  text-shadow: 0px 5px 35px black;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  color: #FFF;
  margin-bottom: 1rem;
  width: 100%;
  letter-spacing: normal;
  line-height: 50px;
  font-size: 24px;
}
@media (min-width: 34.375em) {
  .single-column-full-image-cta-block .section__content .card-wrapper .heading {
    line-height: 50px;
    font-size: 38px;
  }
}
.single-column-full-image-cta-block .section__content .card-wrapper .content {
  text-shadow: 0px 5px 35px black;
  font-family: "objektiv-mk1", sans-serif;
  color: #FFF;
  width: 100%;
  margin-bottom: 2rem;
  font-size: 17px;
  line-height: 27px;
}
@media (min-width: 34.375em) {
  .single-column-full-image-cta-block .section__content .card-wrapper .content {
    font-size: 19px;
    line-height: 29px;
  }
}
.single-column-full-image-cta-block .section__content .card-wrapper .cta-button {
  background: #B9975B;
  height: 46px;
  max-width: 320px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 15px) 50%;
  padding: 0px 29px 0px 25px;
}
.single-column-full-image-cta-block .section__content .card-wrapper .cta-button:hover {
  background: #2D2926;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 15px) 50%;
  padding: 0px 29px 0px 25px;
}

.standard-content-block {
  position: relative;
  margin: 0px;
}
.standard-content-block .section__shell {
  position: relative;
  padding-left: 0px;
  padding-right: 0px;
}
@media (min-width: 68.75em) {
  .standard-content-block .section__shell {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.standard-content-block .section__content {
  position: relative;
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px 1rem !important;
}
@media (min-width: 34.375em) {
  .standard-content-block .section__content {
    padding: 0px 75px !important;
  }
}
.standard-content-block .section__content .section-heading {
  font-family: "objektiv-mk1", sans-serif;
  color: #2D2926;
  font-weight: 800;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 24px;
  text-transform: uppercase;
  font-size: 36px;
  line-height: 41px;
}
@media (min-width: 34.375em) {
  .standard-content-block .section__content .section-heading {
    font-size: 38px;
    line-height: 50px;
  }
}
.standard-content-block .section__content .section-buttons {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-bottom: 3rem;
}
.standard-content-block .section__content .section-buttons .cta-button {
  background: #B9975B;
  height: 46px;
  width: 192px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 25px) 50%;
  padding: 0px 29px 0px 25px;
}
.standard-content-block .section__content .section-buttons .cta-button:hover {
  background: #2D2926;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 25px) 50%;
  padding: 0px 29px 0px 25px;
}
.standard-content-block .section__content .card-wrapper {
  width: 100% !important;
  margin-top: 20px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.standard-content-block .section__content .card-wrapper .content {
  font-family: "objektiv-mk1", sans-serif;
  text-align: left;
  width: 100%;
  max-width: 720px;
  margin-bottom: 17px;
  letter-spacing: normal;
  font-size: 15px;
  font-weight: 300;
  line-height: 25px;
}
@media (min-width: 34.375em) {
  .standard-content-block .section__content .card-wrapper .content {
    font-size: 17px;
    line-height: 27px;
  }
}
.standard-content-block .section__content .card-wrapper .content.full-width {
  max-width: 100%;
}
.standard-content-block .section__content .card-wrapper .content h1, .standard-content-block .section__content .card-wrapper .content h2 {
  font-family: "objektiv-mk1", sans-serif;
  color: #2D2926;
  font-weight: 800;
  letter-spacing: normal;
  text-align: center;
  text-transform: uppercase;
  font-size: 36px;
  line-height: 41px;
}
@media (min-width: 34.375em) {
  .standard-content-block .section__content .card-wrapper .content h1, .standard-content-block .section__content .card-wrapper .content h2 {
    font-size: 38px;
    line-height: 50px;
  }
}
.standard-content-block .section__content .card-wrapper .content h3, .standard-content-block .section__content .card-wrapper .content h4 {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  letter-spacing: normal;
  margin-bottom: 17px;
  font-size: 20px;
  line-height: 31px;
}
@media (min-width: 34.375em) {
  .standard-content-block .section__content .card-wrapper .content h3, .standard-content-block .section__content .card-wrapper .content h4 {
    font-size: 28px;
    line-height: 40px;
  }
}
.standard-content-block .section__content .card-wrapper .content p {
  margin-bottom: 17px;
}
.standard-content-block .section__content .card-wrapper .content a:hover {
  color: #B9975B;
}
.standard-content-block .section__content .card-wrapper .content .aligncenter {
  display: block;
  margin-left: auto;
  margin-right: auto;
}
.standard-content-block .section__content .card-wrapper .cta-button {
  font-family: "objektiv-mk1", sans-serif;
  font-size: 17px;
  line-height: 39px;
  letter-spacing: normal;
  border-radius: 30px;
  min-width: 145px;
  text-decoration: none;
  text-align: center;
  margin-top: 30px;
  margin-bottom: 30px;
  background: #B9975B;
  padding: 8px 16px;
}
.standard-content-block .section__content .card-wrapper .cta-button:hover {
  color: #B9975B;
  background: #FFF;
}
.standard-content-block .section__content .card-wrapper .socials-heading {
  font-family: "objektiv-mk1", sans-serif;
  line-height: 40px;
  font-size: 34px;
  letter-spacing: -0.4px;
  margin-bottom: 30px;
  text-align: center;
}
.standard-content-block .section__content .card-wrapper .socials {
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: flex-end;
}
.standard-content-block .section__content .card-wrapper .socials a {
  text-align: center;
  min-width: 40px;
  color: white;
  font-size: 27px;
}
.standard-content-block .section__content .card-wrapper .socials a:hover {
  color: #B9975B;
}

.three-column-image-block {
  position: relative;
  margin: 0px;
  padding: 0px 0px 0px 0px;
}
.three-column-image-block .section__shell {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1088px;
  margin: 0 auto;
}
.three-column-image-block .section__content {
  position: relative;
  margin: auto auto;
  width: 100%;
}
.three-column-image-block .section__content .section_heading {
  width: 100%;
  text-align: center;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 24px;
  font-weight: 800 !important;
  line-height: 50px;
  color: #B9975B;
  letter-spacing: normal;
  margin-bottom: 1rem;
}
.three-column-image-block .section__content .three-column-image-block-list {
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  margin-right: 1rem;
}
@media (min-width: 34.375em) {
  .three-column-image-block .section__content .three-column-image-block-list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    gap: 0.75rem;
  }
}
@media (min-width: 68.75em) {
  .three-column-image-block .section__content .three-column-image-block-list {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.three-column-image-block .section__content .three-column-image-block-list .section__column {
  margin-bottom: 0.75rem;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  height: 260px;
  width: 100%;
}
@media (min-width: 34.375em) {
  .three-column-image-block .section__content .three-column-image-block-list .section__column {
    height: 300px;
    width: calc(33.3% - 0.5rem);
    max-width: 352px;
  }
}
.three-column-image-block .section__content .three-column-image-block-list .section__column:hover .section__inner .card-wrapper .heading {
  background-image: url("../images/butcombe-btn-arrow.svg");
}
.three-column-image-block .section__content .three-column-image-block-list .section__column .section__inner {
  position: absolute;
  height: 100%;
  width: 100%;
}
.three-column-image-block .section__content .three-column-image-block-list .section__column .section__inner .image-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.three-column-image-block .section__content .three-column-image-block-list .section__column .section__inner .image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.three-column-image-block .section__content .three-column-image-block-list .section__column .section__inner .card-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}
.three-column-image-block .section__content .three-column-image-block-list .section__column .section__inner .card-wrapper .heading {
  width: calc(100% - 2rem);
  position: absolute;
  bottom: 0.75rem;
  left: 1rem;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  text-shadow: 0px 0px 25px black;
  color: #FFF;
  text-transform: uppercase;
  background-image: url("../images/butcombe-btn-arrow-gold.svg");
  background-repeat: no-repeat;
  background-size: 27px 16px;
  background-position: calc(100% - 5px) 50%;
  padding-right: 36px;
  line-height: 50px;
  font-size: 20px;
}
@media (min-width: 34.375em) {
  .three-column-image-block .section__content .three-column-image-block-list .section__column .section__inner .card-wrapper .heading {
    font-size: 22px;
  }
}
.three-column-image-block .section__content .three-column-image-block-list .section__column .section__inner .card-wrapper .heading p {
  text-shadow: 0px 0px 25px black;
}

.three-column-post-block {
  position: relative;
  margin: 0px;
  padding: 0px;
}
.three-column-post-block .section__shell {
  position: relative;
  height: 100%;
  max-width: 1088px;
  margin: 0 auto;
}
.three-column-post-block .section__content {
  position: relative;
  margin: auto auto;
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (min-width: 68.75em) {
  .three-column-post-block .section__content {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.three-column-post-block .section__content .section-heading {
  font-family: "objektiv-mk1", sans-serif;
  color: #B9975B;
  font-size: 24px;
  font-weight: 800;
  line-height: 50px;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 24px;
  text-transform: uppercase;
}
.three-column-post-block .section__content .three-column-post-block-flexbox {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}
@media (min-width: 46.875em) {
  .three-column-post-block .section__content .three-column-post-block-flexbox {
    flex-direction: row;
    justify-content: flex-start;
    flex-wrap: wrap;
    gap: 0.75rem;
  }
}
.three-column-post-block .section__content .three-column-post-block-flexbox .section__column .section__inner .image-wrapper {
  max-height: 230px;
}
.three-column-post-block .section__content .section-button {
  display: flex;
  justify-content: center;
}
.three-column-post-block .section__content .section-button .cta-button {
  margin-top: 1.5rem;
  background: transparent;
  height: 46px;
  width: 100%;
  max-width: 235px;
  border: 2px solid #B9975B;
  border-radius: 30px;
  color: #2D2926;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url("../images/butcombe-btn-arrow-gold.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 25px) 50%;
  padding: 0px 29px 0px 25px;
}
.three-column-post-block .section__content .section-button .cta-button:hover {
  color: #FFF;
  background: #B9975B;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 25px) 50%;
  padding: 0px 29px 0px 25px;
}
.three-column-post-block .section__content .section__column {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}
@media (min-width: 46.875em) {
  .three-column-post-block .section__content .section__column {
    width: calc(33.3% - 0.5rem);
    max-width: 352px;
  }
}
.three-column-post-block .section__content .section__column .section__inner {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
.three-column-post-block .section__content .section__column .section__inner .image-wrapper {
  width: 100%;
  height: 230px;
}
.three-column-post-block .section__content .section__column .section__inner .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.three-column-post-block .section__content .section__column .section__inner .card-wrapper {
  padding: 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.three-column-post-block .section__content .section__column .section__inner .card-wrapper .heading {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 18px;
  line-height: 28px;
  text-transform: uppercase;
}
.three-column-post-block .section__content .section__column .section__inner .card-wrapper .content {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  margin-top: 0.5rem;
}
.three-column-post-block .section__content .section__column .section__inner .card-wrapper .cta-button {
  margin-top: 1rem;
  background: #B9975B;
  height: 36.5px;
  min-width: 165px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 20px) 50%;
  padding: 0px 29px 0px 20px;
}
.three-column-post-block .section__content .section__column .section__inner .card-wrapper .cta-button:hover {
  background: #2D2926;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 20px) 50%;
  padding: 0px 29px 0px 20px;
}

.tiny-images-row-block {
  position: relative;
  margin: 0px;
  padding: 0px 0px 0px 0px;
}
.tiny-images-row-block .section__shell {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1088px;
  margin: 0 auto;
}
.tiny-images-row-block .section__content {
  position: relative;
  margin: auto auto;
  width: 100%;
}
.tiny-images-row-block .section__content .tiny-images-row-block-list {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  align-items: center;
  justify-content: center;
  gap: 1.25rem;
}
@media (min-width: 34.375em) {
  .tiny-images-row-block .section__content .tiny-images-row-block-list {
    gap: 45px;
  }
}
.tiny-images-row-block .section__content .tiny-images-row-block-list .section__column {
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  height: 48px;
  width: 94px;
}
@media (min-width: 34.375em) {
  .tiny-images-row-block .section__content .tiny-images-row-block-list .section__column {
    height: 70px;
    width: 139px;
  }
}
.tiny-images-row-block .section__content .tiny-images-row-block-list .section__column .section__inner {
  position: absolute;
  height: 100%;
  width: 100%;
}
.tiny-images-row-block .section__content .tiny-images-row-block-list .section__column .section__inner .image-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.tiny-images-row-block .section__content .tiny-images-row-block-list .section__column .section__inner .image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: contain;
}

.two-column-form-block {
  position: relative;
  margin: 0px;
}
.two-column-form-block .section__shell {
  position: relative;
  width: 100%;
  max-width: 1088px;
  margin: 0 auto;
  overflow: hidden;
  min-height: 500px;
}
.two-column-form-block .section__overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
.two-column-form-block .section__overlay img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.two-column-form-block .section__content {
  z-index: 2;
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  padding: 0px;
}
@media (min-width: 56.25em) {
  .two-column-form-block .section__content {
    flex-direction: row;
    justify-content: space-between;
    gap: 1rem;
    padding: 0px 2rem 0px 4rem;
  }
}
.two-column-form-block .section__content .form-wrapper {
  z-index: 2;
  background: rgba(45, 41, 38, 0.7);
  padding: 1rem;
  width: 100% !important;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
@media (min-width: 56.25em) {
  .two-column-form-block .section__content .form-wrapper {
    padding: 1.5rem 0px 1rem 0px;
    width: 40% !important;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
}
.two-column-form-block .section__content .form-wrapper.full-width {
  width: 60% !important;
  margin: 0 auto;
}
.two-column-form-block .section__content .form-wrapper.empty-background {
  background: #2d2926;
}
.two-column-form-block .section__content .form-wrapper .section_form_title {
  text-transform: uppercase;
  letter-spacing: normal;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 24px;
  color: #B9975B;
  line-height: 50px;
}
.two-column-form-block .section__content .form-wrapper .gform_confirmation_message {
  background: black !important;
  border: 1.5px solid #FFF !important;
  border-radius: 5px !important;
  padding: 10px !important;
  outline: none !important;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 27px !important;
  color: #FFF !important;
}
.two-column-form-block .section__content .form-wrapper .gform_wrapper {
  width: 100% !important;
  padding: 0px 20px 0px 20px !important;
}
@media (min-width: 56.25em) {
  .two-column-form-block .section__content .form-wrapper .gform_wrapper {
    padding: 0px 40px 0px 40px !important;
  }
}
.two-column-form-block .section__content .form-wrapper .gform_wrapper .gform_heading .gform_title {
  display: none !important;
  text-transform: uppercase !important;
  letter-spacing: normal !important;
  font-family: "objektiv-mk1", sans-serif !important;
  font-weight: 800 !important;
  font-size: 24px !important;
  color: #B9975B !important;
  line-height: 50px !important;
  margin-bottom: 17px !important;
}
.two-column-form-block .section__content .form-wrapper .gform_wrapper .gform_validation_errors {
  background: #2D2926;
  border: 1.5px solid #B9975B;
  padding: 5px;
  outline: none;
}
.two-column-form-block .section__content .form-wrapper .gform_wrapper .gform_validation_errors * {
  font-family: "objektiv-mk1", sans-serif;
  font-size: 13px;
  color: #B9975B;
}
.two-column-form-block .section__content .form-wrapper .gform_wrapper .gfield_validation_message {
  background: #2D2926;
  border: 1.5px solid #B9975B;
  border-radius: 5px;
  padding: 5px;
  outline: none;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 13px;
  color: #B9975B;
}
.two-column-form-block .section__content .form-wrapper .gform_wrapper .gform_required_legend {
  display: none;
}
.two-column-form-block .section__content .form-wrapper .gform_wrapper .gfield--type-html {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  color: #FFF;
}
.two-column-form-block .section__content .form-wrapper .gform_wrapper label {
  color: #FFF !important;
  font-family: "objektiv-mk1", sans-serif !important;
  font-size: 18px !important;
}
.two-column-form-block .section__content .form-wrapper .gform_wrapper input,
.two-column-form-block .section__content .form-wrapper .gform_wrapper textarea {
  font-family: "objektiv-mk1", sans-serif !important;
  font-size: 14px !important;
  color: #FFF !important;
  background: transparent !important;
  border-radius: 8px !important;
  border: 2px solid #B9975B !important;
  outline: none !important;
  cursor: text !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  resize: none !important;
  overflow: hidden !important;
}
.two-column-form-block .section__content .form-wrapper .gform_wrapper input::placeholder,
.two-column-form-block .section__content .form-wrapper .gform_wrapper textarea::placeholder {
  color: #FFF !important;
}
.two-column-form-block .section__content .form-wrapper .gform_wrapper input::-ms-input-placeholder,
.two-column-form-block .section__content .form-wrapper .gform_wrapper textarea::-ms-input-placeholder {
  /* Edge 12 -18 */
  color: #FFF !important;
}
.two-column-form-block .section__content .form-wrapper .gform_wrapper textarea {
  overflow-y: auto !important;
  resize: vertical !important;
}
.two-column-form-block .section__content .form-wrapper .gform_wrapper .gchoice {
  display: flex !important;
  align-items: flex-start !important;
}
.two-column-form-block .section__content .form-wrapper .gform_wrapper .gchoice input {
  margin-top: 5px !important;
  margin-right: 10px !important;
  border: 2px solid #B9975B !important;
  outline: none !important;
}
.two-column-form-block .section__content .form-wrapper .gform_wrapper .gchoice label {
  font-family: "objektiv-mk1", sans-serif !important;
  font-weight: 300 !important;
  font-size: 15px !important;
  line-height: 24px !important;
  color: #FFF !important;
}
.two-column-form-block .section__content .form-wrapper .gform_wrapper .gform_button {
  margin-top: 1rem !important;
  background: #B9975B !important;
  height: 36px !important;
  width: 100% !important;
  max-width: 165px !important;
  border: 0px !important;
  border-radius: 30px !important;
  cursor: pointer !important;
  display: flex !important;
  flex-direction: row !important;
  align-items: center !important;
  justify-content: flex-start !important;
  text-align: left !important;
  color: #FFF !important;
  font-family: "objektiv-mk1", sans-serif !important;
  font-size: 12px !important;
  font-weight: 300 !important;
  letter-spacing: 1.5px !important;
  text-decoration: none !important;
  text-transform: uppercase !important;
  background-image: url("../images/butcombe-btn-arrow.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 20px 12px !important;
  background-position: calc(100% - 20px) 50% !important;
  padding: 0px 29px 0px 20px !important;
}
.two-column-form-block .section__content .form-wrapper .gform_wrapper .gform_button:hover {
  background: #2D2926 !important;
  background-image: url("../images/butcombe-btn-arrow.svg") !important;
  background-repeat: no-repeat !important;
  background-size: 20px 12px !important;
  background-position: calc(100% - 20px) 50% !important;
  padding: 0px 29px 0px 20px !important;
}
.two-column-form-block .section__content .card-wrapper {
  z-index: 2;
  width: 100% !important;
  padding: 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}
@media (min-width: 56.25em) {
  .two-column-form-block .section__content .card-wrapper {
    width: 45% !important;
    padding: 0px;
  }
}
.two-column-form-block .section__content .card-wrapper.top {
  justify-content: flex-start;
}
.two-column-form-block .section__content .card-wrapper .heading {
  margin-bottom: 1rem;
  width: 100%;
  color: #FFF;
  letter-spacing: normal;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 28px;
  line-height: 40px;
}
@media (min-width: 56.25em) {
  .two-column-form-block .section__content .card-wrapper .heading {
    font-size: 38px;
    line-height: 50px;
  }
}
.two-column-form-block .section__content .card-wrapper .content {
  margin-bottom: 1rem;
  width: 100%;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
}
@media (min-width: 56.25em) {
  .two-column-form-block .section__content .card-wrapper .content {
    font-size: 19px;
    line-height: 29px;
  }
}
.two-column-form-block .section__content .card-wrapper .content a {
  color: #FFF;
}
.two-column-form-block .section__content .card-wrapper .content a:hover {
  color: #B9975B;
}
.two-column-form-block .section__content .card-wrapper .cta-button {
  margin-top: 1rem;
  background: #B9975B;
  height: 46px;
  width: 100%;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 29px) 50%;
  padding: 0px 29px 0px 25px;
  max-width: 290px;
}
@media (min-width: 34.375em) {
  .two-column-form-block .section__content .card-wrapper .cta-button {
    max-width: 300px;
  }
}
.two-column-form-block .section__content .card-wrapper .cta-button:hover {
  background: #2D2926;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 29px) 50%;
  padding: 0px 29px 0px 25px;
}
.two-column-form-block .section__content .card-wrapper.empty-background .heading {
  color: #2D2926;
}
.two-column-form-block .section__content .card-wrapper.empty-background .content {
  color: #2D2926;
}
.two-column-form-block .section__content .card-wrapper.empty-background .content a {
  color: #2D2926;
}

.two-column-image-block {
  position: relative;
  margin: 0px;
  padding: 0px 0px 0px 0px;
}
.two-column-image-block .section__shell {
  position: relative;
  height: 100%;
  width: 100%;
  max-width: 1088px;
  margin: 0 auto;
}
.two-column-image-block .section__content {
  position: relative;
  margin: auto auto;
  width: 100%;
}
.two-column-image-block .section__content .section_heading {
  width: 100%;
  text-align: center;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 24px;
  font-weight: 800 !important;
  line-height: 50px;
  color: #B9975B;
  letter-spacing: normal;
  margin-bottom: 1rem;
}
.two-column-image-block .section__content .two-column-image-block-list {
  display: flex;
  flex-direction: column;
  margin-left: 2rem;
  margin-right: 2rem;
}
@media (min-width: 34.375em) {
  .two-column-image-block .section__content .two-column-image-block-list {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: center;
    gap: 0.75rem;
  }
}
@media (min-width: 68.75em) {
  .two-column-image-block .section__content .two-column-image-block-list {
    margin-left: 0px;
    margin-right: 0px;
  }
}
.two-column-image-block .section__content .two-column-image-block-list .section__column {
  margin-bottom: 0.75rem;
  overflow: hidden;
  position: relative;
  border-radius: 8px;
  cursor: pointer;
  height: 260px;
  width: 100%;
}
@media (min-width: 34.375em) {
  .two-column-image-block .section__content .two-column-image-block-list .section__column {
    height: 350px;
    width: calc(50% - 0.5rem);
    max-width: 536px;
  }
}
.two-column-image-block .section__content .two-column-image-block-list .section__column:hover .section__inner .card-wrapper .heading {
  background-image: url("../images/butcombe-btn-arrow.svg");
}
.two-column-image-block .section__content .two-column-image-block-list .section__column .section__inner {
  position: absolute;
  height: 100%;
  width: 100%;
}
.two-column-image-block .section__content .two-column-image-block-list .section__column .section__inner .image-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
}
.two-column-image-block .section__content .two-column-image-block-list .section__column .section__inner .image-wrapper img {
  height: 100%;
  width: 100%;
  object-fit: cover;
}
.two-column-image-block .section__content .two-column-image-block-list .section__column .section__inner .card-wrapper {
  position: absolute;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.1);
}
.two-column-image-block .section__content .two-column-image-block-list .section__column .section__inner .card-wrapper .heading {
  width: calc(100% - 2rem);
  position: absolute;
  bottom: 0.75rem;
  left: 1rem;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 22px;
  font-weight: 800;
  line-height: 50px;
  color: #FFF;
  text-transform: uppercase;
  background-image: url("../images/butcombe-btn-arrow-gold.svg");
  background-repeat: no-repeat;
  background-size: 27px 16px;
  background-position: calc(100% - 5px) 50%;
  padding-right: 36px;
}

.two-column-post-block {
  position: relative;
  margin: 0px;
  padding: 0px;
}
.two-column-post-block .section__shell {
  position: relative;
  height: 100%;
  max-width: 1088px;
  margin: 0 auto;
}
.two-column-post-block .section__content {
  position: relative;
  margin: auto auto;
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (min-width: 68.75em) {
  .two-column-post-block .section__content {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.two-column-post-block .section__content .section-heading {
  font-family: "objektiv-mk1", sans-serif;
  color: #B9975B;
  font-size: 24px;
  font-weight: 800;
  line-height: 50px;
  letter-spacing: normal;
  text-align: center;
  margin-bottom: 24px;
  text-transform: uppercase;
}
.two-column-post-block .section__content .two-column-post-block-flexbox {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
.two-column-post-block .section__content .section-button {
  width: 100%;
  display: flex;
  justify-content: center;
}
.two-column-post-block .section__content .section-button .cta-button {
  margin-top: 2rem;
  background: transparent;
  height: 46px;
  width: 100%;
  max-width: 200px;
  border: 2px solid #B9975B;
  border-radius: 30px;
  color: #2D2926;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url("../images/butcombe-btn-arrow-gold.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 20px) 50%;
  padding: 0px 20px 0px 20px;
}
.two-column-post-block .section__content .section-button .cta-button:hover {
  color: #FFF;
  background: #B9975B;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 20px) 50%;
  padding: 0px 20px 0px 20px;
}
.two-column-post-block .section__content .section__column {
  background: white;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
}
.two-column-post-block .section__content .section__column .section__inner {
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  cursor: pointer;
}
@media (min-width: 34.375em) {
  .two-column-post-block .section__content .section__column .section__inner {
    flex-direction: row;
    justify-content: space-between;
  }
}
.two-column-post-block .section__content .section__column .section__inner .image-wrapper {
  height: 230px;
  width: 100%;
}
@media (min-width: 34.375em) {
  .two-column-post-block .section__content .section__column .section__inner .image-wrapper {
    height: 280px;
    width: 41%;
    max-width: 444px;
  }
}
.two-column-post-block .section__content .section__column .section__inner .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.two-column-post-block .section__content .section__column .section__inner .card-wrapper {
  padding: 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
  width: 100%;
}
@media (min-width: 34.375em) {
  .two-column-post-block .section__content .section__column .section__inner .card-wrapper {
    width: 59%;
    max-width: 644px;
  }
}
.two-column-post-block .section__content .section__column .section__inner .card-wrapper .heading {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 20px;
  line-height: 28px;
  text-transform: uppercase;
}
.two-column-post-block .section__content .section__column .section__inner .card-wrapper .content {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 24px;
  margin-top: 0.75rem;
}
.two-column-post-block .section__content .section__column .section__inner .card-wrapper .cta-button {
  margin-top: 1rem;
  background: #B9975B;
  height: 36px;
  width: 100%;
  max-width: 165px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  text-align: left;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 20px) 50%;
  padding: 0px 20px 0px 20px;
}
.two-column-post-block .section__content .section__column .section__inner .card-wrapper .cta-button:hover {
  background: #2D2926;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 20px) 50%;
  padding: 0px 20px 0px 20px;
}

.allergy-section {
  max-width: 1920px;
  width: 100vw;
  margin-top: 3rem;
  margin-bottom: 3rem;
  padding-top: 0;
  padding-bottom: 0;
  padding-left: 2rem;
  padding-right: 2rem;
}
@media (min-width: 68.75em) {
  .allergy-section {
    padding-left: 0px;
    padding-right: 0px;
  }
}
.allergy-section .content-wrapper {
  width: 100%;
  max-width: 1088px;
  margin: 0 auto;
  padding: 1.5rem;
  background: #FFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}
@media (min-width: 56.25em) {
  .allergy-section .content-wrapper {
    flex-direction: row;
    gap: 1rem;
  }
}
.allergy-section .content-wrapper .input-wrapper {
  max-width: 100%;
  background: #FFF;
  border: 2px solid #B9975B;
  border-radius: 8px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
}
@media (min-width: 56.25em) {
  .allergy-section .content-wrapper .input-wrapper {
    width: 37%;
  }
}
@media (min-width: 56.25em) {
  .allergy-section .content-wrapper .input-wrapper.cinema {
    width: 17%;
  }
}
.allergy-section .content-wrapper .input-wrapper .banner-search-field,
.allergy-section .content-wrapper .input-wrapper .banner-search-field-text {
  width: calc(100% - 36px);
  height: 36px;
  background: transparent;
  border-radius: 8px !important;
  color: #231f20;
  text-decoration: none;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 14px;
  font-weight: 300;
  border: 0px;
  outline: 0px;
  padding: 0px 10px;
}
.allergy-section .content-wrapper .input-wrapper .banner-search-btn {
  height: 36px;
  width: 36px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  cursor: pointer;
}
.allergy-section .content-wrapper .input-wrapper .banner-search-btn img {
  height: 16px;
  width: 16px;
}
.allergy-section .content-wrapper .heading {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  text-align: center;
  letter-spacing: normal;
  font-size: 20px;
}
@media (min-width: 34.375em) {
  .allergy-section .content-wrapper .heading {
    font-size: 24px;
  }
}
.allergy-section .content-wrapper .content {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: normal;
  text-align: center;
  letter-spacing: normal;
  font-size: 16px;
}
@media (min-width: 34.375em) {
  .allergy-section .content-wrapper .content {
    font-size: 19px;
  }
}
.allergy-section .content-wrapper .select-wrapper {
  border: 2px solid #B9975B;
  border-radius: 8px !important;
  width: 100%;
}
@media (min-width: 56.25em) {
  .allergy-section .content-wrapper .select-wrapper {
    width: 20%;
  }
}
.allergy-section .content-wrapper .select-wrapper select {
  height: 36px;
  width: 100%;
  background: #FFF7EB;
  border-radius: 8px !important;
  color: #231f20;
  text-decoration: none;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 14px;
  font-weight: 300;
  border: 0px;
  outline: 0px;
  padding: 0px 10px;
}
.allergy-section .content-wrapper .button-wrapper {
  text-align: center;
  display: flex;
  justify-content: center;
  margin-top: 0.75rem;
  width: 100%;
}
@media (min-width: 56.25em) {
  .allergy-section .content-wrapper .button-wrapper {
    margin-top: 0px;
    width: 15%;
  }
}
.allergy-section .content-wrapper .button-wrapper .cta-button {
  background: #B9975B;
  height: 46px;
  width: 100%;
  max-width: 176px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 13px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 25px) 50%;
  padding: 0px 29px 0px 25px;
}
.allergy-section .content-wrapper .button-wrapper .cta-button:hover {
  background: #2D2926;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 25px) 50%;
  padding: 0px 29px 0px 25px;
}
.allergy-section .content-wrapper .button-wrapper .cta-button.disabled {
  background: #898F9A;
}

.pubs_list_autocomplete.ui-widget.ui-widget-content {
  border: 1px solid #c3a76d;
  background-color: white;
  font-family: "objektiv-mk1", sans-serif;
  border-radius: 4px;
}
.pubs_list_autocomplete.ui-widget.ui-widget-content .ui-menu-item-wrapper {
  font-size: 15px;
}

.iframe-wrapper {
  text-align: center;
  height: 800px;
  display: none;
  margin: 50px auto;
}

#allergy-iframe {
  width: 100%;
  height: 800px;
  max-width: 1088px;
  border: none;
}

.gallery-block {
  position: relative;
  /*	padding: 0 14vw; */
  height: 204px;
}
@media (min-width: 46.875em) {
  .gallery-block {
    height: 423px;
  }
}
@media (min-width: 56.25em) {
  .gallery-block {
    height: 767px;
  }
}
.gallery-block ul.images {
  list-style-type: none;
  margin: 0;
  padding: 0;
  display: flex;
  max-height: 100%;
}
.gallery-block ul.images li {
  min-width: 100%;
  width: 100%;
  max-height: 100%;
}
.gallery-block ul.images li img {
  height: 100%;
  width: 100%;
  margin: auto;
  object-fit: cover;
}
@media (min-width: 46.875em) {
  .gallery-block ul.images li img {
    border-radius: 4px;
  }
}
.gallery-block ul.images * {
  height: 204px;
}
@media (min-width: 46.875em) {
  .gallery-block ul.images * {
    height: 423px;
  }
}
@media (min-width: 56.25em) {
  .gallery-block ul.images * {
    height: 767px;
  }
}
.gallery-block ul.images .slick-slide li {
  transition: opacity 0.5s, padding 0.5s;
}
.gallery-block .controls {
  display: flex;
  justify-content: space-between;
  position: absolute;
  top: calc(50% - 19px);
  width: calc(100% - 60px);
  left: 30px;
}
@media (min-width: 46.875em) {
  .gallery-block .controls {
    width: calc(100% - 10vw - 60px);
    left: calc(5vw + 30px);
  }
}
@media (min-width: 56.25em) {
  .gallery-block .controls {
    width: calc(100% - 28vw - 136px);
    left: calc(14vw + 68px);
  }
}
.gallery-block .controls > * {
  width: 33px;
  height: 14px;
  display: block;
  cursor: pointer;
  background-size: 33px 14px;
}
@media (min-width: 46.875em) {
  .gallery-block .controls > * {
    width: 68px;
    height: 29px;
    background-size: 68px 29px;
  }
}
@media (min-width: 56.25em) {
  .gallery-block .controls > * {
    width: 80px;
    height: 34px;
    background-size: 80px 34px;
  }
}
.gallery-block .controls .sliderPrev {
  background-image: url("../images/arrow_left.svg");
}
.gallery-block .controls .sliderNext {
  background-image: url("../images/arrow_right.svg");
}
.gallery-block ul.slick-dots {
  list-style-type: none;
  padding: 0;
  height: initial;
  display: flex;
  justify-content: center;
  position: relative;
  top: -76px;
}
.gallery-block ul.slick-dots li {
  min-width: unset;
  margin-right: 9px;
}
.gallery-block ul.slick-dots li button {
  content: "";
  height: 2px;
  width: 12vw;
  background-color: rgba(255, 255, 255, 0.3);
  border: none;
}
@media (min-width: 46.875em) {
  .gallery-block ul.slick-dots li button {
    height: 4px;
    width: 63px;
  }
}
.gallery-block ul.slick-dots li.slick-active button {
  background-color: white;
}
.gallery-block ul.slick-dots * {
  height: initial;
}
.gallery-block .gallery {
  max-width: 100%;
  width: 100%;
  order: 1;
}
@media (min-width: 56.25em) {
  .gallery-block .gallery {
    max-width: 100%;
    margin-bottom: 0px;
  }
}
.gallery-block .gallery .slider-nav .slick-track {
  display: flex;
  flex-direction: row;
  align-items: stretch;
  height: 100%;
}
.gallery-block .gallery .slider-nav .slick-slide {
  height: auto;
  padding: 10px 2px;
  line-height: normal;
  display: flex !important;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.gallery-block .gallery .slider-nav .slick-slide img {
  flex-shrink: 0;
  min-width: 100%;
  min-height: 100%;
}

/* 8 */
/*  ==========================================================================
    Generic:Widths
    ========================================================================== */
/*
    Sizes in human readable format. These are used in conjunction with other
    objects and abstractions, most commonly the grid system.

    We have a mixin to generate our widths and their breakpoint-specific
    variations.
*/
/*
    Whole
    -----
*/
.one-whole {
  width: 100%;
}

/*
    Halves
    ------
*/
.one-half {
  width: 50%;
}

/*
    Thirds
    ------
*/
.one-third {
  width: 33.3333333%;
}

.two-thirds {
  width: 66.6666666%;
}

/*
    Quarters
    --------
*/
.one-quarter {
  width: 25%;
}

.two-quarters {
  width: 50%;
}

.three-quarters {
  width: 75%;
}

/*
    Fifths
    ------
*/
.one-fifth {
  width: 20%;
}

.two-fifths {
  width: 40%;
}

.three-fifths {
  width: 60%;
}

.four-fifths {
  width: 80%;
}

/*
    Sixths
    ------
*/
.one-sixth {
  width: 16.6666666%;
}

.two-sixths {
  width: 33.3333333%;
}

.three-sixths {
  width: 50%;
}

.four-sixths {
  width: 66.6666666%;
}

.five-sixths {
  width: 83.3333333%;
}

/*
    Eighths
    -------
*/
.one-eighth {
  width: 12.5%;
}

.two-eighths {
  width: 25%;
}

.three-eighths {
  width: 37.5%;
}

.four-eighths {
  width: 50%;
}

.five-eighths {
  width: 62.5%;
}

.six-eighths {
  width: 75%;
}

.seven-eighths {
  width: 87.5%;
}

/*
    Ninths
    ------
*/
.one-ninth {
  width: 11.1111111%;
}

.two-ninths {
  width: 22.2222222%;
}

.four-ninths {
  width: 44.4444444%;
}

.five-ninths {
  width: 55.5555555%;
}

.seven-ninths {
  width: 77.7777777%;
}

.eight-ninths {
  width: 88.8888888%;
}

/*
    Tenths
    ------
*/
.one-tenth, .w-10 {
  width: 10%;
}

.two-tenths, .w-20 {
  width: 20%;
}

.three-tenths, .w-30 {
  width: 30%;
}

.four-tenths, .w-40 {
  width: 40%;
}

.five-tenths, .w-50 {
  width: 50%;
}

.six-tenths, .w-60 {
  width: 60%;
}

.seven-tenths, .w-70 {
  width: 70%;
}

.eight-tenths, .w-80 {
  width: 80%;
}

.nine-tenths, .w-90 {
  width: 90%;
}

.ten-tenths, .w-100 {
  width: 100%;
}

/*
    Twelfths
    --------
*/
.one-twelfth {
  width: 8.3333333%;
}

.two-twelfths {
  width: 16.6666666%;
}

.three-twelfths {
  width: 25%;
}

.four-twelfths {
  width: 33.3333333%;
}

.five-twelfths {
  width: 41.6666666%;
}

.six-twelfths {
  width: 50%;
}

.seven-twelfths {
  width: 58.3333333%;
}

.eight-twelfths {
  width: 66.6666666%;
}

.nine-twelfths {
  width: 75%;
}

.ten-twelfths {
  width: 83.3333333%;
}

.eleven-twelfths {
  width: 91.6666666%;
}

/*
    Twentieth
    --------
*/
.one-twentieth {
  width: 5%;
}

.two-twentieths {
  width: 10%;
}

.three-twentieths {
  width: 15%;
}

.four-twentieths {
  width: 20%;
}

.five-twentieths {
  width: 25%;
}

.six-twentieths {
  width: 30%;
}

.seven-twentieths {
  width: 35%;
}

.eight-twentieths {
  width: 40%;
}

.nine-twentieths {
  width: 45%;
}

.ten-twentieths {
  width: 50%;
}

.eleven-twentieths {
  width: 55%;
}

.twelve-twentieths {
  width: 60%;
}

.thirteen-twentieths {
  width: 65%;
}

/*
Create more grid sizes below:-

@include respond($bp--grid-small) {
    @include grid-setup("small-");
}
*/
@media (min-width: 28.125em) {
  /*
      Whole
      -----
  */
  .small-one-whole {
    width: 100%;
  }

  /*
      Halves
      ------
  */
  .small-one-half {
    width: 50%;
  }

  /*
      Thirds
      ------
  */
  .small-one-third {
    width: 33.3333333%;
  }

  .small-two-thirds {
    width: 66.6666666%;
  }

  /*
      Quarters
      --------
  */
  .small-one-quarter {
    width: 25%;
  }

  .small-two-quarters {
    width: 50%;
  }

  .small-three-quarters {
    width: 75%;
  }

  /*
      Fifths
      ------
  */
  .small-one-fifth {
    width: 20%;
  }

  .small-two-fifths {
    width: 40%;
  }

  .small-three-fifths {
    width: 60%;
  }

  .small-four-fifths {
    width: 80%;
  }

  /*
      Sixths
      ------
  */
  .small-one-sixth {
    width: 16.6666666%;
  }

  .small-two-sixths {
    width: 33.3333333%;
  }

  .small-three-sixths {
    width: 50%;
  }

  .small-four-sixths {
    width: 66.6666666%;
  }

  .small-five-sixths {
    width: 83.3333333%;
  }

  /*
      Eighths
      -------
  */
  .small-one-eighth {
    width: 12.5%;
  }

  .small-two-eighths {
    width: 25%;
  }

  .small-three-eighths {
    width: 37.5%;
  }

  .small-four-eighths {
    width: 50%;
  }

  .small-five-eighths {
    width: 62.5%;
  }

  .small-six-eighths {
    width: 75%;
  }

  .small-seven-eighths {
    width: 87.5%;
  }

  /*
      Ninths
      ------
  */
  .small-one-ninth {
    width: 11.1111111%;
  }

  .small-two-ninths {
    width: 22.2222222%;
  }

  .small-four-ninths {
    width: 44.4444444%;
  }

  .small-five-ninths {
    width: 55.5555555%;
  }

  .small-seven-ninths {
    width: 77.7777777%;
  }

  .small-eight-ninths {
    width: 88.8888888%;
  }

  /*
      Tenths
      ------
  */
  .small-one-tenth, .small-w-10 {
    width: 10%;
  }

  .small-two-tenths, .small-w-20 {
    width: 20%;
  }

  .small-three-tenths, .small-w-30 {
    width: 30%;
  }

  .small-four-tenths, .small-w-40 {
    width: 40%;
  }

  .small-five-tenths, .small-w-50 {
    width: 50%;
  }

  .small-six-tenths, .small-w-60 {
    width: 60%;
  }

  .small-seven-tenths, .small-w-70 {
    width: 70%;
  }

  .small-eight-tenths, .small-w-80 {
    width: 80%;
  }

  .small-nine-tenths, .small-w-90 {
    width: 90%;
  }

  .small-ten-tenths, .small-w-100 {
    width: 100%;
  }

  /*
      Twelfths
      --------
  */
  .small-one-twelfth {
    width: 8.3333333%;
  }

  .small-two-twelfths {
    width: 16.6666666%;
  }

  .small-three-twelfths {
    width: 25%;
  }

  .small-four-twelfths {
    width: 33.3333333%;
  }

  .small-five-twelfths {
    width: 41.6666666%;
  }

  .small-six-twelfths {
    width: 50%;
  }

  .small-seven-twelfths {
    width: 58.3333333%;
  }

  .small-eight-twelfths {
    width: 66.6666666%;
  }

  .small-nine-twelfths {
    width: 75%;
  }

  .small-ten-twelfths {
    width: 83.3333333%;
  }

  .small-eleven-twelfths {
    width: 91.6666666%;
  }

  /*
      Twentieth
      --------
  */
  .small-one-twentieth {
    width: 5%;
  }

  .small-two-twentieths {
    width: 10%;
  }

  .small-three-twentieths {
    width: 15%;
  }

  .small-four-twentieths {
    width: 20%;
  }

  .small-five-twentieths {
    width: 25%;
  }

  .small-six-twentieths {
    width: 30%;
  }

  .small-seven-twentieths {
    width: 35%;
  }

  .small-eight-twentieths {
    width: 40%;
  }

  .small-nine-twentieths {
    width: 45%;
  }

  .small-ten-twentieths {
    width: 50%;
  }

  .small-eleven-twentieths {
    width: 55%;
  }

  .small-twelve-twentieths {
    width: 60%;
  }

  .small-thirteen-twentieths {
    width: 65%;
  }
}
@media (min-width: 34.375em) {
  /*
      Whole
      -----
  */
  .medium-one-whole {
    width: 100%;
  }

  /*
      Halves
      ------
  */
  .medium-one-half {
    width: 50%;
  }

  /*
      Thirds
      ------
  */
  .medium-one-third {
    width: 33.3333333%;
  }

  .medium-two-thirds {
    width: 66.6666666%;
  }

  /*
      Quarters
      --------
  */
  .medium-one-quarter {
    width: 25%;
  }

  .medium-two-quarters {
    width: 50%;
  }

  .medium-three-quarters {
    width: 75%;
  }

  /*
      Fifths
      ------
  */
  .medium-one-fifth {
    width: 20%;
  }

  .medium-two-fifths {
    width: 40%;
  }

  .medium-three-fifths {
    width: 60%;
  }

  .medium-four-fifths {
    width: 80%;
  }

  /*
      Sixths
      ------
  */
  .medium-one-sixth {
    width: 16.6666666%;
  }

  .medium-two-sixths {
    width: 33.3333333%;
  }

  .medium-three-sixths {
    width: 50%;
  }

  .medium-four-sixths {
    width: 66.6666666%;
  }

  .medium-five-sixths {
    width: 83.3333333%;
  }

  /*
      Eighths
      -------
  */
  .medium-one-eighth {
    width: 12.5%;
  }

  .medium-two-eighths {
    width: 25%;
  }

  .medium-three-eighths {
    width: 37.5%;
  }

  .medium-four-eighths {
    width: 50%;
  }

  .medium-five-eighths {
    width: 62.5%;
  }

  .medium-six-eighths {
    width: 75%;
  }

  .medium-seven-eighths {
    width: 87.5%;
  }

  /*
      Ninths
      ------
  */
  .medium-one-ninth {
    width: 11.1111111%;
  }

  .medium-two-ninths {
    width: 22.2222222%;
  }

  .medium-four-ninths {
    width: 44.4444444%;
  }

  .medium-five-ninths {
    width: 55.5555555%;
  }

  .medium-seven-ninths {
    width: 77.7777777%;
  }

  .medium-eight-ninths {
    width: 88.8888888%;
  }

  /*
      Tenths
      ------
  */
  .medium-one-tenth, .medium-w-10 {
    width: 10%;
  }

  .medium-two-tenths, .medium-w-20 {
    width: 20%;
  }

  .medium-three-tenths, .medium-w-30 {
    width: 30%;
  }

  .medium-four-tenths, .medium-w-40 {
    width: 40%;
  }

  .medium-five-tenths, .medium-w-50 {
    width: 50%;
  }

  .medium-six-tenths, .medium-w-60 {
    width: 60%;
  }

  .medium-seven-tenths, .medium-w-70 {
    width: 70%;
  }

  .medium-eight-tenths, .medium-w-80 {
    width: 80%;
  }

  .medium-nine-tenths, .medium-w-90 {
    width: 90%;
  }

  .medium-ten-tenths, .medium-w-100 {
    width: 100%;
  }

  /*
      Twelfths
      --------
  */
  .medium-one-twelfth {
    width: 8.3333333%;
  }

  .medium-two-twelfths {
    width: 16.6666666%;
  }

  .medium-three-twelfths {
    width: 25%;
  }

  .medium-four-twelfths {
    width: 33.3333333%;
  }

  .medium-five-twelfths {
    width: 41.6666666%;
  }

  .medium-six-twelfths {
    width: 50%;
  }

  .medium-seven-twelfths {
    width: 58.3333333%;
  }

  .medium-eight-twelfths {
    width: 66.6666666%;
  }

  .medium-nine-twelfths {
    width: 75%;
  }

  .medium-ten-twelfths {
    width: 83.3333333%;
  }

  .medium-eleven-twelfths {
    width: 91.6666666%;
  }

  /*
      Twentieth
      --------
  */
  .medium-one-twentieth {
    width: 5%;
  }

  .medium-two-twentieths {
    width: 10%;
  }

  .medium-three-twentieths {
    width: 15%;
  }

  .medium-four-twentieths {
    width: 20%;
  }

  .medium-five-twentieths {
    width: 25%;
  }

  .medium-six-twentieths {
    width: 30%;
  }

  .medium-seven-twentieths {
    width: 35%;
  }

  .medium-eight-twentieths {
    width: 40%;
  }

  .medium-nine-twentieths {
    width: 45%;
  }

  .medium-ten-twentieths {
    width: 50%;
  }

  .medium-eleven-twentieths {
    width: 55%;
  }

  .medium-twelve-twentieths {
    width: 60%;
  }

  .medium-thirteen-twentieths {
    width: 65%;
  }
}
@media (min-width: 46.875em) {
  /*
      Whole
      -----
  */
  .large-one-whole {
    width: 100%;
  }

  /*
      Halves
      ------
  */
  .large-one-half {
    width: 50%;
  }

  /*
      Thirds
      ------
  */
  .large-one-third {
    width: 33.3333333%;
  }

  .large-two-thirds {
    width: 66.6666666%;
  }

  /*
      Quarters
      --------
  */
  .large-one-quarter {
    width: 25%;
  }

  .large-two-quarters {
    width: 50%;
  }

  .large-three-quarters {
    width: 75%;
  }

  /*
      Fifths
      ------
  */
  .large-one-fifth {
    width: 20%;
  }

  .large-two-fifths {
    width: 40%;
  }

  .large-three-fifths {
    width: 60%;
  }

  .large-four-fifths {
    width: 80%;
  }

  /*
      Sixths
      ------
  */
  .large-one-sixth {
    width: 16.6666666%;
  }

  .large-two-sixths {
    width: 33.3333333%;
  }

  .large-three-sixths {
    width: 50%;
  }

  .large-four-sixths {
    width: 66.6666666%;
  }

  .large-five-sixths {
    width: 83.3333333%;
  }

  /*
      Eighths
      -------
  */
  .large-one-eighth {
    width: 12.5%;
  }

  .large-two-eighths {
    width: 25%;
  }

  .large-three-eighths {
    width: 37.5%;
  }

  .large-four-eighths {
    width: 50%;
  }

  .large-five-eighths {
    width: 62.5%;
  }

  .large-six-eighths {
    width: 75%;
  }

  .large-seven-eighths {
    width: 87.5%;
  }

  /*
      Ninths
      ------
  */
  .large-one-ninth {
    width: 11.1111111%;
  }

  .large-two-ninths {
    width: 22.2222222%;
  }

  .large-four-ninths {
    width: 44.4444444%;
  }

  .large-five-ninths {
    width: 55.5555555%;
  }

  .large-seven-ninths {
    width: 77.7777777%;
  }

  .large-eight-ninths {
    width: 88.8888888%;
  }

  /*
      Tenths
      ------
  */
  .large-one-tenth, .large-w-10 {
    width: 10%;
  }

  .large-two-tenths, .large-w-20 {
    width: 20%;
  }

  .large-three-tenths, .large-w-30 {
    width: 30%;
  }

  .large-four-tenths, .large-w-40 {
    width: 40%;
  }

  .large-five-tenths, .large-w-50 {
    width: 50%;
  }

  .large-six-tenths, .large-w-60 {
    width: 60%;
  }

  .large-seven-tenths, .large-w-70 {
    width: 70%;
  }

  .large-eight-tenths, .large-w-80 {
    width: 80%;
  }

  .large-nine-tenths, .large-w-90 {
    width: 90%;
  }

  .large-ten-tenths, .large-w-100 {
    width: 100%;
  }

  /*
      Twelfths
      --------
  */
  .large-one-twelfth {
    width: 8.3333333%;
  }

  .large-two-twelfths {
    width: 16.6666666%;
  }

  .large-three-twelfths {
    width: 25%;
  }

  .large-four-twelfths {
    width: 33.3333333%;
  }

  .large-five-twelfths {
    width: 41.6666666%;
  }

  .large-six-twelfths {
    width: 50%;
  }

  .large-seven-twelfths {
    width: 58.3333333%;
  }

  .large-eight-twelfths {
    width: 66.6666666%;
  }

  .large-nine-twelfths {
    width: 75%;
  }

  .large-ten-twelfths {
    width: 83.3333333%;
  }

  .large-eleven-twelfths {
    width: 91.6666666%;
  }

  /*
      Twentieth
      --------
  */
  .large-one-twentieth {
    width: 5%;
  }

  .large-two-twentieths {
    width: 10%;
  }

  .large-three-twentieths {
    width: 15%;
  }

  .large-four-twentieths {
    width: 20%;
  }

  .large-five-twentieths {
    width: 25%;
  }

  .large-six-twentieths {
    width: 30%;
  }

  .large-seven-twentieths {
    width: 35%;
  }

  .large-eight-twentieths {
    width: 40%;
  }

  .large-nine-twentieths {
    width: 45%;
  }

  .large-ten-twentieths {
    width: 50%;
  }

  .large-eleven-twentieths {
    width: 55%;
  }

  .large-twelve-twentieths {
    width: 60%;
  }

  .large-thirteen-twentieths {
    width: 65%;
  }
}
@media (min-width: 56.25em) {
  /*
      Whole
      -----
  */
  .larger-one-whole {
    width: 100%;
  }

  /*
      Halves
      ------
  */
  .larger-one-half {
    width: 50%;
  }

  /*
      Thirds
      ------
  */
  .larger-one-third {
    width: 33.3333333%;
  }

  .larger-two-thirds {
    width: 66.6666666%;
  }

  /*
      Quarters
      --------
  */
  .larger-one-quarter {
    width: 25%;
  }

  .larger-two-quarters {
    width: 50%;
  }

  .larger-three-quarters {
    width: 75%;
  }

  /*
      Fifths
      ------
  */
  .larger-one-fifth {
    width: 20%;
  }

  .larger-two-fifths {
    width: 40%;
  }

  .larger-three-fifths {
    width: 60%;
  }

  .larger-four-fifths {
    width: 80%;
  }

  /*
      Sixths
      ------
  */
  .larger-one-sixth {
    width: 16.6666666%;
  }

  .larger-two-sixths {
    width: 33.3333333%;
  }

  .larger-three-sixths {
    width: 50%;
  }

  .larger-four-sixths {
    width: 66.6666666%;
  }

  .larger-five-sixths {
    width: 83.3333333%;
  }

  /*
      Eighths
      -------
  */
  .larger-one-eighth {
    width: 12.5%;
  }

  .larger-two-eighths {
    width: 25%;
  }

  .larger-three-eighths {
    width: 37.5%;
  }

  .larger-four-eighths {
    width: 50%;
  }

  .larger-five-eighths {
    width: 62.5%;
  }

  .larger-six-eighths {
    width: 75%;
  }

  .larger-seven-eighths {
    width: 87.5%;
  }

  /*
      Ninths
      ------
  */
  .larger-one-ninth {
    width: 11.1111111%;
  }

  .larger-two-ninths {
    width: 22.2222222%;
  }

  .larger-four-ninths {
    width: 44.4444444%;
  }

  .larger-five-ninths {
    width: 55.5555555%;
  }

  .larger-seven-ninths {
    width: 77.7777777%;
  }

  .larger-eight-ninths {
    width: 88.8888888%;
  }

  /*
      Tenths
      ------
  */
  .larger-one-tenth, .larger-w-10 {
    width: 10%;
  }

  .larger-two-tenths, .larger-w-20 {
    width: 20%;
  }

  .larger-three-tenths, .larger-w-30 {
    width: 30%;
  }

  .larger-four-tenths, .larger-w-40 {
    width: 40%;
  }

  .larger-five-tenths, .larger-w-50 {
    width: 50%;
  }

  .larger-six-tenths, .larger-w-60 {
    width: 60%;
  }

  .larger-seven-tenths, .larger-w-70 {
    width: 70%;
  }

  .larger-eight-tenths, .larger-w-80 {
    width: 80%;
  }

  .larger-nine-tenths, .larger-w-90 {
    width: 90%;
  }

  .larger-ten-tenths, .larger-w-100 {
    width: 100%;
  }

  /*
      Twelfths
      --------
  */
  .larger-one-twelfth {
    width: 8.3333333%;
  }

  .larger-two-twelfths {
    width: 16.6666666%;
  }

  .larger-three-twelfths {
    width: 25%;
  }

  .larger-four-twelfths {
    width: 33.3333333%;
  }

  .larger-five-twelfths {
    width: 41.6666666%;
  }

  .larger-six-twelfths {
    width: 50%;
  }

  .larger-seven-twelfths {
    width: 58.3333333%;
  }

  .larger-eight-twelfths {
    width: 66.6666666%;
  }

  .larger-nine-twelfths {
    width: 75%;
  }

  .larger-ten-twelfths {
    width: 83.3333333%;
  }

  .larger-eleven-twelfths {
    width: 91.6666666%;
  }

  /*
      Twentieth
      --------
  */
  .larger-one-twentieth {
    width: 5%;
  }

  .larger-two-twentieths {
    width: 10%;
  }

  .larger-three-twentieths {
    width: 15%;
  }

  .larger-four-twentieths {
    width: 20%;
  }

  .larger-five-twentieths {
    width: 25%;
  }

  .larger-six-twentieths {
    width: 30%;
  }

  .larger-seven-twentieths {
    width: 35%;
  }

  .larger-eight-twentieths {
    width: 40%;
  }

  .larger-nine-twentieths {
    width: 45%;
  }

  .larger-ten-twentieths {
    width: 50%;
  }

  .larger-eleven-twentieths {
    width: 55%;
  }

  .larger-twelve-twentieths {
    width: 60%;
  }

  .larger-thirteen-twentieths {
    width: 65%;
  }
}
@media (min-width: 64.125em) {
  /*
      Whole
      -----
  */
  .largest-one-whole {
    width: 100%;
  }

  /*
      Halves
      ------
  */
  .largest-one-half {
    width: 50%;
  }

  /*
      Thirds
      ------
  */
  .largest-one-third {
    width: 33.3333333%;
  }

  .largest-two-thirds {
    width: 66.6666666%;
  }

  /*
      Quarters
      --------
  */
  .largest-one-quarter {
    width: 25%;
  }

  .largest-two-quarters {
    width: 50%;
  }

  .largest-three-quarters {
    width: 75%;
  }

  /*
      Fifths
      ------
  */
  .largest-one-fifth {
    width: 20%;
  }

  .largest-two-fifths {
    width: 40%;
  }

  .largest-three-fifths {
    width: 60%;
  }

  .largest-four-fifths {
    width: 80%;
  }

  /*
      Sixths
      ------
  */
  .largest-one-sixth {
    width: 16.6666666%;
  }

  .largest-two-sixths {
    width: 33.3333333%;
  }

  .largest-three-sixths {
    width: 50%;
  }

  .largest-four-sixths {
    width: 66.6666666%;
  }

  .largest-five-sixths {
    width: 83.3333333%;
  }

  /*
      Eighths
      -------
  */
  .largest-one-eighth {
    width: 12.5%;
  }

  .largest-two-eighths {
    width: 25%;
  }

  .largest-three-eighths {
    width: 37.5%;
  }

  .largest-four-eighths {
    width: 50%;
  }

  .largest-five-eighths {
    width: 62.5%;
  }

  .largest-six-eighths {
    width: 75%;
  }

  .largest-seven-eighths {
    width: 87.5%;
  }

  /*
      Ninths
      ------
  */
  .largest-one-ninth {
    width: 11.1111111%;
  }

  .largest-two-ninths {
    width: 22.2222222%;
  }

  .largest-four-ninths {
    width: 44.4444444%;
  }

  .largest-five-ninths {
    width: 55.5555555%;
  }

  .largest-seven-ninths {
    width: 77.7777777%;
  }

  .largest-eight-ninths {
    width: 88.8888888%;
  }

  /*
      Tenths
      ------
  */
  .largest-one-tenth, .largest-w-10 {
    width: 10%;
  }

  .largest-two-tenths, .largest-w-20 {
    width: 20%;
  }

  .largest-three-tenths, .largest-w-30 {
    width: 30%;
  }

  .largest-four-tenths, .largest-w-40 {
    width: 40%;
  }

  .largest-five-tenths, .largest-w-50 {
    width: 50%;
  }

  .largest-six-tenths, .largest-w-60 {
    width: 60%;
  }

  .largest-seven-tenths, .largest-w-70 {
    width: 70%;
  }

  .largest-eight-tenths, .largest-w-80 {
    width: 80%;
  }

  .largest-nine-tenths, .largest-w-90 {
    width: 90%;
  }

  .largest-ten-tenths, .largest-w-100 {
    width: 100%;
  }

  /*
      Twelfths
      --------
  */
  .largest-one-twelfth {
    width: 8.3333333%;
  }

  .largest-two-twelfths {
    width: 16.6666666%;
  }

  .largest-three-twelfths {
    width: 25%;
  }

  .largest-four-twelfths {
    width: 33.3333333%;
  }

  .largest-five-twelfths {
    width: 41.6666666%;
  }

  .largest-six-twelfths {
    width: 50%;
  }

  .largest-seven-twelfths {
    width: 58.3333333%;
  }

  .largest-eight-twelfths {
    width: 66.6666666%;
  }

  .largest-nine-twelfths {
    width: 75%;
  }

  .largest-ten-twelfths {
    width: 83.3333333%;
  }

  .largest-eleven-twelfths {
    width: 91.6666666%;
  }

  /*
      Twentieth
      --------
  */
  .largest-one-twentieth {
    width: 5%;
  }

  .largest-two-twentieths {
    width: 10%;
  }

  .largest-three-twentieths {
    width: 15%;
  }

  .largest-four-twentieths {
    width: 20%;
  }

  .largest-five-twentieths {
    width: 25%;
  }

  .largest-six-twentieths {
    width: 30%;
  }

  .largest-seven-twentieths {
    width: 35%;
  }

  .largest-eight-twentieths {
    width: 40%;
  }

  .largest-nine-twentieths {
    width: 45%;
  }

  .largest-ten-twentieths {
    width: 50%;
  }

  .largest-eleven-twentieths {
    width: 55%;
  }

  .largest-twelve-twentieths {
    width: 60%;
  }

  .largest-thirteen-twentieths {
    width: 65%;
  }
}
@media (min-width: 96.875em) {
  /*
      Whole
      -----
  */
  .largester-one-whole {
    width: 100%;
  }

  /*
      Halves
      ------
  */
  .largester-one-half {
    width: 50%;
  }

  /*
      Thirds
      ------
  */
  .largester-one-third {
    width: 33.3333333%;
  }

  .largester-two-thirds {
    width: 66.6666666%;
  }

  /*
      Quarters
      --------
  */
  .largester-one-quarter {
    width: 25%;
  }

  .largester-two-quarters {
    width: 50%;
  }

  .largester-three-quarters {
    width: 75%;
  }

  /*
      Fifths
      ------
  */
  .largester-one-fifth {
    width: 20%;
  }

  .largester-two-fifths {
    width: 40%;
  }

  .largester-three-fifths {
    width: 60%;
  }

  .largester-four-fifths {
    width: 80%;
  }

  /*
      Sixths
      ------
  */
  .largester-one-sixth {
    width: 16.6666666%;
  }

  .largester-two-sixths {
    width: 33.3333333%;
  }

  .largester-three-sixths {
    width: 50%;
  }

  .largester-four-sixths {
    width: 66.6666666%;
  }

  .largester-five-sixths {
    width: 83.3333333%;
  }

  /*
      Eighths
      -------
  */
  .largester-one-eighth {
    width: 12.5%;
  }

  .largester-two-eighths {
    width: 25%;
  }

  .largester-three-eighths {
    width: 37.5%;
  }

  .largester-four-eighths {
    width: 50%;
  }

  .largester-five-eighths {
    width: 62.5%;
  }

  .largester-six-eighths {
    width: 75%;
  }

  .largester-seven-eighths {
    width: 87.5%;
  }

  /*
      Ninths
      ------
  */
  .largester-one-ninth {
    width: 11.1111111%;
  }

  .largester-two-ninths {
    width: 22.2222222%;
  }

  .largester-four-ninths {
    width: 44.4444444%;
  }

  .largester-five-ninths {
    width: 55.5555555%;
  }

  .largester-seven-ninths {
    width: 77.7777777%;
  }

  .largester-eight-ninths {
    width: 88.8888888%;
  }

  /*
      Tenths
      ------
  */
  .largester-one-tenth, .largester-w-10 {
    width: 10%;
  }

  .largester-two-tenths, .largester-w-20 {
    width: 20%;
  }

  .largester-three-tenths, .largester-w-30 {
    width: 30%;
  }

  .largester-four-tenths, .largester-w-40 {
    width: 40%;
  }

  .largester-five-tenths, .largester-w-50 {
    width: 50%;
  }

  .largester-six-tenths, .largester-w-60 {
    width: 60%;
  }

  .largester-seven-tenths, .largester-w-70 {
    width: 70%;
  }

  .largester-eight-tenths, .largester-w-80 {
    width: 80%;
  }

  .largester-nine-tenths, .largester-w-90 {
    width: 90%;
  }

  .largester-ten-tenths, .largester-w-100 {
    width: 100%;
  }

  /*
      Twelfths
      --------
  */
  .largester-one-twelfth {
    width: 8.3333333%;
  }

  .largester-two-twelfths {
    width: 16.6666666%;
  }

  .largester-three-twelfths {
    width: 25%;
  }

  .largester-four-twelfths {
    width: 33.3333333%;
  }

  .largester-five-twelfths {
    width: 41.6666666%;
  }

  .largester-six-twelfths {
    width: 50%;
  }

  .largester-seven-twelfths {
    width: 58.3333333%;
  }

  .largester-eight-twelfths {
    width: 66.6666666%;
  }

  .largester-nine-twelfths {
    width: 75%;
  }

  .largester-ten-twelfths {
    width: 83.3333333%;
  }

  .largester-eleven-twelfths {
    width: 91.6666666%;
  }

  /*
      Twentieth
      --------
  */
  .largester-one-twentieth {
    width: 5%;
  }

  .largester-two-twentieths {
    width: 10%;
  }

  .largester-three-twentieths {
    width: 15%;
  }

  .largester-four-twentieths {
    width: 20%;
  }

  .largester-five-twentieths {
    width: 25%;
  }

  .largester-six-twentieths {
    width: 30%;
  }

  .largester-seven-twentieths {
    width: 35%;
  }

  .largester-eight-twentieths {
    width: 40%;
  }

  .largester-nine-twentieths {
    width: 45%;
  }

  .largester-ten-twentieths {
    width: 50%;
  }

  .largester-eleven-twentieths {
    width: 55%;
  }

  .largester-twelve-twentieths {
    width: 60%;
  }

  .largester-thirteen-twentieths {
    width: 65%;
  }
}
/*  ==========================================================================
    Helpers:Layout
    ========================================================================== */
/*
    Contain floats
    --------------

    Make an element expand to contain floated children.
    Uses pseudo-elements (micro clearfix).

    1. The space content is one way to avoid an Opera bug when the
        `contenteditable` attribute is included anywhere else in the document.
        Otherwise it causes space to appear at the top and bottom of the
        element.
    2. The use of `table` rather than `block` is only necessary if using
        `:before` to contain the top-margins of child elements.
 */
.cf:before, .cf:after,
.group:before,
.group:after {
  content: "";
  /* 1 */
  display: table;
  /* 2 */
}
.cf:after,
.group:after {
  clear: both;
}

/*
    Floats
*/
.float--right {
  float: right !important;
}

.float--left {
  float: left !important;
}

/*  ==========================================================================
    Helpers:Rendering
    ========================================================================== */
.will-change {
  /*
      Promote item to it's own layer

      http://aerotwist.com/blog/on-translate3d-and-layer-creation-hacks/

      at some point change this to the new property will-change
      http://aerotwist.com/blog/bye-bye-layer-hacks/
  */
  transform: translateZ(0);
}

/*  ==========================================================================
    Generic:Helpers:Spacing
    ========================================================================== */
/*
    add/remove margins
    ------------------
*/
.push {
  margin: 1.6666666667em !important;
}

.push--top {
  margin-top: 1.6666666667em !important;
}

.push--right {
  margin-right: 1.6666666667em !important;
}

.push--bottom {
  margin-bottom: 1.6666666667em !important;
}

.push--left {
  margin-left: 1.6666666667em !important;
}

.push--ends {
  margin-top: 1.6666666667em !important;
  margin-bottom: 1.6666666667em !important;
}

.push--sides {
  margin-right: 1.6666666667em !important;
  margin-left: 1.6666666667em !important;
}

.push-half {
  margin: 0.8333333333em !important;
}

.push-half--top {
  margin-top: 0.8333333333em !important;
}

.push-half--right {
  margin-right: 0.8333333333em !important;
}

.push-half--bottom {
  margin-bottom: 0.8333333333em !important;
}

.push-half--left {
  margin-left: 0.8333333333em !important;
}

.push-half--ends {
  margin-top: 0.8333333333em !important;
  margin-bottom: 0.8333333333em !important;
}

.push-half--sides {
  margin-right: 0.8333333333em !important;
  margin-left: 0.8333333333em !important;
}

.flush {
  margin: 0 !important;
}

.flush--top {
  margin-top: 0 !important;
}

.flush--right {
  margin-right: 0 !important;
}

.flush--bottom {
  margin-bottom: 0 !important;
}

.flush--left {
  margin-left: 0 !important;
}

.flush--ends {
  margin-top: 0 !important;
  margin-bottom: 0 !important;
}

.flush--sides {
  margin-right: 0 !important;
  margin-left: 0 !important;
}

/*
    remove paddings
    ---------------
    I don't find myself wanting to add padding via a helper class so i only have classes for removing
*/
.hard {
  padding: 0 !important;
}

.hard--top {
  padding-top: 0 !important;
}

.hard--right {
  padding-right: 0 !important;
}

.hard--bottom {
  padding-bottom: 0 !important;
}

.hard--left {
  padding-left: 0 !important;
}

.hard--ends {
  padding-top: 0 !important;
  padding-bottom: 0 !important;
}

.hard--sides {
  padding-right: 0 !important;
  padding-left: 0 !important;
}

/* ==========================================================================
   Helpers:State
   ========================================================================== */
/*
    Indicate that interaction will result in an (in)action.
*/
.is-actionable {
  cursor: pointer !important;
}

.is-disabled {
  cursor: default !important;
}

.is-draggable {
  cursor: move !important;
}

/*
    Completely remove from the flow and screen readers.
*/
.is-hidden {
  display: none !important;
  visibility: hidden !important;
}

/*
    Completely remove from the flow but leave available to screen readers.
*/
.is-hidden-visually {
  position: absolute;
  overflow: hidden;
  width: 1px;
  height: 1px;
  padding: 0;
  border: 0;
  clip: rect(1px, 1px, 1px, 1px);
}

/*
    Control visibility without affecting flow.
*/
.is-invisible {
  visibility: hidden !important;
}

.is-visible {
  visibility: visible !important;
}

/*  ==========================================================================
    Generic:Helpers:Text
    ========================================================================== */
/*
    Text alignment
*/
.text--left {
  text-align: left !important;
}

.text--center {
  text-align: center !important;
}

.text--right {
  text-align: right !important;
}

/*
    Apply capital case to an element (usually a `strong`).
*/
.caps {
  text-transform: uppercase !important;
}

/**
 * Font weights
 */
.weight--light {
  font-weight: 300 !important;
}

.weight--normal {
  font-weight: 400 !important;
}

.weight--semibold {
  font-weight: 600 !important;
}

.weight--bold {
  font-weight: bold !important;
}

/*
    Text truncation

    Prevent text from wrapping onto multiple lines, and truncate with an
    ellipsis.

    1.  Ensure that the node has a maximum width after which truncation can
        occur.
    2.  Fix for IE 8/9 if `word-wrap: break-word` is in effect on ancestor
        nodes.
*/
.text--truncate {
  max-width: 100%;
  /* 1 */
  overflow: hidden !important;
  text-overflow: ellipsis !important;
  white-space: nowrap !important;
  word-wrap: normal !important;
  /* 2 */
}

/*
    font sizes
*/
.alpha {
  font-size: 1.6666666667em;
}

.beta {
  font-size: 1.3888888889em;
}

.gamma {
  font-size: 1.2222222222em;
}

.delta {
  font-size: 1.1111111111em;
}

.epsilon {
  font-size: 1em;
}

.zeta {
  font-size: 0.8888888889em;
}

/* 9 */
.cta-boxes--box--title {
  margin-bottom: 4px;
}

.cta-boxes--text {
  color: #999999;
  font-size: 0.8888888889em;
  font-family: "objektiv-mk1", sans-serif;
}

.cta-boxes--box--inner--content {
  flex: 1 0 auto;
}

.generic-content {
  text-align: left;
  padding: 2.5% 0;
  background-size: cover;
  background-repeat: no-repeat;
  position: relative;
}
.generic-content.image {
  color: #FFF7EB;
  padding: 3% 0;
}
.generic-content.image h1, .generic-content.image h2, .generic-content.image h3, .generic-content.image h4, .generic-content.image h5, .generic-content.image h6, .generic-content.image .generic-content--title {
  color: #FFF7EB;
  font-weight: 300;
}
.generic-content.image .generic-content--title {
  font-size: 25px;
  text-transform: uppercase;
}
.generic-content.image .generic-content--background-image {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
}
.generic-content.image .generic-content--background-image img {
  width: 100%;
  height: 100%;
}
.generic-content .generic-content--content {
  position: relative;
  z-index: 2;
}
.generic-content .generic-content--title {
  font-size: 25px;
}
.generic-content .generic-content--inner-content strong {
  color: blue;
}
.generic-content .generic-content--inner-content p {
  margin-bottom: 2%;
  line-height: 30px;
  font-weight: 100;
  font-size: 22px;
}
@media only screen and (max-width: 34.375em) {
  .generic-content .generic-content--inner-content p {
    font-size: 1rem;
    line-height: 1.3rem;
  }
}

.grid--youtube-video {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 */
  padding-top: 25px;
  height: 0;
}
.grid--youtube-video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.grid--image-gallery img {
  width: 33.3%;
  height: 124.3px;
  margin: 0;
  padding: 0;
}

.grid--standard-content {
  padding: 3% 9% 6%;
  text-align: center;
  color: #fff;
}
.grid--standard-content h1, .grid--standard-content h2, .grid--standard-content h3, .grid--standard-content h4, .grid--standard-content h5, .grid--standard-content h6, .grid--standard-content .grid--standard-content--title {
  text-transform: uppercase;
  max-width: 320px;
  display: inline-block;
}

.set_width_layouts {
  padding: 10% 9px;
  background: #fff;
}
@media (min-width: 46.875em) {
  .set_width_layouts {
    padding: 6% 37px;
  }
}
@media (min-width: 56.25em) {
  .set_width_layouts {
    padding: 2% 0;
  }
}
@media (min-width: 64.125em) {
  .set_width_layouts {
    padding: 30px 0;
  }
}
.set_width_layouts.set_width_layouts--grey {
  background: #FFF7EB;
}
.set_width_layouts.set_width_layouts--grey .set_width_layouts__title {
  color: #d3d3d3;
  font-size: 24px;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 400;
  text-transform: uppercase;
  margin: 0 0 15%;
  letter-spacing: 0.3em;
}
@media (min-width: 46.875em) {
  .set_width_layouts.set_width_layouts--grey .set_width_layouts__title {
    margin: 0 0 10%;
  }
}
@media (min-width: 56.25em) {
  .set_width_layouts.set_width_layouts--grey .set_width_layouts__title {
    margin: 0 0 5%;
  }
}
.set_width_layouts.set_width_layouts--grey .set_width_layouts__title::after {
  content: "";
  display: block;
  margin: 10px auto;
  width: 200px;
  height: 1px;
  background: #d3d3d3;
}
@media (min-width: 56.25em) {
  .set_width_layouts .set_width_layout.larger-one-half:nth-child(even) {
    border-left: 1px solid #d3d3d3;
  }
}
.set_width_layouts .set_width_layout .set_width_layout--standard-content--text p {
  padding-bottom: 10px;
}

.set_width_layout--video {
  position: relative;
  padding-top: 0;
}
.set_width_layout--video iframe {
  position: relative;
  width: 100%;
  display: block;
}
.set_width_layout--video .inner {
  width: 100%;
  background: #fff;
  display: flex;
  flex-direction: column;
}
.set_width_layout--video .inner .inner__content {
  flex: 1 0 auto;
}

.set_width_layout--image-gallery {
  font-size: 0;
}
.set_width_layout--image-gallery img {
  width: 33.3%;
  height: 128.3px;
  margin: 0;
  padding: 2px;
}

.set_width_layout--standard-content {
  margin-bottom: 35px;
}
.set_width_layout--standard-content:last-of-type {
  margin-bottom: 0;
}
@media (min-width: 46.875em) {
  .set_width_layout--standard-content.large-one-half:nth-child(odd) {
    border-right: 1px solid #FFF7EB;
  }
}

.set_width_layout--standard-content, .set_width_layout--links {
  position: relative;
}
.set_width_layout--standard-content .set_width_layout--standard-content--inner, .set_width_layout--links .set_width_layout--standard-content--inner {
  width: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 1% 0;
}
.set_width_layout--standard-content .set_width_layout--standard-content--inner .set_width_layout--standard-content--inner__content, .set_width_layout--links .set_width_layout--standard-content--inner .set_width_layout--standard-content--inner__content {
  flex: 1 0 auto;
  padding: 0px 22px;
}
.set_width_layout--standard-content .set_width_layout--standard-content--bg-image, .set_width_layout--links .set_width_layout--standard-content--bg-image {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 0;
}
.set_width_layout--standard-content h1, .set_width_layout--standard-content h2, .set_width_layout--standard-content h3, .set_width_layout--standard-content h4, .set_width_layout--standard-content h5, .set_width_layout--standard-content h6, .set_width_layout--standard-content .set_width_layout--standard-content--title, .set_width_layout--links h1, .set_width_layout--links h2, .set_width_layout--links h3, .set_width_layout--links h4, .set_width_layout--links h5, .set_width_layout--links h6, .set_width_layout--links .set_width_layout--standard-content--title {
  display: block;
  line-height: 1.2em;
  margin: 0.5em 0;
}
.set_width_layout--standard-content .set_width_layout--standard-content--text, .set_width_layout--links .set_width_layout--standard-content--text {
  font-size: 1em;
  font-family: "objektiv-mk1", sans-serif;
}
.set_width_layout--standard-content .set_width_layout--standard-content--text h1, .set_width_layout--standard-content .set_width_layout--standard-content--text h2, .set_width_layout--links .set_width_layout--standard-content--text h1, .set_width_layout--links .set_width_layout--standard-content--text h2 {
  font-family: "objektiv-mk1", sans-serif;
  font-size: 36px;
  color: #FFF7EB;
  margin: 0;
  padding-bottom: 30px;
}
@media (min-width: 46.875em) {
  .set_width_layout--standard-content .set_width_layout--standard-content--text h1, .set_width_layout--standard-content .set_width_layout--standard-content--text h2, .set_width_layout--links .set_width_layout--standard-content--text h1, .set_width_layout--links .set_width_layout--standard-content--text h2 {
    font-size: 48px;
  }
}
.set_width_layout--standard-content .set_width_layout--standard-content--text h1:after, .set_width_layout--standard-content .set_width_layout--standard-content--text h2:after, .set_width_layout--links .set_width_layout--standard-content--text h1:after, .set_width_layout--links .set_width_layout--standard-content--text h2:after {
  content: "";
  display: block;
  width: 100%;
  border-top: 1px solid #d3d3d3;
  margin-top: 30px;
}
.set_width_layouts--grey .set_width_layout--standard-content .set_width_layout--standard-content--text h1:after, .set_width_layouts--grey .set_width_layout--standard-content .set_width_layout--standard-content--text h2:after, .set_width_layouts--grey .set_width_layout--links .set_width_layout--standard-content--text h1:after, .set_width_layouts--grey .set_width_layout--links .set_width_layout--standard-content--text h2:after {
  border-top: 1px solid #d3d3d3;
}
.home .set_width_layout--standard-content .set_width_layout--standard-content--text h1, .home .set_width_layout--standard-content .set_width_layout--standard-content--text h2, .home .set_width_layout--links .set_width_layout--standard-content--text h1, .home .set_width_layout--links .set_width_layout--standard-content--text h2 {
  padding-bottom: 0;
}
.home .set_width_layout--standard-content .set_width_layout--standard-content--text h1:after, .home .set_width_layout--standard-content .set_width_layout--standard-content--text h2:after, .home .set_width_layout--links .set_width_layout--standard-content--text h1:after, .home .set_width_layout--links .set_width_layout--standard-content--text h2:after {
  display: none;
}
.set_width_layout--standard-content.brand-blue .set_width_layout--standard-content--inner, .set_width_layout--links.brand-blue .set_width_layout--standard-content--inner {
  background: #FFF7EB;
  color: #fff;
}
.set_width_layout--standard-content.brand-blue a, .set_width_layout--links.brand-blue a {
  color: #fff;
}
.set_width_layout--standard-content.brand-blue .set_width_layout--standard-content--inner, .set_width_layout--links.brand-blue .set_width_layout--standard-content--inner {
  padding: 1% 0 2% 0;
}

.set_width_layouts .cta-boxes--box--title {
  margin-top: 0;
  color: #FFF7EB;
}
.set_width_layouts .cta-boxes--box--inner {
  width: 100%;
  position: relative;
  background: #fff;
  display: flex;
  flex-direction: column;
}
.set_width_layouts .cta-boxes--box--inner img {
  width: 100%;
  display: block;
}
.set_width_layouts.set_width_layouts--white .cta-boxes--box--inner {
  background: #d3d3d3;
}
.set_width_layouts .cta-boxes--box--inner--content {
  padding: 12px 18px 18px;
}

.blog .cta-boxes--box--inner--content, .archive .cta-boxes--box--inner--content {
  width: 100%;
  position: relative;
  background: #d3d3d3;
}

.set_width_layout--testimonials {
  margin-bottom: 35px;
}
.set_width_layout--testimonials .testimonials {
  padding-top: 14px;
  padding-bottom: 60px;
}
@media (min-width: 46.875em) {
  .set_width_layout--testimonials .testimonials {
    padding-bottom: 40px;
  }
}
.set_width_layout--testimonials .slick-track {
  display: flex;
}
.set_width_layout--testimonials .slick-next, .set_width_layout--testimonials .slick-prev {
  top: auto;
  bottom: 0;
  position: absolute;
  width: 50%;
  border: 1px solid #fff;
  text-indent: 0;
  transition: all 0.2s;
  text-transform: lowercase;
  font-size: 19px;
}
.set_width_layout--testimonials .slick-next:hover, .set_width_layout--testimonials .slick-prev:hover {
  background: #fff;
  color: #d3d3d3;
}
.set_width_layout--testimonials .slick-next:hover:after, .set_width_layout--testimonials .slick-prev:hover:after {
  background: url("/content/themes/standard/images/sprite.png") 0 0 no-repeat;
}
.set_width_layout--testimonials .slick-next:after, .set_width_layout--testimonials .slick-prev:after {
  content: "";
  display: block;
  position: absolute;
  bottom: 14px;
  right: 14px;
  height: 15px;
  width: 8px;
  background: url("/content/themes/standard/images/sprite.png") -10px 0 no-repeat;
}
.set_width_layout--testimonials .slick-next.slick-next, .set_width_layout--testimonials .slick-prev.slick-next {
  right: 0;
  border-left: 0;
  text-align: left;
  padding-left: 20px;
}
.set_width_layout--testimonials .slick-next.slick-prev, .set_width_layout--testimonials .slick-prev.slick-prev {
  text-align: right;
  padding-right: 20px;
  left: 0;
}
.set_width_layout--testimonials .slick-next.slick-prev:after, .set_width_layout--testimonials .slick-prev.slick-prev:after {
  right: auto;
  left: 14px;
  transform: rotate(180deg);
}
@media (min-width: 46.875em) {
  .set_width_layout--testimonials.large-one-half:nth-child(odd) {
    border-right: 1px solid #d3d3d3;
  }
}

.set_width_layout--testimonials, .set_width_layout--links {
  position: relative;
}
.set_width_layout--testimonials .set_width_layout--testimonials--inner, .set_width_layout--links .set_width_layout--testimonials--inner {
  width: 100%;
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  padding: 1% 0;
}
.set_width_layout--testimonials .set_width_layout--testimonials--inner .set_width_layout--testimonials--inner__content, .set_width_layout--links .set_width_layout--testimonials--inner .set_width_layout--testimonials--inner__content {
  flex: 1 0 auto;
  padding: 0px 22px;
}
.set_width_layout--testimonials .set_width_layout--testimonials--name, .set_width_layout--links .set_width_layout--testimonials--name {
  font-size: 0.8rem;
  float: right;
  margin-top: 5%;
  text-align: right;
}
.set_width_layout--testimonials .set_width_layout--testimonials--bg-image, .set_width_layout--links .set_width_layout--testimonials--bg-image {
  position: absolute;
  top: 20px;
  left: 20px;
  z-index: 0;
}
.set_width_layout--testimonials h1, .set_width_layout--testimonials h2, .set_width_layout--testimonials h3, .set_width_layout--testimonials h4, .set_width_layout--testimonials h5, .set_width_layout--testimonials h6, .set_width_layout--testimonials .set_width_layout--testimonials--title, .set_width_layout--links h1, .set_width_layout--links h2, .set_width_layout--links h3, .set_width_layout--links h4, .set_width_layout--links h5, .set_width_layout--links h6, .set_width_layout--links .set_width_layout--testimonials--title {
  display: block;
  line-height: 1.2em;
  margin: 0.5em 0;
}
.set_width_layout--testimonials .set_width_layout--testimonials--text, .set_width_layout--links .set_width_layout--testimonials--text {
  font-size: 1em;
  font-family: "objektiv-mk1", sans-serif;
  position: relative;
  text-indent: 35px;
}
.set_width_layout--testimonials .set_width_layout--testimonials--text::before, .set_width_layout--testimonials .set_width_layout--testimonials--text::after, .set_width_layout--links .set_width_layout--testimonials--text::before, .set_width_layout--links .set_width_layout--testimonials--text::after {
  text-indent: 0;
  line-height: 1;
  font-family: FontAwesome;
  font-size: 3.3em;
  display: inline;
  position: absolute;
}
.set_width_layout--testimonials .set_width_layout--testimonials--text::before, .set_width_layout--links .set_width_layout--testimonials--text::before {
  content: "“";
  top: -7px;
  left: 0px;
}
.set_width_layout--testimonials .set_width_layout--testimonials--text p::after, .set_width_layout--links .set_width_layout--testimonials--text p::after {
  content: "”";
  position: relative;
  display: inline;
  font-size: 2.5em;
  line-height: 0em;
  height: 25px;
  top: 18px;
  left: 7px;
}
.set_width_layout--testimonials .set_width_layout--testimonials--text h1, .set_width_layout--testimonials .set_width_layout--testimonials--text h2, .set_width_layout--links .set_width_layout--testimonials--text h1, .set_width_layout--links .set_width_layout--testimonials--text h2 {
  font-family: "objektiv-mk1", sans-serif;
  font-size: 36px;
  color: #FFF7EB;
  margin: 0;
  padding-bottom: 30px;
}
@media (min-width: 46.875em) {
  .set_width_layout--testimonials .set_width_layout--testimonials--text h1, .set_width_layout--testimonials .set_width_layout--testimonials--text h2, .set_width_layout--links .set_width_layout--testimonials--text h1, .set_width_layout--links .set_width_layout--testimonials--text h2 {
    font-size: 48px;
  }
}
.set_width_layout--testimonials .set_width_layout--testimonials--text h1:after, .set_width_layout--testimonials .set_width_layout--testimonials--text h2:after, .set_width_layout--links .set_width_layout--testimonials--text h1:after, .set_width_layout--links .set_width_layout--testimonials--text h2:after {
  content: "";
  display: block;
  width: 100%;
  border-top: 1px solid #d3d3d3;
  margin-top: 30px;
}
.home .set_width_layout--testimonials .set_width_layout--testimonials--text h1, .home .set_width_layout--testimonials .set_width_layout--testimonials--text h2, .home .set_width_layout--links .set_width_layout--testimonials--text h1, .home .set_width_layout--links .set_width_layout--testimonials--text h2 {
  padding-bottom: 0;
}
.home .set_width_layout--testimonials .set_width_layout--testimonials--text h1:after, .home .set_width_layout--testimonials .set_width_layout--testimonials--text h2:after, .home .set_width_layout--links .set_width_layout--testimonials--text h1:after, .home .set_width_layout--links .set_width_layout--testimonials--text h2:after {
  display: none;
}
.set_width_layout--testimonials.brand-blue, .set_width_layout--links.brand-blue {
  position: relative;
}
.set_width_layout--testimonials.brand-blue .set_width_layout--testimonials--inner__bg, .set_width_layout--links.brand-blue .set_width_layout--testimonials--inner__bg {
  background: #FFF7EB;
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  z-index: -1;
}
.set_width_layout--testimonials.brand-blue .set_width_layout--testimonials--inner, .set_width_layout--links.brand-blue .set_width_layout--testimonials--inner {
  color: #fff;
}
.set_width_layout--testimonials.brand-blue a, .set_width_layout--links.brand-blue a {
  color: #fff;
}
.set_width_layout--testimonials.brand-blue .set_width_layout--testimonials--inner, .set_width_layout--links.brand-blue .set_width_layout--testimonials--inner {
  padding: 1% 0 2% 0;
}

.iframe-section iframe {
  width: 100%;
  border: none;
  height: 1080px;
}

section.newsletter {
  background-size: cover !important;
  color: #fff;
  padding: 3% 34px 10%;
}
@media (min-width: 56.25em) {
  section.newsletter {
    padding: 3% 0 3%;
  }
}
section.newsletter .inner {
  max-width: 960px;
  margin: 0 auto;
}
section.newsletter h2 {
  font-size: 36px;
  font-weight: 100;
  padding: 0% 0 2%;
}
section.newsletter h2 strong {
  display: block;
}
@media (min-width: 46.875em) {
  section.newsletter h2 strong {
    display: inline-block;
  }
}
section.newsletter .control-group {
  margin-right: 2%;
}
@media (min-width: 46.875em) {
  section.newsletter .control-group {
    display: inline-block;
  }
}
section.newsletter .control-group input {
  border: none;
  font-size: 15px;
  font-family: "objektiv-mk1", sans-serif;
  padding: 3% 3% 2% 3%;
  width: 100%;
  margin-bottom: 19px;
  color: #FFF7EB;
}
@media (min-width: 46.875em) {
  section.newsletter .control-group input {
    margin-bottom: 0;
    width: 300px;
  }
}
section.newsletter .btn {
  font-family: "objektiv-mk1", sans-serif;
  margin-top: 5.5%;
  padding: 0.8% 5% 1% 2%;
  text-transform: none;
  color: #fff;
  border: solid 1px #fff;
  background: none;
  position: relative;
  width: 132px;
}
@media (min-width: 46.875em) {
  section.newsletter .btn {
    width: auto;
  }
}

body.occasions_page .divider {
  margin: 30px auto;
}
body.occasions_page .block.standard-text-block {
  padding-top: 30px;
}
body.occasions_page .block.standard-text-block > h2 {
  margin-bottom: 0;
}
body.occasions_page .block.standard-text-block > .divider {
  margin: 30px auto;
}
body.occasions_page .block.standard-text-block > p {
  margin: 30px auto;
}
body.occasions_page .block.properties-main-wrap .properties-list ul li {
  margin-bottom: 30px;
}
body.occasions_page .block.properties-main-wrap .properties-list ul li:nth-child(n+11) {
  display: none;
}
@media (min-width: 46.875em) {
  body.occasions_page .block.properties-main-wrap .properties-list ul li:nth-child(n+11) {
    display: list-item;
  }
}
body.occasions_page .flexible-content-wrap {
  margin-top: 0;
}
body.occasions_page .flexible-content-wrap > .standard-text-block {
  padding-top: 0;
}
body.occasions_page .flexible-content-wrap .block {
  margin-bottom: 30px;
}
body.occasions_page .flexible-content-wrap .block.properties-main-wrap, body.occasions_page .flexible-content-wrap .block.pubs_filter_wrapper {
  margin-bottom: 0;
}
body.occasions_page .pubs_filter_wrapper .title {
  padding-top: 0;
}
body.occasions_page .pubs_filter_wrapper .subtitle {
  margin-top: 30px;
}
body.occasions_page .pubs_filter_wrapper #filters {
  margin: 30px auto;
  flex-wrap: nowrap;
  gap: 0.75rem;
}
body.occasions_page .pubs_filter_wrapper .buttons_section {
  margin: 30px auto;
}
body.occasions_page .pubs_filter_wrapper .buttons_section .button_filter {
  margin-top: 0;
  margin-bottom: 10px;
}
body.occasions_page .pubs_filter_wrapper .buttons_section.buttons_section_event .button_filter {
  margin-bottom: 10px;
}
@media (min-width: 46.875em) {
  body.occasions_page .pubs_filter_wrapper .buttons_section.buttons_section_event .button_filter {
    margin-bottom: 0;
  }
}
body.occasions_page .pubs_filter_wrapper .pubs_cards_title {
  padding: 0;
  margin: 30px auto;
}
body.enquire-us_page .inner-wrap .gform_wrapper {
  width: 60%;
  margin: auto;
}

#callback_form_wrapper {
  width: 50%;
  margin: auto;
  font-family: "caslons-egyptian", sans-serif;
}
#callback_form_wrapper .gfield {
  text-align: left;
}
#callback_form_wrapper label {
  text-align: left;
}
#callback_form_wrapper input[type=submit] {
  background: #bfa775;
  border-radius: 2px;
  border-style: none;
  border-color: black;
  border-width: 0;
  color: white !important;
  font-family: Arial, "Helvetica Neue", Helvetica, sans-serif !important;
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 700;
  letter-spacing: 0;
  line-height: 1;
  white-space: normal;
  padding: 11px 16px;
  text-align: center;
  word-break: break-word;
  -ms-flex-item-align: end;
  align-self: flex-end;
  cursor: pointer;
  height: auto;
}

.second_level_property_template_wrapper .block.properties-main-wrap .post_content_wrapper {
  margin-top: 50px;
  width: 100%;
  max-width: 1088px;
  text-align: center;
}
.second_level_property_template_wrapper .block.properties-main-wrap .post_content_wrapper p {
  width: 100%;
  max-width: 1088px;
}
.second_level_property_template_wrapper .block.properties-main-wrap .section__filter {
  position: relative;
  width: 100%;
}
.second_level_property_template_wrapper .block.properties-main-wrap .section__filter.desktop-version {
  display: none;
}
@media (min-width: 56.25em) {
  .second_level_property_template_wrapper .block.properties-main-wrap .section__filter.desktop-version {
    display: block;
  }
}
.second_level_property_template_wrapper .block.properties-main-wrap .section__filter.mobile-version {
  display: block;
}
@media (min-width: 56.25em) {
  .second_level_property_template_wrapper .block.properties-main-wrap .section__filter.mobile-version {
    display: none;
  }
}
.second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-heading {
  font-family: "objektiv-mk1", sans-serif;
  color: #B9975B;
  font-weight: 800;
  font-size: 24px;
  line-height: 50px;
  letter-spacing: normal;
  width: 100%;
  text-align: center;
}
.second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter {
  margin: 17px auto 0px auto;
  max-width: calc(100% - 1.5rem);
}
@media (min-width: 34.375em) {
  .second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter {
    max-width: calc(672px + 3rem);
  }
}
@media (min-width: 56.25em) {
  .second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter {
    max-width: calc(840px + 3.75rem);
  }
}
@media (min-width: 68.75em) {
  .second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter {
    max-width: calc(1008px + 4rem);
  }
}
.second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter .search-category-button-showmore {
  cursor: pointer;
  height: 36px;
  width: 100%;
  max-width: 168px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #2D2926;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-decoration: none;
  padding: 0px;
  margin: 0 auto;
}
.second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter .search-category-button-showmore:hover {
  color: #B9975B;
}
.second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter .search-category-button-showmore {
  cursor: pointer;
  height: 36px;
  width: 100%;
  max-width: 168px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  color: #2D2926;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 14px;
  font-weight: bold;
  letter-spacing: 1.5px;
  text-decoration: underline;
  text-transform: uppercase;
  padding: 0px;
  margin: 0 auto;
}
.second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter .search-category-button-showmore:hover {
  color: #B9975B;
}
.second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter .search-category-button {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 12px;
  letter-spacing: 1.5px;
  height: 48px;
  color: #2D2926;
  background: #FFF;
  border: 1px solid #EFE6D8;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: center;
  text-transform: uppercase;
  width: calc(50% - 0.5rem);
}
@media (min-width: 34.375em) {
  .second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter .search-category-button {
    width: 168px;
  }
}
.second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter .search-category-button.selected, .second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter .search-category-button:hover {
  background: #2D2926;
  color: #FFF;
}
.second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter .search-category-button-group-container {
  width: 100%;
}
.second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter .search-category-button-group-container.hidden {
  display: none;
}
.second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter .search-category-button-group-container .search-category-button-group {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 0.75rem;
  margin-bottom: 0.75rem;
}
.second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter .search-category-button-group-container .search-category-button-group.template-2 {
  gap: 0.75rem;
}
.second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter .search-category-button-group-container .search-category-button-group.template-2 .search-category-button {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 12px;
  letter-spacing: 1.5px;
  height: 24px;
  color: #2D2926;
  background: unset;
  border: 0px;
  border-radius: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  text-decoration: none;
  text-align: left;
  text-transform: uppercase;
  width: auto;
  padding-right: 0.75rem;
  border-right: 1px solid #2D2926;
}
.second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter .search-category-button-group-container .search-category-button-group.template-2 .search-category-button.selected, .second_level_property_template_wrapper .block.properties-main-wrap .section__filter .search-filter .search-category-button-group-container .search-category-button-group.template-2 .search-category-button:hover {
  color: #B9975B;
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list h3 {
  margin-top: 50px;
  font-family: "objektiv-mk1", sans-serif;
  color: #B9975B;
  font-weight: 800;
  font-size: 24px;
  line-height: 50px;
  letter-spacing: normal;
  width: 100%;
  text-align: center;
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list h3 span {
  color: #231f20;
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul {
  display: flex;
  flex-direction: column;
  gap: 0.75rem;
}
@media (min-width: 46.875em) {
  .second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul {
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: flex-start;
  }
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul li.default-pubhotel {
  margin-bottom: 0.5rem;
  background: white;
  border-radius: 8px;
  overflow: hidden;
  width: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  margin-right: unset;
}
@media (min-width: 46.875em) {
  .second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul li.default-pubhotel {
    width: 352px;
    max-width: calc(33.33% - 0.5rem);
  }
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul li.default-pubhotel .image-wrapper {
  position: relative;
  width: 100%;
  height: 230px;
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul li.default-pubhotel .image-wrapper img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul li.default-pubhotel .image-wrapper.filter-grayscale {
  transition: all 0.5s;
  filter: grayscale(1);
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul li.default-pubhotel .image-wrapper.filter-grayscale:hover {
  filter: grayscale(0);
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul li.default-pubhotel .image-wrapper .boutique-overlay {
  display: none;
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul li.default-pubhotel .image-wrapper.is-boutique .boutique-overlay {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 35px;
  width: 157px;
  max-width: 100%;
  top: 0;
  left: 0;
  background: #B9975B;
  text-transform: uppercase;
  color: #FFF7EB;
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 12px;
  letter-spacing: 1.5px;
  border-bottom-right-radius: 8px;
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul li.default-pubhotel .property-details {
  padding: 1.5rem 1.5rem;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul li.default-pubhotel .property-details h3 {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 800;
  font-size: 18px;
  line-height: 28px;
  text-transform: uppercase;
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul li.default-pubhotel .property-details p {
  font-family: "objektiv-mk1", sans-serif;
  font-weight: 300;
  font-size: 15px;
  line-height: 25px;
  margin-top: 0.5rem;
  margin-bottom: 0px;
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul li.default-pubhotel .property-details .cta-button {
  margin-top: 0.5rem;
  background: transparent;
  width: 100%;
  display: block;
  color: #B9975B;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 12px;
  font-weight: bold;
  text-transform: uppercase;
  letter-spacing: 1.5px;
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul li.default-pubhotel .property-details .cta-button:hover {
  color: #2D2926;
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul li.default-pubhotel .property-details .buttons-wrapper {
  display: block;
  width: 100%;
  margin-top: 0.5rem;
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul li.default-pubhotel .property-details .cta-room-button {
  background: #B9975B;
  height: 36px;
  width: 100%;
  max-width: 187px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 20px) 50%;
  padding: 0px 20px 0px 20px;
  margin-top: 0.5rem;
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul li.default-pubhotel .property-details .cta-room-button:hover {
  background: #2D2926;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 20px) 50%;
  padding: 0px 20px 0px 20px;
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul li.default-pubhotel .property-details .cta-table-button {
  background: #2D2926;
  height: 36px;
  width: 100%;
  max-width: 187px;
  border-radius: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  color: #FFF;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 12px;
  font-weight: 300;
  letter-spacing: 1.5px;
  text-decoration: none;
  text-transform: uppercase;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 20px) 50%;
  padding: 0px 20px 0px 20px;
  margin-top: 0.5rem;
}
.second_level_property_template_wrapper .block.properties-main-wrap .properties-list ul li.default-pubhotel .property-details .cta-table-button:hover {
  background: #B9975B;
  background-image: url("../images/butcombe-btn-arrow.svg");
  background-repeat: no-repeat;
  background-size: 20px 12px;
  background-position: calc(100% - 20px) 50%;
  padding: 0px 20px 0px 20px;
}
.second_level_property_template_wrapper .property-dropdown-wrap h3 {
  margin-top: 50px;
  font-family: "objektiv-mk1", sans-serif;
  color: #B9975B;
  font-weight: 800;
  font-size: 24px;
  line-height: 50px;
  letter-spacing: normal;
  width: 100%;
  text-align: center;
}
.second_level_property_template_wrapper .property-dropdown-wrap h3 span {
  color: #231f20;
}
.second_level_property_template_wrapper .property-dropdown-wrap > .inner {
  width: 100%;
  max-width: 1088px;
  margin: 0 auto;
  padding: 1.5rem;
  background: #FFF;
  border-radius: 10px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
}
@media (min-width: 56.25em) {
  .second_level_property_template_wrapper .property-dropdown-wrap > .inner {
    flex-direction: row;
    gap: 1rem;
  }
}
.second_level_property_template_wrapper .property-dropdown-wrap > .inner .dropdown-block {
  max-width: 100%;
  border: 2px solid #B9975B;
  border-radius: 8px !important;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  padding: 5px;
}
@media (min-width: 56.25em) {
  .second_level_property_template_wrapper .property-dropdown-wrap > .inner .dropdown-block {
    width: 37%;
  }
}
.second_level_property_template_wrapper .property-dropdown-wrap > .inner .dropdown-block * {
  color: #231f20;
  text-decoration: none;
  font-family: "objektiv-mk1", sans-serif;
  font-size: 14px;
  font-weight: 300;
  line-height: 1;
  padding: 5px;
}
.second_level_property_template_wrapper .property-dropdown-wrap > .inner .dropdown-block .dropdown-options {
  border: 2px solid #B9975B;
}
.second_level_property_template_wrapper .property-dropdown-wrap > .inner .dropdown-block .dropdown-options span {
  padding: 5px 0;
}
.second_level_property_template_wrapper .block.standard-text-block h1 {
  margin-top: 50px;
  font-family: "objektiv-mk1", sans-serif;
  color: #B9975B;
  font-weight: 800;
  letter-spacing: normal;
  width: 100%;
  text-align: center;
}
.second_level_property_template_wrapper .block.standard-text-block h3 {
  margin-top: 50px;
  font-family: "objektiv-mk1", sans-serif;
  color: #B9975B;
  font-weight: 800;
  font-size: 24px;
  line-height: 50px;
  letter-spacing: normal;
  width: 100%;
  text-align: center;
}
.second_level_property_template_wrapper .block.standard-text-block p {
  font-family: "objektiv-mk1", sans-serif;
  letter-spacing: normal;
  text-align: center;
}

/* 10 */
/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */
.hamburger {
  padding: 15px 0;
  display: inline-block;
  cursor: pointer;
  transition-property: opacity, filter;
  transition-duration: 0.15s;
  transition-timing-function: linear;
  font: inherit;
  color: inherit;
  text-transform: none;
  background-color: transparent;
  border: 0;
  margin: 0;
  overflow: visible;
}
.hamburger:hover {
  opacity: 0.7;
}
.hamburger.is-active:hover {
  opacity: 0.7;
}
.hamburger.is-active .hamburger-inner,
.hamburger.is-active .hamburger-inner::before,
.hamburger.is-active .hamburger-inner::after {
  background-color: #B9975B;
}

.hamburger-box {
  width: 24px;
  height: 15px;
  display: inline-block;
  position: relative;
}

.hamburger-inner {
  display: block;
  top: 50%;
  margin-top: -1.5px;
}
.hamburger-inner, .hamburger-inner::before, .hamburger-inner::after {
  width: 24px;
  height: 3px;
  background-color: #B9975B;
  border-radius: 4px;
  position: absolute;
  transition-property: transform;
  transition-duration: 0.15s;
  transition-timing-function: ease;
}
.hamburger-inner::before, .hamburger-inner::after {
  content: "";
  display: block;
}
.hamburger-inner::before {
  top: -6px;
}
.hamburger-inner::after {
  bottom: -6px;
}

/*
 * 3DX
 */
.hamburger--3dx .hamburger-box {
  perspective: 48px;
}
.hamburger--3dx .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx .hamburger-inner::before, .hamburger--3dx .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(180deg);
}
.hamburger--3dx.is-active .hamburger-inner::before {
  transform: translate3d(0, 6px, 0) rotate(45deg);
}
.hamburger--3dx.is-active .hamburger-inner::after {
  transform: translate3d(0, -6px, 0) rotate(-45deg);
}

/*
 * 3DX Reverse
 */
.hamburger--3dx-r .hamburger-box {
  perspective: 48px;
}
.hamburger--3dx-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx-r .hamburger-inner::before, .hamburger--3dx-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dx-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateY(-180deg);
}
.hamburger--3dx-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 6px, 0) rotate(45deg);
}
.hamburger--3dx-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -6px, 0) rotate(-45deg);
}

/*
 * 3DY
 */
.hamburger--3dy .hamburger-box {
  perspective: 48px;
}
.hamburger--3dy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy .hamburger-inner::before, .hamburger--3dy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(-180deg);
}
.hamburger--3dy.is-active .hamburger-inner::before {
  transform: translate3d(0, 6px, 0) rotate(45deg);
}
.hamburger--3dy.is-active .hamburger-inner::after {
  transform: translate3d(0, -6px, 0) rotate(-45deg);
}

/*
 * 3DY Reverse
 */
.hamburger--3dy-r .hamburger-box {
  perspective: 48px;
}
.hamburger--3dy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy-r .hamburger-inner::before, .hamburger--3dy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg);
}
.hamburger--3dy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 6px, 0) rotate(45deg);
}
.hamburger--3dy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -6px, 0) rotate(-45deg);
}

/*
 * 3DXY
 */
.hamburger--3dxy .hamburger-box {
  perspective: 48px;
}
.hamburger--3dxy .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy .hamburger-inner::before, .hamburger--3dxy .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg);
}
.hamburger--3dxy.is-active .hamburger-inner::before {
  transform: translate3d(0, 6px, 0) rotate(45deg);
}
.hamburger--3dxy.is-active .hamburger-inner::after {
  transform: translate3d(0, -6px, 0) rotate(-45deg);
}

/*
 * 3DXY Reverse
 */
.hamburger--3dxy-r .hamburger-box {
  perspective: 48px;
}
.hamburger--3dxy-r .hamburger-inner {
  transition: transform 0.15s cubic-bezier(0.645, 0.045, 0.355, 1), background-color 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy-r .hamburger-inner::before, .hamburger--3dxy-r .hamburger-inner::after {
  transition: transform 0s 0.1s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.hamburger--3dxy-r.is-active .hamburger-inner {
  background-color: transparent !important;
  transform: rotateX(180deg) rotateY(180deg) rotateZ(-180deg);
}
.hamburger--3dxy-r.is-active .hamburger-inner::before {
  transform: translate3d(0, 6px, 0) rotate(45deg);
}
.hamburger--3dxy-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -6px, 0) rotate(-45deg);
}

/*
 * Arrow
 */
.hamburger--arrow.is-active .hamburger-inner::before {
  transform: translate3d(-4.8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
.hamburger--arrow.is-active .hamburger-inner::after {
  transform: translate3d(-4.8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
 * Arrow Right
 */
.hamburger--arrow-r.is-active .hamburger-inner::before {
  transform: translate3d(4.8px, 0, 0) rotate(45deg) scale(0.7, 1);
}
.hamburger--arrow-r.is-active .hamburger-inner::after {
  transform: translate3d(4.8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
 * Arrow Alt
 */
.hamburger--arrowalt .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(-4.8px, -6px, 0) rotate(-45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.hamburger--arrowalt.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(-4.8px, 6px, 0) rotate(45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
 * Arrow Alt Right
 */
.hamburger--arrowalt-r .hamburger-inner::before {
  transition: top 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt-r .hamburger-inner::after {
  transition: bottom 0.1s 0.1s ease, transform 0.1s cubic-bezier(0.165, 0.84, 0.44, 1);
}
.hamburger--arrowalt-r.is-active .hamburger-inner::before {
  top: 0;
  transform: translate3d(4.8px, -6px, 0) rotate(45deg) scale(0.7, 1);
  transition: top 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}
.hamburger--arrowalt-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: translate3d(4.8px, 6px, 0) rotate(-45deg) scale(0.7, 1);
  transition: bottom 0.1s ease, transform 0.1s 0.1s cubic-bezier(0.895, 0.03, 0.685, 0.22);
}

/*
 * Arrow Turn
 */
.hamburger--arrowturn.is-active .hamburger-inner {
  transform: rotate(-180deg);
}
.hamburger--arrowturn.is-active .hamburger-inner::before {
  transform: translate3d(8px, 0, 0) rotate(45deg) scale(0.7, 1);
}
.hamburger--arrowturn.is-active .hamburger-inner::after {
  transform: translate3d(8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}

/*
 * Arrow Turn Right
 */
.hamburger--arrowturn-r.is-active .hamburger-inner {
  transform: rotate(-180deg);
}
.hamburger--arrowturn-r.is-active .hamburger-inner::before {
  transform: translate3d(-8px, 0, 0) rotate(-45deg) scale(0.7, 1);
}
.hamburger--arrowturn-r.is-active .hamburger-inner::after {
  transform: translate3d(-8px, 0, 0) rotate(45deg) scale(0.7, 1);
}

/*
 * Boring
 */
.hamburger--boring .hamburger-inner, .hamburger--boring .hamburger-inner::before, .hamburger--boring .hamburger-inner::after {
  transition-property: none;
}
.hamburger--boring.is-active .hamburger-inner {
  transform: rotate(45deg);
}
.hamburger--boring.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--boring.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}

/*
 * Collapse
 */
.hamburger--collapse .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse .hamburger-inner::after {
  top: -12px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse.is-active .hamburger-inner {
  transform: translate3d(0, -6px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Collapse Reverse
 */
.hamburger--collapse-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0.13s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse-r .hamburger-inner::after {
  top: -12px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0.1s linear;
}
.hamburger--collapse-r .hamburger-inner::before {
  transition: top 0.12s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--collapse-r.is-active .hamburger-inner {
  transform: translate3d(0, -6px, 0) rotate(45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--collapse-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0.1s 0.22s linear;
}
.hamburger--collapse-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.16s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.25s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Elastic
 */
.hamburger--elastic .hamburger-inner {
  top: 1.5px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic .hamburger-inner::before {
  top: 6px;
  transition: opacity 0.125s 0.275s ease;
}
.hamburger--elastic .hamburger-inner::after {
  top: 12px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic.is-active .hamburger-inner {
  transform: translate3d(0, 6px, 0) rotate(135deg);
  transition-delay: 0.075s;
}
.hamburger--elastic.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}
.hamburger--elastic.is-active .hamburger-inner::after {
  transform: translate3d(0, -12px, 0) rotate(-270deg);
  transition-delay: 0.075s;
}

/*
 * Elastic Reverse
 */
.hamburger--elastic-r .hamburger-inner {
  top: 1.5px;
  transition-duration: 0.275s;
  transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic-r .hamburger-inner::before {
  top: 6px;
  transition: opacity 0.125s 0.275s ease;
}
.hamburger--elastic-r .hamburger-inner::after {
  top: 12px;
  transition: transform 0.275s cubic-bezier(0.68, -0.55, 0.265, 1.55);
}
.hamburger--elastic-r.is-active .hamburger-inner {
  transform: translate3d(0, 6px, 0) rotate(-135deg);
  transition-delay: 0.075s;
}
.hamburger--elastic-r.is-active .hamburger-inner::before {
  transition-delay: 0s;
  opacity: 0;
}
.hamburger--elastic-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -12px, 0) rotate(270deg);
  transition-delay: 0.075s;
}

/*
 * Emphatic
 */
.hamburger--emphatic {
  overflow: hidden;
}
.hamburger--emphatic .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.hamburger--emphatic .hamburger-inner::after {
  top: 6px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
.hamburger--emphatic.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
.hamburger--emphatic.is-active .hamburger-inner::before {
  left: -48px;
  top: -48px;
  transform: translate3d(48px, 48px, 0) rotate(45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic.is-active .hamburger-inner::after {
  right: -48px;
  top: -48px;
  transform: translate3d(-48px, 48px, 0) rotate(-45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
 * Emphatic Reverse
 */
.hamburger--emphatic-r {
  overflow: hidden;
}
.hamburger--emphatic-r .hamburger-inner {
  transition: background-color 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r .hamburger-inner::before {
  left: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, left 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r .hamburger-inner::after {
  top: 6px;
  right: 0;
  transition: transform 0.125s cubic-bezier(0.6, 0.04, 0.98, 0.335), top 0.05s 0.125s linear, right 0.125s 0.175s ease-in;
}
.hamburger--emphatic-r.is-active .hamburger-inner {
  transition-delay: 0s;
  transition-timing-function: ease-out;
  background-color: transparent !important;
}
.hamburger--emphatic-r.is-active .hamburger-inner::before {
  left: -48px;
  top: 48px;
  transform: translate3d(48px, -48px, 0) rotate(-45deg);
  transition: left 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}
.hamburger--emphatic-r.is-active .hamburger-inner::after {
  right: -48px;
  top: 48px;
  transform: translate3d(-48px, -48px, 0) rotate(45deg);
  transition: right 0.125s ease-out, top 0.05s 0.125s linear, transform 0.125s 0.175s cubic-bezier(0.075, 0.82, 0.165, 1);
}

/*
 * Minus
 */
.hamburger--minus .hamburger-inner::before, .hamburger--minus .hamburger-inner::after {
  transition: bottom 0.08s 0s ease-out, top 0.08s 0s ease-out, opacity 0s linear;
}
.hamburger--minus.is-active .hamburger-inner::before, .hamburger--minus.is-active .hamburger-inner::after {
  opacity: 0;
  transition: bottom 0.08s ease-out, top 0.08s ease-out, opacity 0s 0.08s linear;
}
.hamburger--minus.is-active .hamburger-inner::before {
  top: 0;
}
.hamburger--minus.is-active .hamburger-inner::after {
  bottom: 0;
}

/*
 * Slider
 */
.hamburger--slider .hamburger-inner {
  top: 1.5px;
}
.hamburger--slider .hamburger-inner::before {
  top: 6px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.hamburger--slider .hamburger-inner::after {
  top: 12px;
}
.hamburger--slider.is-active .hamburger-inner {
  transform: translate3d(0, 6px, 0) rotate(45deg);
}
.hamburger--slider.is-active .hamburger-inner::before {
  transform: rotate(-45deg) translate3d(-3.4285714286px, -3px, 0);
  opacity: 0;
}
.hamburger--slider.is-active .hamburger-inner::after {
  transform: translate3d(0, -12px, 0) rotate(-90deg);
}

/*
 * Slider Reverse
 */
.hamburger--slider-r .hamburger-inner {
  top: 1.5px;
}
.hamburger--slider-r .hamburger-inner::before {
  top: 6px;
  transition-property: transform, opacity;
  transition-timing-function: ease;
  transition-duration: 0.15s;
}
.hamburger--slider-r .hamburger-inner::after {
  top: 12px;
}
.hamburger--slider-r.is-active .hamburger-inner {
  transform: translate3d(0, 6px, 0) rotate(-45deg);
}
.hamburger--slider-r.is-active .hamburger-inner::before {
  transform: rotate(45deg) translate3d(3.4285714286px, -3px, 0);
  opacity: 0;
}
.hamburger--slider-r.is-active .hamburger-inner::after {
  transform: translate3d(0, -12px, 0) rotate(90deg);
}

/*
 * Spin
 */
.hamburger--spin .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin.is-active .hamburger-inner {
  transform: rotate(225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Spin Reverse
 */
.hamburger--spin-r .hamburger-inner {
  transition-duration: 0.22s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin-r .hamburger-inner::before {
  transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in;
}
.hamburger--spin-r .hamburger-inner::after {
  transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spin-r.is-active .hamburger-inner {
  transform: rotate(-225deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spin-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out;
}
.hamburger--spin-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
  transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Spring
 */
.hamburger--spring .hamburger-inner {
  top: 1.5px;
  transition: background-color 0s 0.13s linear;
}
.hamburger--spring .hamburger-inner::before {
  top: 6px;
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring .hamburger-inner::after {
  top: 12px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring.is-active .hamburger-inner {
  transition-delay: 0.22s;
  background-color: transparent !important;
}
.hamburger--spring.is-active .hamburger-inner::before {
  top: 0;
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 6px, 0) rotate(45deg);
}
.hamburger--spring.is-active .hamburger-inner::after {
  top: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
  transform: translate3d(0, 6px, 0) rotate(-45deg);
}

/*
 * Spring Reverse
 */
.hamburger--spring-r .hamburger-inner {
  top: auto;
  bottom: 0;
  transition-duration: 0.13s;
  transition-delay: 0s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring-r .hamburger-inner::after {
  top: -12px;
  transition: top 0.2s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), opacity 0s linear;
}
.hamburger--spring-r .hamburger-inner::before {
  transition: top 0.1s 0.2s cubic-bezier(0.33333, 0.66667, 0.66667, 1), transform 0.13s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--spring-r.is-active .hamburger-inner {
  transform: translate3d(0, -6px, 0) rotate(-45deg);
  transition-delay: 0.22s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--spring-r.is-active .hamburger-inner::after {
  top: 0;
  opacity: 0;
  transition: top 0.2s cubic-bezier(0.33333, 0, 0.66667, 0.33333), opacity 0s 0.22s linear;
}
.hamburger--spring-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(90deg);
  transition: top 0.1s 0.15s cubic-bezier(0.33333, 0, 0.66667, 0.33333), transform 0.13s 0.22s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Stand
 */
.hamburger--stand .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}
.hamburger--stand .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand.is-active .hamburger-inner {
  transform: rotate(90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}
.hamburger--stand.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Stand Reverse
 */
.hamburger--stand-r .hamburger-inner {
  transition: transform 0.075s 0.15s cubic-bezier(0.55, 0.055, 0.675, 0.19), background-color 0s 0.075s linear;
}
.hamburger--stand-r .hamburger-inner::before {
  transition: top 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand-r .hamburger-inner::after {
  transition: bottom 0.075s 0.075s ease-in, transform 0.075s 0s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--stand-r.is-active .hamburger-inner {
  transform: rotate(-90deg);
  background-color: transparent !important;
  transition: transform 0.075s 0s cubic-bezier(0.215, 0.61, 0.355, 1), background-color 0s 0.15s linear;
}
.hamburger--stand-r.is-active .hamburger-inner::before {
  top: 0;
  transform: rotate(-45deg);
  transition: top 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--stand-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(45deg);
  transition: bottom 0.075s 0.1s ease-out, transform 0.075s 0.15s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Squeeze
 */
.hamburger--squeeze .hamburger-inner {
  transition-duration: 0.075s;
  transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze .hamburger-inner::before {
  transition: top 0.075s 0.12s ease, opacity 0.075s ease;
}
.hamburger--squeeze .hamburger-inner::after {
  transition: bottom 0.075s 0.12s ease, transform 0.075s cubic-bezier(0.55, 0.055, 0.675, 0.19);
}
.hamburger--squeeze.is-active .hamburger-inner {
  transform: rotate(45deg);
  transition-delay: 0.12s;
  transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1);
}
.hamburger--squeeze.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
  transition: top 0.075s ease, opacity 0.075s 0.12s ease;
}
.hamburger--squeeze.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
  transition: bottom 0.075s ease, transform 0.075s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
}

/*
 * Vortex
 */
.hamburger--vortex .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex .hamburger-inner::before, .hamburger--vortex .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}
.hamburger--vortex .hamburger-inner::before {
  transition-property: top, opacity;
}
.hamburger--vortex .hamburger-inner::after {
  transition-property: bottom, transform;
}
.hamburger--vortex.is-active .hamburger-inner {
  transform: rotate(765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex.is-active .hamburger-inner::before, .hamburger--vortex.is-active .hamburger-inner::after {
  transition-delay: 0s;
}
.hamburger--vortex.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--vortex.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(90deg);
}

/*
 * Vortex Reverse
 */
.hamburger--vortex-r .hamburger-inner {
  transition-duration: 0.2s;
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex-r .hamburger-inner::before, .hamburger--vortex-r .hamburger-inner::after {
  transition-duration: 0s;
  transition-delay: 0.1s;
  transition-timing-function: linear;
}
.hamburger--vortex-r .hamburger-inner::before {
  transition-property: top, opacity;
}
.hamburger--vortex-r .hamburger-inner::after {
  transition-property: bottom, transform;
}
.hamburger--vortex-r.is-active .hamburger-inner {
  transform: rotate(-765deg);
  transition-timing-function: cubic-bezier(0.19, 1, 0.22, 1);
}
.hamburger--vortex-r.is-active .hamburger-inner::before, .hamburger--vortex-r.is-active .hamburger-inner::after {
  transition-delay: 0s;
}
.hamburger--vortex-r.is-active .hamburger-inner::before {
  top: 0;
  opacity: 0;
}
.hamburger--vortex-r.is-active .hamburger-inner::after {
  bottom: 0;
  transform: rotate(-90deg);
}